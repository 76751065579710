import React from "react";
import {Paper, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {observer} from "mobx-react";
import {getCheckboxControl} from "./utils/getCheckboxControl";
import {getAllPermissions} from "./utils/getAllPermissions";
import {BotsListPermissions} from "./bots/BotsListPermissions";
import {AppState} from "../../../app/types";

export const ToolboxPermissions = observer((p: {permissions: {[key: string]: string[]}; appState: AppState}) => {
    const {permissions, appState} = p;
    const updateUsers = appState.actions.users && appState.actions.users.updateUsers;
    if (!updateUsers) {
        console.error(`appState.actions.users.updateUsers not found`);
        return null;
    }
    const toolboxPermissions = Object.keys(permissions).reduce((acc, key) => {
        const userToolboxPermissions = permissions[key]
            .filter((entry: string) => entry.startsWith("/toolbox/"))
            .map((entry: string) => entry.split("/")[2]);
        acc[key] = userToolboxPermissions;
        return acc;
    }, {} as {[key: string]: string[]});

    const allPermissions = getAllPermissions(permissions, "bot");

    const toggleFunction = (value: string) => (isOn: boolean) => {
        const entry = `/toolbox/${value}`;
        Object.keys(permissions).forEach(key => {
            const userPermissions = permissions[key];
            isOn
                ? !userPermissions.includes(entry) && userPermissions.push(entry)
                : userPermissions.includes(entry) && userPermissions.splice(userPermissions.indexOf(entry), 1);
        });
        updateUsers(permissions);
    };

    return (
        <Paper style={{marginTop: "2px"}} elevation={0}>
            <Table padding="none">
                <TableHead>
                    <TableRow>
                        <TableCell key="Golem">Golem</TableCell>
                        <TableCell key="Director">Director</TableCell>
                        <TableCell key="Status">Status</TableCell>
                        <TableCell key="users">Users</TableCell>
                        <TableCell key="servers">Servers</TableCell>
                        <TableCell key="List Bots">List Bots</TableCell>
                        <TableCell key="Bots Admin">Bots Admin</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={`row *`}>
                        <TableCell key="cell0">
                            {getCheckboxControl(toolboxPermissions, "golem", toggleFunction)}
                        </TableCell>
                        <TableCell key="cell1">
                            {getCheckboxControl(toolboxPermissions, "director", toggleFunction)}
                        </TableCell>
                        <TableCell key="cell2">
                            {getCheckboxControl(toolboxPermissions, "status", toggleFunction)}
                        </TableCell>
                        <TableCell key="cell3">
                            {getCheckboxControl(toolboxPermissions, "users", toggleFunction)}
                        </TableCell>
                        <TableCell key="cell4">
                            {getCheckboxControl(toolboxPermissions, "servers", toggleFunction)}
                        </TableCell>
                        <TableCell key="cell5">
                            <BotsListPermissions {...{permissions, allPermissions, appState}} />
                        </TableCell>
                        <TableCell key="Bots Admin">
                            {getCheckboxControl(toolboxPermissions, "botadmin", toggleFunction)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
});
