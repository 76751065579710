import { AppState, LogType } from "../../../../../app/types";
import { getBotsState } from "../../../../botsControl/logic/getBotsState";
import { GraphState, DashboardState, ChartType, EntryType, Aggregator, GatewayType, gatewayTypes } from "../types";
import { getBotName } from "../../../../users/components/utils/getBotName";
import { uuid } from "../test/uuid";

const url = "https://api.imperson.com/queries/";

export const getPeriod = (dash: DashboardState) => {
    let a = "";

    switch (dash.data.timeRangeOptionIndex) {
        case 0:
            a = "this_1_days";
            break;
        case 1:
            a = "this_7_days";
            break;
        case 2:
            a = "this_365_days";
            break;
        case 3:
        default:
            break;
    }

    return a;
};

export const getDateString = (date: Date) =>
    date
        .getFullYear()
        .toString()
        .padStart(4, "0") +
    "-" +
    date
        .getMonth()
        .toString()
        .padStart(2, "0") +
    "-" +
    date
        .getDate()
        .toString()
        .padStart(2, "0");

const avAggregators = [Aggregator["average total"], Aggregator.average];

const getCounterQuery = (graph: GraphState) => {
    if (graph.dataTypes.x.value === EntryType.users) {
        if (avAggregators.includes(graph.aggregator)) return "avgUsersQuery";
        return "totalUsersQuery";
    } else if (graph.dataTypes.x.value === EntryType.sessionDuration) {
        if (avAggregators.includes(graph.aggregator)) return "avgSessionDurationQuery";
        return "avgSessionDurationQuery"; // THIS WON"T WORK!
    } else if (graph.dataTypes.x.value === EntryType.sessionTurns) {
        if (avAggregators.includes(graph.aggregator)) return "avgSessionTurnsQuery";
        return "avgSessionTurnsQuery"; // THIS WON"T WORK!
    } else if (graph.dataTypes.x.value === EntryType.sessions) {
        if (avAggregators.includes(graph.aggregator)) return "totalSessionsQuery"; // THIS WON"T WORK!
        return "totalSessionsQuery";
    }
    return "";
};

const getTimeseriesQuery = (graph: GraphState) => {
    if (!graph.dataTypes.y.length) return "";

    const yType = graph.dataTypes.y[0].value;
    if (yType === EntryType.users) {
        return "usersQuery";
    } else if (yType === EntryType.platform || gatewayTypes.includes(yType)) {
        return "usersPerPlatformQuery";
    } else if (yType === EntryType.sessionDuration) {
        return "avgSessionDurationQuery";
    } else if (yType === EntryType.sessionTurns) {
        return "avgSessionTurnsQuery";
    }
    return "";
};

const getPieQuery = (graph: GraphState) => {
    if (!graph.dataTypes.y.length) return "";
    return "usersPerPlatformTotalQuery";
};

const getTableQuery = (graph: GraphState) => {
    if (!graph.dataTypes.y.length) return "";
    return "marketingGoalsQuery";
};

const graphToQuery = (graph: GraphState) => {
    let a = "";

    switch (graph.chartType) {
        case ChartType.counter:
            a = getCounterQuery(graph);
            break;
        case ChartType.bar:
        case ChartType.area:
        case ChartType.line:
            a = getTimeseriesQuery(graph);
            break;
        case ChartType.pie:
            a = getPieQuery(graph);
            break;
        case ChartType.table:
            a = getTableQuery(graph);
            break;
        default:
            break;
    }

    return a;
};

const processNumericReplyResult = (
    result: Array<{
        timeframe: { start: string; end: string };
        value: number;
    }>,
    processYValue: (value: number) => number = v => v
) => result.map(r => ({ x: new Date(r.timeframe.start), y: [processYValue(r.value)] }));

const processPlatformsReplyResult = (
    result: Array<{
        timeframe: { start: string; end: string };
        value: Array<{ gateway: GatewayType; result: number }>;
    }>,
    processYValue: (value: number) => number = v => v
) => result.map(r => ({ x: new Date(r.timeframe.start), y: r.value.map(rv => processYValue(rv.result)) }));

const processReplyAsCounter = (graph: GraphState, data: any) => {
    let a = parseInt(data);
    if (isNaN(a)) {
        const result: {
            avgForPeriod: number;
            result: Array<{ timeframe: { start: string; end: string }; value: number }>;
        } = JSON.parse(data);

        a = result.avgForPeriod;
    }
    graph.data = [{ x: a, y: [a] }];
};

const processReplyAsTimeseries = (graph: GraphState, data: any) => {
    const result: Array<{
        timeframe: { start: string; end: string };
        value: number | Array<{ gateway: GatewayType; result: number }>;
    }> = JSON.parse(data).result;
    if (!result.length) return;
    const first = result[0];
    if (first.value instanceof Array) {
        const yData = graph.dataTypes.y;
        first.value.forEach((value, i) => {
            if (yData.length <= i)
                yData.push({ isEnabled: true, id: uuid(), value: EntryType.platform, label: EntryType.platform });
            yData[i].value = (value.gateway as any) as EntryType;
        });
        graph.data = processPlatformsReplyResult(
            result as Array<{
                timeframe: { start: string; end: string };
                value: Array<{ gateway: GatewayType; result: number }>;
            }>
        );
    } else {
        graph.data = processNumericReplyResult(
            result as Array<{
                timeframe: { start: string; end: string };
                value: number;
            }>,
            graph.dataTypes.y[0].value === EntryType.sessionDuration ? v => v / 1000 / 60 : v => v
        );
    }
};

const processReplyAsPie = (graph: GraphState, data: any) => {
    const result: Object[] = JSON.parse(data).result;
    if (!result.length) return;
    const titles = Object.keys(result[0]);
    const newData = [{ x: "", y: titles }, ...result.map((r, i) => ({ x: "", y: titles.map(t => (r as any)[t]) }))];
    graph.data = newData;
};

const processReplyAsTable = (graph: GraphState, data: any) => {
    const result: any[] = JSON.parse(data).result;
    if (!result.length) return;
    const first = result[0];
    const headers = Object.keys(first);
    const d = [{ x: "", y: headers }, ...result.map(r => ({ x: "", y: headers.map(h => r[h]) }))];
    graph.data = d;
};

const processReplyData = (graph: GraphState, data: any) => {
    switch (graph.chartType) {
        case ChartType.counter:
            processReplyAsCounter(graph, data);
            break;
        case ChartType.bar:
        case ChartType.area:
        case ChartType.line:
            processReplyAsTimeseries(graph, data);
            break;
        case ChartType.pie:
            processReplyAsPie(graph, data);
            break;
        case ChartType.table:
            processReplyAsTable(graph, data);
            break;
        default:
            break;
    }
};

const tags = ["bots", "DASHBOARD"];

export const makeGetData = (appState: AppState) => {
    return async (graph: GraphState) => {
        const botsState = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const dash = botsState.dashboards[botsState.expandedBotId];
        // const layout = botsState.dashboards[botsState.expandedBotId].config.layout;
        if (!dash) return;
        const query = graphToQuery(graph);
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === botsState.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({ d: [] });
            }
            addLog({ tags, entry: `Query: ${query} start`, type: LogType.start });

            const period = getPeriod(dash);

            const body = {
                query,
                dates: Boolean(period)
                    ? { start: "", end: "" }
                    : {
                        start: getDateString(new Date(dash.data.timeRange[0])),
                        end: getDateString(new Date(dash.data.timeRange[1]))
                    },
                period,
                interval: dash.data.timeInterval,
                minturns: 2,
                personality: getBotName(bot),
                relative: Boolean(period),
                timezone: dash.data.timezone
            };
            graph.isReloading = true;
            const reply = await appState.auth.fetchWithAuth(url, {
                method: "POST",
                body: JSON.stringify(body)
            });

            if (reply.ok) {
                const data = await reply.json();
                graph.isReloading = false;
                const result = Promise.resolve(processReplyData(graph, data));
                addLog({ tags, entry: `Query: ${query} success`, type: LogType.end });
                return result;
            } else {
                graph.isReloading = false;
                const result = Promise.resolve({ error: reply.statusText });
                addLog({ tags, entry: `Query: ${query} error`, type: LogType.error });
                return result;
            }
        } catch (e) {
            graph.isReloading = false;
            addLog({ tags, entry: `Query: ${query} error`, type: LogType.error });
            return Promise.resolve({ error: e });
        }
    };
};
