import React, {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogActions, Button} from "@material-ui/core";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {action, autorun} from "mobx";

export const ReloadDialog = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [isShowingReloadIFrame, setisShowingReloadIFrame] = useState(state.isShowingReloadIFrame);

    useEffect(() => {
        return autorun(() => {
            setisShowingReloadIFrame(state.isShowingReloadIFrame);
        });
    }, [state.isShowingReloadIFrame]);

    return (
        <Dialog open={isShowingReloadIFrame} maxWidth="lg" fullWidth={true}>
            <DialogContent style={{height: "400px"}}>
                <iframe title="reload dialog iframe" id="reload-iframe" frameBorder={0} width="100%" height="100%" />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={action(() => {
                        state.isShowingReloadIFrame = false;
                    })}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
