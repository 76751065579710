import React, {useState, Fragment, useEffect} from "react";
import {autorun} from "mobx";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    IconButton,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormControl,
    Typography
} from "@material-ui/core";
import {QnaType} from "../types";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {AppState} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {hasPermission} from "../../../../../app/permissions";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {makeGetQnaData, makePutQnaData} from "../logic/getQnaData";
import {uuid} from "../test/uuid";

export const Qna = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [answers, setAnswers] = useState([] as QnaType);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [questionValue, setQuestionValue] = useState("");
    const [responseValue, setResponseValue] = useState("");
    const [questionTypeValue, setQuestionTypeValue] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
    useEffect(() => {
        // console.log(`dashboard get qna`);
        const getQnaData = makeGetQnaData(p.appState);
        getQnaData().then((value: any) => {
            setAnswers(value as QnaType);
        });
    }, []);
    // let [filteredAnswers] = useState([] as FaqType);
    // const filter = autorun(() => (filteredAnswers = answers.filter(x => x.category == categories[category].category)));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    if (!bot) return null;
    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "5%"}}>#</TableCell>
                        <TableCell style={{width: "20%"}}>Match</TableCell>
                        <TableCell style={{width: "70%"}}>Response</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {answers.length
                        ? answers.map((value, i) => {
                              return (
                                  <TableRow hover key={uuid()}>
                                      <TableCell style={{width: "5%"}}>{value.ruleid}</TableCell>
                                      <TableCell style={{width: "20%"}}>
                                          {value.questionType == "pattern" ? (
                                              <div style={{color: "#009688"}}>Input Patterns:</div>
                                          ) : null}
                                          {value.questionType == "fallback" ? (
                                              <div style={{color: "#009688"}}>Fallback Responses:</div>
                                          ) : null}
                                          {value.questionType == "intent" ? (
                                              <div style={{color: "#009688"}}>Example Sentences:</div>
                                          ) : null}
                                          {value.questionType == "" ? (
                                              <div>
                                                  <span style={{color: "#009688"}}>Q:&nbsp;</span>
                                                  {value.question}
                                              </div>
                                          ) : null}
                                          {value.questionType != ""
                                              ? value.question.split(/\r?\n/).map(val => {
                                                    return (
                                                        <div key={uuid()} id={uuid()}>
                                                            {val}
                                                        </div>
                                                    );
                                                })
                                              : null}
                                      </TableCell>
                                      <TableCell style={{width: "70%", whiteSpace: "initial"}}>
                                          {value.response.split(/\r?\n/).map((val, r) => {
                                              return r % 2 == 0 ? (
                                                  <div key={uuid()} id={uuid()}>
                                                      {val}
                                                  </div>
                                              ) : (
                                                  <div style={{backgroundColor: "#F6F6F6"}} key={uuid()} id={uuid()}>
                                                      {val}
                                                  </div>
                                              );
                                          })}
                                      </TableCell>
                                      <TableCell>
                                          {hasPermission("faqupdate", appState.auth.permissions, getBotName(bot)) ? (
                                              <Fragment>
                                                  <IconButton
                                                      size="small"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => {
                                                          setQuestionValue(value.question);
                                                          setResponseValue(value.response);
                                                          setQuestionTypeValue(value.questionType);
                                                          setSelectedIndex(i);
                                                          setIsEditDialogOpen(true);
                                                      }}>
                                                      <EditIcon />
                                                  </IconButton>
                                                  <IconButton
                                                      size="small"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => {
                                                          setSelectedIndex(i);
                                                          setIsDeleteDialogOpen(true);
                                                      }}>
                                                      <DeleteIcon />
                                                  </IconButton>
                                              </Fragment>
                                          ) : null}
                                      </TableCell>
                                  </TableRow>
                              );
                          })
                        : null}
                    {hasPermission("faqupdate", appState.auth.permissions, getBotName(bot)) ? (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Button
                                    style={{cursor: "pointer"}}
                                    size="small"
                                    onClick={() => {
                                        setQuestionValue("");
                                        setResponseValue("");
                                        setQuestionTypeValue("");
                                        setSelectedIndex(-1);
                                        setIsEditDialogOpen(true);
                                    }}>
                                    New QNA
                                </Button>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
            <Dialog
                open={isEditDialogOpen}
                fullWidth
                maxWidth="md"
                onClose={() => setIsEditDialogOpen(false)}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Typography>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="position"
                                name="position"
                                value={questionTypeValue}
                                onChange={(e: any) => {
                                    setQuestionTypeValue(e.target.value);
                                }}>
                                <FormControlLabel
                                    value=""
                                    control={<Radio color="primary" />}
                                    label="Match a Question and a Response"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="pattern"
                                    control={<Radio color="primary" />}
                                    label="Match Input Patterns"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="intent"
                                    control={<Radio color="primary" />}
                                    label="Match Example Sentences"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="fallback"
                                    control={<Radio color="primary" />}
                                    label="Fallback Responses (No match)"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Typography>
                    {questionTypeValue != "fallback" ? (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="question"
                            label={
                                questionTypeValue == "pattern"
                                    ? "Input Patterns"
                                    : questionTypeValue == "intent"
                                    ? "Example Sentences"
                                    : "Question"
                            }
                            type="text"
                            fullWidth
                            multiline={questionTypeValue == "pattern" || questionTypeValue == "intent"}
                            value={questionValue}
                            onChange={e => setQuestionValue(e.target.value)}
                        />
                    ) : null}
                    <TextField
                        margin="dense"
                        id="response"
                        label={questionTypeValue == "fallback" ? "Fallback Responses" : "Response"}
                        type="text"
                        fullWidth
                        value={responseValue}
                        multiline={questionTypeValue == "fallback"}
                        onChange={e => setResponseValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            const putQna = makePutQnaData(appState);
                            if (selectedIndex == -1) {
                                const maxnumber = Math.max.apply(
                                    null,
                                    answers && answers.length ? answers.map(x => parseInt(x.ruleid)) : []
                                );
                                const maxstring = isFinite(maxnumber) ? (maxnumber + 1).toString() : "1";
                                answers.push({
                                    ruleid: maxstring,
                                    question: questionValue,
                                    response: responseValue,
                                    questionType: questionTypeValue
                                });
                                putQna(
                                    getBotName(bot),
                                    bot.key,
                                    maxstring,
                                    questionValue,
                                    responseValue,
                                    questionTypeValue
                                );
                            } else {
                                answers[selectedIndex].question = questionValue;
                                answers[selectedIndex].response = responseValue;
                                answers[selectedIndex].questionType = questionTypeValue;
                                putQna(
                                    getBotName(bot),
                                    bot.key,
                                    answers[selectedIndex].ruleid,
                                    questionValue,
                                    responseValue,
                                    questionTypeValue
                                );
                            }
                            setIsEditDialogOpen(false);
                        }}
                        color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this question?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
                        No
                    </Button>
                    <Button
                        onClick={() => {
                            const putQna = makePutQnaData(appState);
                            putQna(getBotName(bot), bot.key, answers[selectedIndex].ruleid, "", "", "");
                            answers.splice(selectedIndex, 1);
                            setIsDeleteDialogOpen(false);
                        }}
                        color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};
