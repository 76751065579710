import React, {useState, useEffect} from "react";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {Button, Menu, MenuItem} from "@material-ui/core";

export const ControlGroupMenu = (p: {appState: AppState; group: string; setselectedGroup: (group: string) => void}) => {
    const {appState, group, setselectedGroup} = p;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const reduceScalingGroups = () =>
        appState.data.bots_statuses.reduce(
            (acc, cur) => {
                !acc.includes(cur.scalingGroup) && acc.push(cur.scalingGroup);
                return acc;
            },
            [] as string[]
        );
    const [groups, setGroups] = useState(reduceScalingGroups());
    useEffect(() => {
        setGroups(reduceScalingGroups());
    }, [appState.data.bots_statuses]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Button onClick={handleClick}>{group || "Choose a group"}</Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {groups.map((name, i) => {
                    return (
                        <MenuItem
                            key={`name ${i}`}
                            value={name}
                            onClick={() => {
                                setselectedGroup(name);
                                handleClose();
                            }}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
