import React, {useState, useEffect} from "react";
import {GraphState, DataUnit} from "../../types";
import {Divider, Typography} from "@material-ui/core";
import {autorun} from "mobx";
import {Reloading} from "../Reloading";

export const ChartWrapper = (p: {
    graph: GraphState;
    chart: (p: {graph: GraphState; filteredData: DataUnit[]}) => JSX.Element;
}) => {
    const [isReloading, setisReloading] = useState(p.graph.isReloading);
    useEffect(() => {
        return autorun(() => {
            setisReloading(p.graph.isReloading);
        });
    }, []);
    const [filteredData, setfilteredData] = useState(p.graph.data);
    useEffect(() => {
        return autorun(() => {
            setfilteredData(p.graph.data);
        });
    }, []);

    const dt = p.graph.dataTypes;

    const yAxisLabel = dt.y
        .filter(y => y.isEnabled)
        .map(y => y.value)
        .join(", ");

    const xAxisLabel = dt.x.value;

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{padding: "7px 10px 4px", borderBottom: "1px solid #d7d7d7"}}>
                <Typography align="left" variant="subtitle2">
                    {p.graph.title || `${xAxisLabel} by ${yAxisLabel}`}
                </Typography>
            </div>
            {isReloading ? <Reloading /> : p.chart({graph: p.graph, filteredData})}
        </div>
    );
};
