import React, {useState, useEffect} from "react";
import {autorun} from "mobx";
import {AppState, BotStatusData} from "../../../../../../app/types";
import {DashboardState} from "../../types";
import {Button, Menu, MenuItem, Tooltip} from "@material-ui/core";
import {getBotsState} from "../../../../../botsControl/logic/getBotsState";

export const PlatformsMenu = (p: {appState: AppState; dashState: DashboardState}) => {
    const {appState} = p;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [gateway, setSelected] = useState(p.dashState.data.gateway);
    // console.log("gateway:" + p.dashState.data.gateway);
    let platforms = [] as string[];
    const botsState = getBotsState(appState);
    const bot = appState.data.bots_statuses.find(bot => bot.key === botsState.expandedBotId);

    useEffect(() => {
        return autorun(() => (p.dashState.data.gateway = gateway));
    }, [gateway]);

    if (!bot) return null;
    const configData = (bot.configData || {}) as any;
    const cPlatforms = (configData.dashPlatforms || "") as string;
    platforms = cPlatforms == "" ? [] : cPlatforms.split(",");
    // if (platforms.length > 0 && !platform) {
    //     setSelected(platforms[0]);
    // }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return platforms.length > 0 ? (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Tooltip title="Platforms">
                <Button onClick={handleClick}>{!gateway ? "ALL" : gateway}</Button>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {platforms.map((name, i) => {
                    return (
                        <MenuItem
                            key={`name ${i}`}
                            value={name}
                            onClick={() => {
                                setSelected(name);
                                handleClose();
                            }}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    ) : null;
};
