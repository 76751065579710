import React, {useState} from "react";
import {DashboardState} from "../../types";
import {TimeButtonsSelect} from "../range/TimeButtonsSelect";

export const DashboardTimerange = (p: {dashState: DashboardState; style?: {}}) => {
    const [value, setValue] = useState([Date.now(), Date.now()]);
    const [minmax] = useState([0, Date.now()]);
    const updateRange = (newValue: number[], optionIndex: number) => {
        p.dashState.data.timeRange = newValue;
        p.dashState.data.timeRangeOptionIndex = optionIndex;
        setValue(newValue);
    };
    return (
        <div style={{marginRight: "8px", display: "flex", flexDirection: "row", ...p.style}}>
            <TimeButtonsSelect
                {...{
                    dashState: p.dashState,
                    range: value,
                    minmax,
                    optionIndex: p.dashState.data.timeRangeOptionIndex,
                    updateRange
                }}
            />
        </div>
    );
};
