import React, { useState, useEffect, Fragment, useRef } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    TextField,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    useMediaQuery,
    useTheme,
    Hidden
} from "@material-ui/core";
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import { AppState } from "../../../../../app/types";
import { getBotsState } from "../../../logic/getBotsState";
import { ConversationsActionsBar } from "./ConversationsActionsBar";
import { Divider } from "@material-ui/core";
import { ToolInnerContent } from "../../../../../app/components/ToolInnerContent";
import { conversation, message, agentType, agentAlert } from "../types";
import { getConversations, postNewMessage } from "../logic/getConversations";
import { Log, mergeConvs, mergeMessages } from './Log'
import { showDate } from "../../../../../utils/randInt";
import '../App.css'
import { uuid } from "../../dashboard/test/uuid";

export const Conversations = (p: { appState: AppState }) => {
    const { appState } = p;
    const state = getBotsState(appState);

    const inputRef = useRef(null);
    const [now, setNow] = useState(new Date());
    const [input, setInput] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    const [agent, setAgent] = useState({ agent: appState.auth.userEmail, ping: new Date().toISOString(), offline: false, toolboxNotif: true, emailNotif: true, smsNotif: false } as agentType);
    const [conversations, setConversations] = useState([] as conversation[]);
    const [selectedConv, setSelectedConv] = useState(0);
    const [alerts,setAlerts] = useState([] as agentAlert[]);
    const [expandedBotId, setExpandedBotId] = useState(state.expandedBotId);
    const [botrec, setBotrec] = useState(appState.data.bots_statuses.find(b => b.key == state.expandedBotId));
    const [bot, setBot] = useState("");
    const [optionsDialog, setOptionsDialog] = useState(false);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        setExpandedBotId(state.expandedBotId);
        setBotrec(appState.data.bots_statuses.find(b => b.key === state.expandedBotId));
        // if (Notification.permission != "granted" && agent.toolboxNotif) {
        //     Notification.requestPermission()
        //     .then((perm) => {
        //         console.log(perm); 
        //         setAgent({...agent, toolboxNotif: perm === "granted" })
        //     });
        // }
    }, [state.expandedBotId]);

    useEffect(() => {
        if (botrec) setBot(botrec.personalityID + "-" + botrec.instanceID);
    }, [botrec]);

    useEffect(() => {
        const ctrl = { id: uuid(), control: new AbortController() };
        // console.log("loading conversations", bot, "controller", ctrl);
        if (bot) getConversations(appState, bot, agent, setAgent, conversations, setConversations, setAlerts, ctrl.control);
        return () => {
            // console.log("aborting conversations", bot, "controller", ctrl);
            ctrl.control.abort();
        };
    });

    const postInput = () => {
        if (botrec && conversations[selectedConv]) {
            const msg = { cuid: conversations[selectedConv].cuid, muid: uuid(), contenttype: "text", incoming: false, msgtext: input, created: new Date().toISOString(), sender: appState.auth.userEmail } as message;
            setConversations(mergeConvs(conversations, { ...conversations[selectedConv], messages: mergeMessages(conversations[selectedConv].messages, [msg]) }));
            setInput("");
            if (inputRef) {
                (inputRef as any).current.focus()
            };
            postNewMessage(appState, msg, bot, botrec.key, conversations[selectedConv].gw, conversations[selectedConv].userid);
        }
    }

    const formSubmit = ((event: any) => {
        if (input != "") postInput();
        event.preventDefault();
        event.stopPropagation();
        return false;
    });

    const onChange = ((event: any) => {
        setInput(event.target.value);
    });

    const onCheck = ((event: any) => {
        setAgent({ ...agent, [event.target.name]: true });
    })

    // const onRadio = ((event: any) => {
    //     const emailnotif = event.target.name === "email";
    //     setAgent({ ...agent, emailNotif: emailnotif, smsNotif: !emailnotif } as agentType);
    // })

    const convLog = (conversations:conversation[], selectedConv:number) => {
        return (<div style={{ position: "relative", height: "100%" }}><Log {...{ conv: conversations[selectedConv], readonly: true, setStage: null }} /></div>);
    }

    return expandedBotId ? (
        <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            {ConversationsActionsBar(appState, bot, conversations, selectedConv, setConversations, setOptionsDialog)}
            <Divider />
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    overflow: "hidden"
                }}>
                <ToolInnerContent style={{ padding: "4px", overflow: "auto", width: !desktop ? "100%" : "50%", border: "1px solid lightgray" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell>Gw</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {conversations.map((v, i) => {
                                return (
                                    <Fragment key={v.cuid}>
                                        <TableRow
                                            selected={v.cuid === conversations[selectedConv].cuid}
                                            hover
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedConv(i)
                                            }}>
                                            <TableCell>
                                                {v.status === 2
                                                    ? <Tooltip title={"Waiting in queue"}><AssignmentLateOutlinedIcon style={{ color: "red", fontSize: "large" }} /></Tooltip>
                                                    : v.ownerid !== bot
                                                        ? <Tooltip title={"Assigned to " + v.ownerid}><AssignmentIndOutlinedIcon style={{ color: "green", fontSize: "large" }} /></Tooltip>
                                                        : <Tooltip title={"Assigned to bot"}><ChatOutlinedIcon style={{ color: "rgb(1, 166, 224)", fontSize: "large" }} /></Tooltip>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Fragment>
                                                    {v.status === 2
                                                        || (v.ownerid !== bot
                                                            && v.messages
                                                            && (v.messages[v.messages.length - 1].incoming || (!v.messages[v.messages.length - 1].incoming && v.messages[v.messages.length - 1].contenttype == "transfer")))
                                                        ? <TimerOutlinedIcon /> : null}
                                                    {showDate(v.updated, now)}
                                                </Fragment>
                                            </TableCell>
                                            <TableCell>{v.status === 2 ? "(In Queue)" : v.ownerid}</TableCell>
                                            <TableCell><Tooltip title={v.cuid}><span>{v.gw}</span></Tooltip></TableCell>
                                        </TableRow>
                                        {v.cuid === conversations[selectedConv].cuid && v.ownerid === appState.auth.userEmail
                                            ? <TableRow><TableCell colSpan={4}>
                                                <div>
                                                    <form onSubmit={formSubmit}>
                                                        <TextField type="text" onChange={onChange} placeholder="Type a message..." value={input} ref={inputRef} />
                                                        <Button type="submit" disabled={false}>send</Button>
                                                    </form>
                                                </div>
                                            </TableCell></TableRow>
                                            : null}
                                        <Hidden mdUp>
                                             {conversations[selectedConv] && v.cuid === conversations[selectedConv].cuid && conversations[selectedConv].messages
                                             ? <TableRow><TableCell colSpan={4}><div style={{height:"300px"}}>{convLog(conversations,selectedConv)}</div></TableCell></TableRow>
                                             :null}
                                        </Hidden>
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </ToolInnerContent>
                <Hidden smDown>
                    <ToolInnerContent style={{ padding: "4px", width: "50%", overflowX: "hidden", overflowY: "auto", border: "1px solid lightgray" }}>
                        {conversations[selectedConv] && conversations[selectedConv].messages?convLog(conversations,selectedConv):null}
                    </ToolInnerContent>
                </Hidden>
            </div>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={optionsDialog}
                onClose={() => { setOptionsDialog(false); }}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={agent.offline} onChange={onCheck} name="offline" />}
                                label="I am not taking conversations"
                            />
                            <FormLabel>When online:</FormLabel>
                            <FormControlLabel
                                control={<Checkbox checked={agent.toolboxNotif} onChange={onCheck} name="toolboxNotif" />}
                                label="Send me notifications here"
                            />
                            <FormLabel>When offline:</FormLabel>
                            <div><Radio name="email" value="email" checked={agent.emailNotif} />Notify me by email:<input placeholder="abc"></input></div>
                            <div><Radio name="sms" value="sms" checked={agent.smsNotif} />Notify me by text to:<input placeholder="xyz"></input></div>
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { setOptionsDialog(false); }}
                        color="primary">
                        Close
                        </Button>
                </DialogActions>
            </Dialog>
        </div>
    ) : (
        <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
            {/* <ConversationsActionsBar {...{ appState }} /> */}
        </div>
    );
};
