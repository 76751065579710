import React, { Fragment, useState, useEffect } from "react";
import { AppState, BotsControlState, BotStatusData } from "../../../app/types";
import { TableRow, TableCell, Fab, Theme, Badge, TableBody, IconButton, Tooltip, Button, Hidden, Menu, MenuItem } from "@material-ui/core";
import { BotsCheckbox } from "./BotsCheckbox";
import { getBotsState } from "../logic/getBotsState";
import { autorun, action } from "mobx";
import { makeStyles } from "@material-ui/styles";
import { getBotName } from "../../users/components/utils/getBotName";
import { hasPermission } from "../../../app/permissions";
import MoreVertIcon from '@material-ui/icons/MoreVert';

// const getGtws = (gateways: { gatewayID: string }[]) => {
//     let result = "";
//     for (var i = 0; i < gateways.length; i++) {
//         result += gateways[i].gatewayID + " ";
//     }
//     return result;
// };

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            height: "20px",
            width: "20px",
            minHeight: "20px",
            minWidth: "20px",
            fontSize: "1rem",
            margin: `${theme.spacing(1)}px`,
            boxShadow: "0px 0px 0px 0px"
        },
        label: {
            height: "20px",
            width: "20px",
            minHeight: "20px",
            minWidth: "20px",
            fontSize: "1rem"
        }
    };
});

export const BotsControlTableBody = (props: { appState: AppState }) => {
    const { appState } = props;
    const state = getBotsState(appState);
    const classes = useStyles();

    const [engines, setEngines] = useState(props.appState.data.engine_statuses);
    const [isEnginesReady, setisEnginesReady] = useState(false);
    const [bots, setBots] = useState(appState.data.bots_statuses);
    const [customers, setCustomers] = useState(state.expandedCustomers);

    useEffect(() => {
        return autorun(() => {
            setEngines(props.appState.data.engine_statuses);
            setisEnginesReady(Boolean(Object.keys(props.appState.data.engine_statuses).length));
        });
    }, [props.appState.data.engine_statuses]);

    useEffect(() => {
        return autorun(() => {
            const l = props.appState.data.bots_statuses.length; // this is here to trigger mobx update
            setBots(props.appState.data.bots_statuses);
        });
    }, [props.appState.data.bots_statuses]);

    const botsByCustomers = {} as { [key: string]: BotStatusData[] };
    const enginesByCustomers = {} as { [key: string]: number };

    bots.forEach(bot => {
        bot.customer && !botsByCustomers[bot.customer] && (botsByCustomers[bot.customer] = []);
        botsByCustomers[bot.customer].push(bot);
        isNaN(enginesByCustomers[bot.customer]) && (enginesByCustomers[bot.customer] = 0);
        const botName = bot.personalityID + "-" + bot.instanceID;
        enginesByCustomers[bot.customer] += engines[botName] ? engines[botName].activeSessions : 0;
    });

    const onBotSelected = (bot: BotStatusData, fromRow: boolean = false) => {
        !state.gateways[bot.key] &&
            (state.gateways[bot.key] = { selectedGatewayId: "", gatewayChanges: {}, deletedGateways: {} });
        state.expandedBotId = bot.key;
        let bName = getBotName(bot);
        appState.actions.botsControl && appState.actions.botsControl.addToPath(bName);
        if (
            fromRow &&
            hasPermission("dashboard", appState.auth.permissions, bName) &&
            !hasPermission("view", appState.auth.permissions, bName)
        ) {
            appState.actions.botsControl && appState.actions.botsControl.addToPath("dashboard");
        } else if (
            fromRow &&
            hasPermission("faq", appState.auth.permissions, bName) &&
            !hasPermission("view", appState.auth.permissions, bName)
        ) {
            appState.actions.botsControl && appState.actions.botsControl.addToPath("faq");
        }
    };

    const getBotBadge = (botName: string) => {
        if (!isEnginesReady) return 0;
        if (!engines[botName]) return "Unloaded";
        return engines[botName].activeSessions === 0 ? 0 : engines[botName].activeSessions;
    };

    const makeContentDB = (botName: string, appState: AppState, state: BotsControlState, bot: BotStatusData) => {
        return (hasPermission("view", appState.auth.permissions, botName)
            ? (state.propertiesChanges[bot.key]
                ? state.propertiesChanges[bot.key].contentDb
                : bot.contentDB)
            : null
        )
    }
    const makeDashboard = (botName: string, appState: AppState, bot: BotStatusData) => {
        return (
                <Tooltip title="dashboard">
                    <Button color="primary" variant="outlined" className="bt"
                        style={{ height: "30px" }}
                        onClick={action((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const actions = appState.actions.botsControl;
                            if (!actions) return;
                            onBotSelected(bot);
                            actions.addToPath("dashboard");
                        })}>
                        Dashboard
                </Button>
                </Tooltip>
        )
    }
    const makeFaq = (botName: string, appState: AppState, bot: BotStatusData) => {
        return (
            <Tooltip title="FAQs">
                <Button color="primary" variant="outlined" className="bt"
                    style={{ height: "30px" }}
                    onClick={action((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const actions = appState.actions.botsControl;
                        if (!actions) return;
                        onBotSelected(bot);
                        actions.addToPath("faq");
                    })}>
                    Faq
                </Button>
            </Tooltip>
        )
    }

    const makeConversations = (botName: string, appState: AppState, bot: BotStatusData) => {
        return <Tooltip title="conversations">
                <Button color="primary" variant="outlined" className="bt"
                    style={{ height: "30px" }}
                    onClick={action((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const actions = appState.actions.botsControl;
                        if (!actions) return;
                        onBotSelected(bot);
                        actions.addToPath("conversations");
                    })}>
                Conversations
                </Button>
            </Tooltip>
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // console.log("menu click",event.currentTarget.id);
        setAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const makeExpandedRow = (customer: string) => {
    
        return botsByCustomers[customer]
            .filter(bot => getBotName(bot).includes(state.filter))
            .map((bot, i): JSX.Element => {
                const botName = getBotName(bot);
                return (
                    <TableRow
                        key={bot.key}
                        hover
                        id={bot.key}
                        onClick={() => onBotSelected(bot, true)}
                        style={{ cursor: "pointer" }}>
                        <TableCell key="cell0" style={{width:"24px"}}>
                            <BotsCheckbox {...{ appState, selectedBotIds: state.selectedBotIds, bot }} />
                        </TableCell>
                        <TableCell>
                            <Tooltip title={"Last Reload: " + new Date(bot.lastReload).toLocaleString()}>
                                <Badge
                                    max={999999999}
                                    color={engines[botName] ? "primary" : "error"}
                                    badgeContent={getBotBadge(botName)}>
                                    {bot.personalityID}-{bot.instanceID}
                                </Badge>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            {makeContentDB(botName, appState, state, bot)}
                            <Hidden smDown>
                                {hasPermission("faq", appState.auth.permissions, botName)?makeFaq(botName, appState, bot):null}
                                {hasPermission("conversations", appState.auth.permissions, botName)?makeConversations(botName, appState, bot):null}
                                {hasPermission("dashboard", appState.auth.permissions, botName) &&
                                 hasPermission("view", appState.auth.permissions, botName) ?makeDashboard(botName, appState, bot): null}
                            </Hidden>
                            <Hidden mdUp>
                            <Fragment>
                            <IconButton aria-controls={"simple-menu-"+bot.key} aria-haspopup="true" onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id={"simple-menu-" + bot.key}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && anchorEl?.getAttribute("aria-controls") === "simple-menu-" + bot.key}
                                onClose={handleClose}
                            >
                                {hasPermission("faq", appState.auth.permissions, botName)?<MenuItem>{makeFaq(botName, appState, bot)}</MenuItem>:null}
                                {hasPermission("conversations", appState.auth.permissions, botName)?<MenuItem>{makeConversations(botName, appState, bot)}</MenuItem>:null}
                                {hasPermission("dashboard", appState.auth.permissions, botName) &&
                                 hasPermission("view", appState.auth.permissions, botName) ?<MenuItem>{makeDashboard(botName, appState, bot)}</MenuItem>:null}
                            </Menu>
                        </Fragment>
                            </Hidden>
                        </TableCell>
                        {/* <TableCell>
                            <span>{getGtws(bot.gateways)}</span>
                            {hasPermission("gateways", appState.auth.permissions, botName) && (
                                <Tooltip title="Edit Gateways">
                                    <IconButton
                                        style={{ height: "30px" }}
                                        size="small"
                                        onClick={action((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            const actions = appState.actions.botsControl;
                                            if (!actions) return;
                                            onBotSelected(bot);
                                            actions.addToPath("gateways");
                                        })}>
                                        <Gateway />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </TableCell> */}
                        {/* <TableCell>{new Date(bot.lastReload).toLocaleString()}</TableCell> */}
                    </TableRow>
                );
            });
    };

    return (
        <TableBody>
            {Object.keys(botsByCustomers).map((customer, i) => {
                return (
                    <Fragment key={i.toString()}>
                        <TableRow key={`row ${i}`} style={{ background: "#eaeaea" }}>
                            <TableCell
                                colSpan={5}
                                onClick={() => {
                                    customers[customer] = !customers[customer];
                                    setCustomers({ ...customers });
                                }}>
                                <div style={{ display: "flex" }}>
                                    <Fab classes={classes} variant="round" size="small">
                                        {customers[customer] ? "-" : "+"}
                                    </Fab>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center"
                                        }}>
                                        <Badge
                                            max={999999999}
                                            color="primary"
                                            badgeContent={customers[customer] ? 0 : enginesByCustomers[customer] || 0}>
                                            {customer}
                                        </Badge>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                        {!customers[customer] ? null : makeExpandedRow(customer)}
                    </Fragment>
                );
            })}
        </TableBody>
    );
};
