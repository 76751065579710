import React, {useRef, useEffect} from "react";
import {Paper, Typography, IconButton} from "@material-ui/core";
import {observer} from "mobx-react";
import {AppState, LogEntryData, LogType} from "../types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {blue} from "@material-ui/core/colors";
import {getLogState} from "./getLogState";

const pad = (value: string) => (value.length >= 2 ? value : "0" + value);

const formatDate = (date: Date) => {
    const minutes = date.getMinutes().toString();
    const hours = date.getHours().toString();
    const seconds = date.getSeconds().toString();

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

const getStyleByType = (type: LogType) => {
    let style = {};
    switch (type) {
        case LogType.info:
            break;
        case LogType.start:
            style = {color: "blue"};
            break;
        case LogType.end:
            style = {color: "green"};
            break;
        case LogType.error:
            style = {color: "red"};
            break;
        case LogType.warning:
            style = {color: "orange"};
            break;
        default:
            break;
    }
    return style;
};
const AutoscrollingTypography = (p: {
    sizes: {isMinimized: boolean; isCollapsed: boolean; width: number | string; height: number};
    entries: LogEntryData[];
}) => {
    const ref = useRef(null as any);
    useEffect(() => {
        ref.current && ref.current.scrollHeight > p.sizes.height && (ref.current.scrollTop = ref.current.scrollHeight);
    });
    const entries = !p.sizes.isCollapsed ? p.entries : p.entries.length ? [p.entries[p.entries.length - 1]] : [];
    return (
        <div
            ref={ref}
            style={{
                transition: "all 0.2s ease",
                height: `${p.sizes.height}px`,
                width: "100%",
                overflow: "auto"
            }}>
            {entries.map((entry, i) => (
                <p
                    key={i}
                    style={{
                        marginBottom: "1px",
                        marginTop: "1px",
                        fontSize: "13.33px",
                        lineHeight: "1em",
                        whiteSpace: "pre-line",
                        ...getStyleByType(entry.type),
                        ...entry.style
                    }}>
                    {`${entry.tags.join(",")} : ${formatDate(entry.timestamp)} ${entry.entry}`}
                </p>
            ))}
        </div>
    );
};

export const Log = observer((p: {appState: AppState}) => {
    const state = getLogState(p.appState);
    const entries = p.appState.data.log;
    const sizesBase = {isCollapsed: state.isCollapsed, isMinimized: state.isMinimized};
    const sizes = state.isCollapsed
        ? {width: "100%", height: 20, ...sizesBase}
        : {width: 600, height: 200, ...sizesBase};
    if (state.isMinimized) {
        sizes.width = sizes.height = 0;
    }
    const length = entries.length; // this is here to trigger rerender when entries array changes
    return (
        <Paper
            style={{
                minHeight: state.isMinimized ? "0px" : "38px",
                bottom: "0px",
                zIndex: 100,
                paddingLeft: state.isMinimized ? "0px" : "6px",
                paddingBottom: state.isMinimized ? "0px" : "14px",
                margin: state.isMinimized ? "0px" : "12px",
                overflow: "hidden",
                width: state.isMinimized ? "0px" : "",
                border: "1px solid lightgray"
            }}
            elevation={0}>
            <div
                style={{
                    height: state.isMinimized ? "0px" : "20px",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                <Typography variant="caption">Log</Typography>
                <IconButton
                    style={{height: "20px", width: "20px", color: blue[400], padding: "0px"}}
                    onClick={() => (state.isCollapsed = !state.isCollapsed)}>
                    <ChevronRightIcon
                        style={{
                            transition: "all 0.2s ease",
                            transform: state.isCollapsed ? `rotate(-90deg)` : `rotate(90deg)`
                        }}
                    />
                </IconButton>
            </div>
            <AutoscrollingTypography {...{entries, sizes}} />
        </Paper>
    );
});
