import React, {Fragment} from "react";
import {TableRow, TableCell} from "@material-ui/core";
import {observer} from "mobx-react";
import {getCheckboxWithAsteriskControl} from "../utils/getCheckboxWithAsteriskControl";
import {action} from "mobx";

export const BotsPermissionsDynamic = observer(
    (
        p: {
            botsPermissions: {[key: string]: Map<string, string[]>};
            asteriskPermissions: {
                [key: string]: string[];
            };
            tempBots: string[];
            togglePermission: (type: string) => (value: string) => (isOn: boolean) => void;
        } & {
            classes?: any;
        }
    ) => {
        const {botsPermissions, asteriskPermissions, tempBots, togglePermission} = p;
        tempBots.forEach(bot =>
            Object.keys(botsPermissions).forEach(
                key => !botsPermissions[key].has(bot) && action(() => botsPermissions[key].set(bot, []))()
            )
        );
        const allBotsInUse = Array.from(
            new Set(Object.keys(botsPermissions)
                .map(key => Array.from(botsPermissions[key].keys()))
                .flat(1) as string[])
        );
        return (
            <Fragment>
                {allBotsInUse.map(key => {
                    const thisBotPermissions = Object.keys(botsPermissions).map(
                        userKey => botsPermissions[userKey].get(key) || []
                    );
                    return (
                        <TableRow key={`row ${key}`}>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "view",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "deploy",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "update",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "dashboard",
                                    togglePermission(key)
                                )}
                            </TableCell>

                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "logview",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisBotPermissions,
                                    asteriskPermissions,
                                    "logcomment",
                                    togglePermission(key)
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Fragment>
        );
    }
);
