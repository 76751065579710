import React, {Component} from "react";
import {Table, TableRow, TableCell} from "@material-ui/core";
export class ServerSecondRow extends Component {
    constructor(props) {
        super(props);
        this.state = {visible: props.visible};
    }
    render() {
        if (!this.state.visible) {
            return null;
        } else {
            return (
                <TableRow>
                    <TableCell colSpan={4}>
                        <Table padding="dense">
                            {this.props.deployments.map((deploymentrow, i) => {
                                return (
                                    <TableRow>
                                        <TableCell>{deploymentrow.key}</TableCell>
                                        <TableCell>{deploymentrow.name}</TableCell>
                                        <TableCell>
                                            {deploymentrow.activeServers}/{deploymentrow.maxServers}
                                        </TableCell>
                                        <TableCell>{deploymentrow.status}</TableCell>
                                        <TableCell>{new Date(deploymentrow.startTime).toLocaleString()}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </TableCell>
                </TableRow>
            );
        }
    }
}
