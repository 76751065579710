import React, {useEffect, useState} from "react";
import {AppState} from "../../../../../app/types";
import {ActionsBar, ActionsBarOption} from "../../../../../app/components/ActionsBar";
import {autorun, action} from "mobx";
import {Theme} from "@material-ui/core";
import {getBotsState} from "../../../logic/getBotsState";
import {makeStyles} from "@material-ui/styles";
import {hasPermission} from "../../../../../app/permissions";
import {Breadcrumbs} from "../../../../../app/components/Breadcrumbs";
import {getBotName} from "../../../../users/components/utils/getBotName";
import { conversation } from "../types";
import { updateLocale } from "moment";
import { updateConversation } from "../logic/getConversations";

const useStyles = makeStyles((theme: Theme) => {
    return {
        clickableText: {
            cursor: "pointer"
        },
        drilldownText: {
            paddingLeft: `${theme.spacing(1)}px`,
            paddingRight: `${theme.spacing(1)}px`
        },
        breadcrumbsContainer: {
            display: "flex"
        }
    };
});

export const ConversationsActionsBar = (appState: AppState,  bot: string, conversations: conversation[], selectedConv: number, setConversations: any, setOptionsDialog: any) => {
    const state = getBotsState(appState);

    const options: ActionsBarOption[] = hasPermission("conversationsupdate", appState.auth.permissions, getBotsState(appState).path[1])
    ? [
        {
            title: "Take",
            disabled: !conversations[selectedConv] || (conversations[selectedConv] && (conversations[selectedConv].ownerid === appState.auth.userEmail)),
            action: () => {
                updateConversation(appState,bot,appState.auth.userEmail,1,conversations,selectedConv,setConversations);
                return false;
            }
        },
        {
            title: "Give",
            disabled: !conversations[selectedConv] || (conversations[selectedConv] && conversations[selectedConv].ownerid !== appState.auth.userEmail),
            action: () => {
                updateConversation(appState,bot,bot,1,conversations,selectedConv,setConversations);
                return false;
            }
        },
        {
            title: "Options",
            disabled: false,
            action: () => {
                setOptionsDialog(true);
                return false;
            }
        }
    ]
    : [];

    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: state.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];

    return <ActionsBar {...{before, options}} />;
};
