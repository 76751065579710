import {AppState, LogType} from "../../../app/types";
import {getBotsState} from "./getBotsState";

const tags = ["bots"];
const url = "https://api.imperson.com/moveKeys";

export const makeBotsMove = (appState: AppState) => async (groupTo: string) => {
    const state = getBotsState(appState);
    const addLog = appState.actions.log.addLog;
    const selectedBots = [...state.selectedBotIds];

    addLog({entry: `moving bots to ${groupTo}`, tags, type: LogType.start});

    try {
        const reply = await appState.auth.fetchWithAuth(
            `${url}?keys=${state.selectedBotIds.join(",")}&group=${groupTo}`
        );
        if (!reply.ok) {
            addLog({entry: `error ${reply.statusText} moving bots to ${groupTo}`, tags, type: LogType.error});
        } else {
            addLog({entry: `moving bots to ${groupTo} complete`, tags, type: LogType.end});
            const bots = appState.data.bots_statuses.filter(bot => selectedBots.includes(bot.key));
            bots.forEach(bot => (bot.scalingGroup = groupTo));
            appState.data.bots_statuses = [...appState.data.bots_statuses];
        }
    } catch (e) {
        addLog({entry: `error ${e} while moving bots to ${groupTo}`, tags, type: LogType.error});
    }
};
