import React, {useState, useEffect, Fragment} from "react";
import {AppState} from "../../../../../app/types";
import {Tabs, Typography, Box, Tab, Divider} from "@material-ui/core";
import {Sessions} from "./Sessions";
import {Faq} from "./faq";
import {Qna} from "./qna";
import {hasPermission} from "../../../../../app/permissions";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {Tasks} from "./tasks";
import {Dialogs} from "./dialogs";
import { TabPanel } from "../../../../../app/components/ToolInnerContent";

// interface TabPanelProps {
//     children?: React.ReactNode;
//     index: any;
//     value: any;
// }

// function TabPanel(props: TabPanelProps) {
//     const {children, value, index, ...other} = props;

//     return (
//         <Typography
//             component="div"
//             style={{overflowY: "scroll", height: "calc(100% - 32px)"}}
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}>
//             <Box>{children}</Box>
//         </Typography>
//     );
// }

export const FaqTabs = (p: {appState: AppState}) => {
    const {appState} = p;
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const bots = appState.data.bots_statuses;
    const state = getBotsState(appState);
    const selected = state.expandedBotId;
    if (!selected) return null;
    const bot = bots.find(b => b.key === selected);
    if (!bot) return null;

    return (
        <Fragment>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example">
                {hasPermission("faq", appState.auth.permissions, getBotName(bot)) ? (
                    <Tab label="FAQ" aria-label="faq" style={{minHeight: "initial"}} />
                ) : null}
                {hasPermission("qna", appState.auth.permissions, getBotName(bot)) ? (
                    <Tab label="Q&amp;A" aria-label="qna" style={{minHeight: "initial"}} />
                ) : null}
                {/* {hasPermission("faq", appState.auth.permissions, getBotName(bot)) ? (
                    <Tab label="Dialogs" aria-label="dialogs" style={{minHeight: "initial"}} />
                ) : null} */}
                {/* {hasPermission("faq", appState.auth.permissions, getBotName(bot)) ? (
                    <Tab label="Tasks" aria-label="tasks" style={{minHeight: "initial"}} />
                ) : null} */}
            </Tabs>
            <Divider />
                <Fragment>
                   {hasPermission("faq", appState.auth.permissions, getBotName(bot)) 
                   ? <TabPanel value={value} index={0}>
                        <Faq {...{appState}} />
                      </TabPanel>
                    : null}
                    {hasPermission("qna", appState.auth.permissions, getBotName(bot)) 
                    ? <TabPanel value={value} index={1}>
                        <Qna {...{appState}} />
                    </TabPanel>
                    : null}
                    {/* <TabPanel value={value} index={4}>
                        <Dialogs {...{appState}} />
                    </TabPanel> */}
                    {/* <TabPanel value={value} index={2}>
                        <Tasks {...{appState}} />
                    </TabPanel> */}
                </Fragment>
        </Fragment>
    );
};
