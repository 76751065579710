import {BotPropertiesState, AppState, BotGatewaysState, DashboardData} from "../../../app/types";
import {observable, action} from "mobx";
import {DashboardState} from "../components/dashboard/types";

const version = 13;

const handleOldVersion = action((appState: AppState) => {
    if (!appState.state.botsControl) return;
    const entry = `State version ${appState.state.botsControl.version} is != ${version}, using default state`;
    appState.actions.log &&
        appState.actions.log.addLog({
            tags: ["bots"],
            entry
        });
    appState.state.botsControl = undefined;
});

export const getBotsState = (appState: AppState) => {
    appState.state.botsControl &&
        (isNaN(appState.state.botsControl.version) || appState.state.botsControl.version !== version) &&
        handleOldVersion(appState);

    const state =
        appState.state.botsControl ||
        observable({
            selectedBotIds: [] as string[],
            filter: "",
            selectedTab: 0,
            path: [`bots`] as string[],
            expandedCustomers: {} as {[key: string]: boolean},
            propertiesChanges: {} as {[key: string]: BotPropertiesState},
            gateways: {} as {[key: string]: BotGatewaysState},
            dashboards: {} as {[key: string]: DashboardState},
            expandedBotId: "",
            isShowingReloadIFrame: false,
            isShowingAreYouSureReload: false,
            isShowingGroupChoiceDialog: false,
            version
        });
    !appState.state.botsControl && (appState.state.botsControl = state);
    return state;
};
