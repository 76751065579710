import React, {ComponentProps} from "react";
import {Box, Paper, Typography} from "@material-ui/core";
import { TabPanelProps } from "../../theme/types";

export const ToolInnerContent = (p: ComponentProps<any>) => {
    return (
        <Paper style={{backgroundColor:"#fafafa", margin: "5px", height: "100%",...p.style}} elevation={0}>
            {p.children}
        </Paper>
    );
};

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, expandClient, ...other } = props;

    return (
        <Typography
            style={{height:"calc(100% - 40px)",overflowY:"auto"}}
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {expandClient 
            ? <Box style={{overflowX:"auto", height: "100%"}}>{children}</Box>
            : <Box style={{overflowX:"auto"}}>{children}</Box>}
        </Typography>
    );
}

