import React, {useState, ComponentProps, useEffect} from "react";
import {SvgIcon, Tooltip, makeStyles} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {autorun} from "mobx";

const msToTime = (s: number) => {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = s - mins;

    return mins + hrs + "m:" + secs + "s";
};

const useStyles = makeStyles(() => {
    const staticIcon = {};
    const movingIcon = {animation: `$fullRotation 1000ms linear infinite`};
    const tooltip = {
        whiteSpace: "pre-line" as "pre-line"
    };
    return {
        "@keyframes fullRotation": {
            "0%": {
                transform: "rotate(0deg)"
            },
            "50%": {
                transform: "rotate(280deg)"
            },
            "100%": {
                transform: "rotate(360deg)"
            }
        },
        staticIcon,
        movingIcon,
        tooltip
    };
});

export const Reload = (p: {state: {isRotating: boolean; nextReload: number; isError: boolean}}) => {
    return function ReloadInternal(props: ComponentProps<any>) {
        const classes = useStyles(props);
        const [isRotating, setIsRotating] = useState(p.state.isRotating);
        const [isError, setIsError] = useState(p.state.isError);
        const [nextReload, setNextReload] = useState(p.state.nextReload);
        useEffect(() => {
            return autorun(() => {
                setIsRotating(p.state.isRotating);
                setIsError(p.state.isError);
                setNextReload(p.state.nextReload);
            });
        }, []);
        const iconStyle = isError ? {color: red[300]} : {};

        const getTT = () =>
            `Click to refresh ${
                nextReload
                    ? `
            auto in ${msToTime(nextReload - Date.now())}`
                    : ``
            }${
                isError
                    ? `
            LAST REFRESH PRODUCED AN ERROR`
                    : ``
            }`;
        const [tt, setTT] = useState(getTT());

        return (
            <Tooltip classes={{tooltip: classes.tooltip}} onPointerEnter={() => setTT(getTT())} title={tt}>
                <SvgIcon
                    style={iconStyle}
                    {...{...props, classes: undefined}}
                    className={isRotating ? classes.movingIcon : classes.staticIcon}>
                    <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </SvgIcon>
            </Tooltip>
        );
    };
};
