import React from "react";
import {GraphState, DataUnit} from "../../types";
import Chart from "react-google-charts";
import {Typography} from "@material-ui/core";

export const TableChart = (p: {graph: GraphState; filteredData: DataUnit[]}) => {
    const {filteredData} = p;
    const processedData = filteredData.map(fd => fd.y);

    return processedData.length > 1 ? (
        <Chart
            style={{display: "flex", justifyContent: "center", width: "100%"}}
            chartType="Table"
            loader={<div>Loading Chart</div>}
            data={processedData}
            // @ts-ignore
            options={{
                width: "100%",
                chartArea: {left: 40, right: 10, top: 10}
            }}
            formatters={p.graph.formatters}
            legendToggle
        />
    ) : (
        <div style={{height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
            <Typography variant="subtitle2">{"NO DATA"}</Typography>
        </div>
    );
};
