import React, {useState, useEffect, useCallback} from "react";
import {IconButton} from "@material-ui/core";
import {AppState} from "../../app/types";
import Storage from "@material-ui/icons/Storage";
import {Options} from "../../app/options/Options";
import {Reload} from "../../icons/Reload";
import {makeGetServers} from "./logic/makeGetServers";
import {autorun, observable, action} from "mobx";
import {getDrawerState} from "../../app/getDrawerState";
import {DrawerItem} from "../../app/components/DrawerItem";

const uid = "servers";

let timeout: NodeJS.Timeout;
const reloadDelay = 60 * 5 * 1000;

export const ServersDrawerItem = (props: {appState: AppState}) => {
    const {appState} = props;
    const drawer = getDrawerState(appState);
    const getServer = makeGetServers(appState);

    const [isError, setIsError] = useState(false);
    const [isReloading, setIsReloading] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [nextReload, setNextReload] = useState(Date.now() + reloadDelay);

    const actionIconParams = observable({
        isRotating: isReloading,
        nextReload,
        isError
    });

    const getData = useCallback(async () => {
        action(() => (actionIconParams.isRotating = true))();
        setIsReloading(true);
        const isSuccess = await getServer();
        setIsReloading(false);
        setIsError(!isSuccess);
        action(() => {
            actionIconParams.isRotating = false;
            actionIconParams.isError = !isSuccess;
            setNextReload(Date.now() + reloadDelay);
        })();
    }, [getServer, actionIconParams.isError, actionIconParams.isRotating]);

    useEffect(() => {
        timeout = setInterval(() => {
            if (drawer.selectedItemId === uid) {
                getData();
            }
        }, reloadDelay);
        return () => clearInterval(timeout);
    }, [drawer.selectedItemId, getData]);

    useEffect(() => {
        return autorun(() => {
            setIsFocus(drawer.selectedItemId === uid);
        });
    }, [drawer.selectedItemId]);

    appState.actions.servers = {getData};

    const actionIcon =
        isFocus || isReloading ? (
            <IconButton
                disabled={isReloading}
                style={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                    marginRight: "8px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                <Options
                    data={[
                        {
                            onClick: getData,
                            icon: Reload({state: actionIconParams}),
                            label: "Reload"
                        }
                    ]}
                />
            </IconButton>
        ) : (
            undefined
        );
    return (
        <DrawerItem
            {...{
                appState,
                uid,
                title: uid,
                tooltip: uid,
                itemIcon: <Storage color="primary" />,
                actionIcon
            }}
        />
    );
};
