import React from "react";
import {GraphState, DataUnit} from "../../types";
import Chart from "react-google-charts";
import {Typography} from "@material-ui/core";

export const BarChart = (p: {graph: GraphState; filteredData: DataUnit[]}) => {
    const {filteredData} = p;
    const dt = p.graph.dataTypes;
    const processedData = [
        [dt.x.value, ...dt.y.filter(y => y.isEnabled).map(y => y.value)],
        ...filteredData.map(du => [du.x, ...du.y])
    ];
    return processedData.length > 1 ? (
        <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={processedData}
            options={{
                // title: p.graph.title || `${xAxisLabel} by ${yAxisLabel}`,
                legend: p.graph.legend,
                chartArea: {left: 40, right: 10, top: 10}
            }}
            formatters={[
                {
                    type: "DateFormat" as "DateFormat",
                    column: 0,
                    options: {
                        formatType: "short"
                    }
                },
                {
                    type: "DateFormat" as "DateFormat",
                    column: 1,
                    options: {
                        formatType: "short"
                    }
                }
            ]}
            legendToggle
        />
    ) : (
        <div style={{height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
            <Typography variant="subtitle2">{"NO DATA"}</Typography>
        </div>
    );
};
