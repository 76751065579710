import {AppState, LogType, BotStatusData} from "../../../app/types";
import {action, observable} from "mobx";

const botlistUrl = "https://api.imperson.com/botlist";
const engineStatusUrl = "https://api.imperson.com/enginesStatus";

// const botlistUrl = "http://localhost:31755/botlist";
// const engineStatusUrl = "http://localhost:31755/enginesStatus";

const tags = ["bots"];

export const makeGetStatus = (appState: AppState) => async () => {
    const auth = appState.auth;
    const addLog = appState.actions.log.addLog;

    let tempBots: BotStatusData[] = [];

    addLog({tags, entry: "loading", type: LogType.start});

    try {
        await auth
            .fetchWithAuth(botlistUrl)
            .then(resp => {
                if (!resp.ok) {
                    throw Error("not ok");
                }
                return resp;
            })
            .then(resp => resp.json())
            .then(data => {
                tempBots = observable((data instanceof Array ? data : []) as BotStatusData[]);
                tempBots.forEach(bot => {
                    bot.canReload =
                        auth.permissions.has("/bot/deploy/*") ||
                        auth.permissions.has("/bot/deploy/" + bot.personalityID + "-" + bot.instanceID);
                });
                action(() => {
                    appState.data.bots_statuses = tempBots;
                    addLog({tags, entry: "bots list delivered"});
                })();
                return auth.fetchWithAuth(engineStatusUrl);
            })
            .then(resp => {
                if (!resp.ok) {
                    throw Error("not ok");
                }
                return resp;
            })
            .then(resp => resp.json())
            .then(data => {
                action(() => {
                    appState.data.engine_statuses = data;
                    addLog({tags, entry: "engines list delivered"});
                    addLog({tags, entry: "load complete", type: LogType.end});
                })();
                return data;
            });
    } catch (error) {
        action(() => {
            addLog({tags, entry: "load error", type: LogType.error});
        })();
        // console.log(error);
        return false;
    }

    return true;
};
