import React, {useEffect, useState} from "react";
import {Paper, Tabs, Tab} from "@material-ui/core";
import {Robots} from "../../../icons/Robots";
import {autorun} from "mobx";
import {Toolbox} from "../../../icons/Toolbox";
import {Database} from "../../../icons/Database";
import {UsersState} from "../../../app/types";

export const PermissionsTabs = (p: {state: UsersState}) => {
    const {state} = p;
    const [value, setValue] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        return autorun(() => setValue(state.selectedTab));
    });
    const onResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    return (
        <Paper style={{position: "sticky", top: "0px", zIndex: 11}} elevation={0}>
            <Tabs
                value={value}
                onChange={(e, newValue) => {
                    state.selectedTab = newValue;
                }}
                variant="standard"
                indicatorColor="primary"
                textColor="primary">
                <Tab value={0} icon={<Toolbox />} label={windowWidth < 600 ? "" : "General"} />
                <Tab value={1} icon={<Database />} label={windowWidth < 600 ? "" : "Databases"} />
                <Tab value={2} icon={<Robots />} label={windowWidth < 600 ? "" : "bots"} />
            </Tabs>
        </Paper>
    );
};
