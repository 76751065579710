import React, {useState, useEffect} from "react";
import {observable, action, autorun} from "mobx";
import {AppState} from "../../../../../../app/types";
import {Button} from "@material-ui/core";
import {makeGetExport} from "../../logic/getExport";
import {DashboardState} from "../../types";

export const ExportButton = (p: {appState: AppState; dashState: DashboardState}) => {
    const [isExporting, setIsExporting] = useState(false);
    const [isError, setIsError] = useState(false);

    const exportb = action(async () => {
        setIsExporting(true);
        const getExport = makeGetExport(p.appState);
        getExport(p.appState);
        setIsError(false);
        setIsExporting(false);
    });

    return (
        <Button
            onClick={exportb}
            disabled={isExporting}
            style={{
                color: "primary",
                marginLeft: "8px",
                marginRight: "8px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
            }}>
            {"export"}
        </Button>
    );
};
