import {AppState, LogType, ServerEngineData} from "../../../app/types";
import {action} from "mobx";

const serverStatusUrl = "https://api.imperson.com/serversStatus";
const engineStatusUrl = "https://api.imperson.com/enginesStatus";

const tags = ["servers"];

export const makeGetServers = (appState: AppState) => async () => {
    const auth = appState.auth;
    const addLog = appState.actions.log.addLog;

    addLog({tags, entry: "loading", type: LogType.start});

    try {
        let tempEngines: {[key: string]: ServerEngineData} = {};
        const enginesResponse = await auth.fetchWithAuth(engineStatusUrl);
        const enginesRaw = await enginesResponse.json();
        Object.keys(enginesRaw).forEach(id => {
            const rawData = enginesRaw[id];
            const xml = new DOMParser().parseFromString("<engines>" + rawData.response + "</engines>", "text/xml");
            const engineStatusArr = Array.from(xml.getElementsByTagName("engine-status"));
            engineStatusArr.forEach(engineStatus => {
                const engineId = engineStatus.getAttribute("engine-id") || "";
                tempEngines[engineId] = {
                    name: id,
                    id: engineId,
                    instance: engineStatus.getAttribute("server-id") || "",
                    status: engineStatus.getAttribute("unloading") === "true" ? "Unloading" : "Listening"
                };
            });
        });
        addLog({tags, entry: "engines data received"});
        const statusesResponse = await auth.fetchWithAuth(serverStatusUrl);
        if (!statusesResponse.ok) {
            throw Error("not ok");
        }
        const statusesRaw = await statusesResponse.json();

        for (var i = 0; i < statusesRaw.length; i++) {
            for (var j = 0; j < statusesRaw[i].deployments.length; j++) {
                for (var k = 0; k < statusesRaw[i].deployments[j].engines.length; k++) {
                    var enginevalue = statusesRaw[i].deployments[j].engines[k];
                    if (!tempEngines[enginevalue.id]) {
                        tempEngines[enginevalue.id] = {
                            id: enginevalue.id,
                            instance: enginevalue.instance,
                            name: enginevalue.name,
                            status: "Not listening"
                        };
                    } else {
                        tempEngines[enginevalue.id].status = "OK";
                    }
                }
            }
        }
        const engines = Object.keys(tempEngines)
            .map(engine => (tempEngines[engine].status === "OK" ? null : tempEngines[engine]))
            .filter(engine => engine) as ServerEngineData[];

        action(() => {
            appState.data.server_engines = engines;
            appState.data.server_instances = statusesRaw;
            addLog({tags, entry: "load complete", type: LogType.end});
        })();
    } catch (e) {
        addLog({tags, entry: "load failed", type: LogType.error});
        return false;
    }

    return true;
};
