const checkCanDashboard = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/dashboard/*") || permissions.has("/bot/dashboard/" + customerId);
};
const checkCanConversations = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/conversations/*") || permissions.has("/bot/conversations/" + customerId);
};
const checkCanConversationsupdate = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/conversationsupdate/*") || permissions.has("/bot/conversationsupdate/" + customerId);
};
const checkCanFaq = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/faq/*") || permissions.has("/bot/faq/" + customerId);
};
const checkCanQna = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/qna/*") || permissions.has("/bot/qna/" + customerId);
};
const checkCanFaqUpdate = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/faqupdate/*") || permissions.has("/bot/faqupdate/" + customerId);
};
const checkCanDeployCoco = (customerId: string, permissions: Set<string>) => {
    return permissions.has("/bot/cocodeploy/*") || permissions.has("/bot/cocodeploy/" + customerId);
};
const checkCanViewBot = (customerId: string, permissions: Set<string>) => {
    let a = 1;
    return permissions.has("/bot/view/*") || permissions.has("/bot/view/" + customerId);
};

export function hasPermission(uid: string, permissions: Set<string>, customerId?: string) {
    let answer = false;
    switch (uid) {
        case "view":
            answer = checkCanViewBot(customerId || "*", permissions);
            break;
        case "servers":
            answer = permissions.has("/toolbox/servers");
            break;
        case "bots":
            answer = permissions.has("/toolbox/status") && permissions.has("/bot/list");
            break;
        case "Bot Links":
            answer =
                permissions.has("/toolbox/status") &&
                permissions.has("/bot/list") &&
                checkCanViewBot(customerId || "*", permissions);
            break;
        case "Bot Properties":
            answer = permissions.has("/toolbox/botadmin");
            break;
        case "Bot Engines":
            answer = permissions.has("/toolbox/botadmin");
            break;
        case "Bot Broadcasts":
            answer = permissions.has("/toolbox/botadmin");
            break;
        case "gateways":
            answer = permissions.has("/toolbox/botadmin");
            break;
        case "conversations":
            answer = checkCanConversations(customerId || "*", permissions);
            break;
        case "conversationsupdate":
            answer = checkCanConversationsupdate(customerId || "*", permissions);
            break;
        case "dashboard":
            answer = checkCanDashboard(customerId || "*", permissions);
            break;
        case "faq":
            answer = checkCanFaq(customerId || "*", permissions);
            break;
        case "qna":
            answer = checkCanQna(customerId || "*", permissions);
            break;
        case "faqupdate":
            answer = checkCanFaqUpdate(customerId || "*", permissions);
            break;
        case "Coco Deploy":
            answer = checkCanDeployCoco(customerId || "*", permissions);
            break;
        case "users":
            answer = permissions.has("/toolbox/users") && permissions.has("/bot/list");
            break;
        case "home":
            answer = true;
            break;
        default:
            break;
    }
    return answer;
}
