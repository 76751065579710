import {DefaultableObjectState} from "../../../../kvp/types";
import {GatewayData} from "../../../../app/types";

const getDefaultByType = (type: string) => {
    let reply: string | number | boolean = "";

    switch (type) {
        case "string":
            break;
        case "number":
            reply = 0;
            break;
        case "boolean":
            reply = false;
            break;
        default:
            break;
    }
    return reply;
};

const getProperty = (key: string, props: any, type: string = "string") => {
    const d = getDefaultByType(type);
    return {
        value: props[key] || d,
        isDefault: !props.hasOwnProperty(key),
        isUpdated: false,
        default: d,
        type
    };
};

const getProperties = (keys: string[], props: any, types: {[key: string]: string} = {}) => {
    return keys.reduce(
        (acc, cur) => {
            acc[cur] = getProperty(cur, props, types[cur] || "string");
            return acc;
        },
        {} as {[key: string]: any}
    );
};

export const getGatewayProperties = (gateway: GatewayData): {[key: string]: DefaultableObjectState} => {
    const {gatewayID, configData} = gateway;
    let response = {} as any;
    const props = configData || {};

    switch (gatewayID) {
        case "TWIT":
            gateway.hasOwnProperty("callbackURL") && (props.callbackURL = gateway.callbackURL);
            gateway.hasOwnProperty("externalID") && (props.externalID = gateway.externalID);
            response = getProperties(
                [
                    "callbackURL",
                    "externalID",
                    "botBypassPhrase",
                    "twitterapiurl",
                    "environment",
                    "consumerKey",
                    "consumerSecret",
                    "accessToken",
                    "accessTokenSecret"
                ],
                props
            );
            break;
        case "FBSR":
            gateway.hasOwnProperty("callbackURL") && (props.callbackURL = gateway.callbackURL);
            gateway.hasOwnProperty("externalID") && (props.externalID = gateway.externalID);
            response = getProperties(["callbackURL", "externalID", "fburl", "verify_token", "access_token"], props);
            break;
        case "KIK":
            gateway.hasOwnProperty("callbackURL") && (props.callbackURL = gateway.callbackURL);
            gateway.hasOwnProperty("externalID") && (props.externalID = gateway.externalID);
            response = getProperties(
                ["callbackURL", "externalID", "kikurl", "kikusername", "kikapikey", "batchResponses"],
                props,
                {batchResponses: "boolean"}
            );
            break;
        case "SMS":
            gateway.hasOwnProperty("callbackURL") && (props.callbackURL = gateway.callbackURL);
            response = getProperties(["callbackURL", "baseurl", "auth_id", "mynumber", "auth_token"], props);
            break;
        case "SKTXT":
            gateway.hasOwnProperty("callbackURL") && (props.callbackURL = gateway.callbackURL);
            response = getProperties(
                ["callbackURL", "serviceUrl", "oauthServerUrl", "client_id", "client_secret"],
                props
            );
            break;
        case "PHONE":
            gateway.hasOwnProperty("externalID") && (props.externalID = gateway.externalID);
            response = getProperties(
                ["externalID", "rawResponses", "voiceResponses", "voiceFolder", "voiceBucket", "textToSpeechVoice"],
                props,
                {rawResponses: "boolean", voiceResponses: "boolean"}
            );
            break;
        case "WEB":
            response = getProperties(["personality", "firstInput"], props);
            break;
        case "APP":
            response = getProperties(
                ["rawResponses", "ssmlSoundResponses", "voiceResponses", "voiceFolder", "voiceBucket"],
                props,
                {
                    rawResponses: "boolean",
                    ssmlSoundResponses: "boolean",
                    voiceResponses: "boolean"
                }
            );
            break;
        case "ECHO":
            response = getProperties(
                ["rawResponses", "ssmlSoundResponses", "voiceResponses", "voiceFolder", "voiceBucket", "ssmlVoiceName"],
                props,
                {
                    rawResponses: "boolean",
                    ssmlSoundResponses: "boolean",
                    voiceResponses: "boolean"
                }
            );
            break;
        case "GHOME":
            response = getProperties(
                ["rawResponses", "ssmlSoundResponses", "voiceResponses", "voiceFolder", "voiceBucket", "actionName"],
                props,
                {
                    rawResponses: "boolean",
                    ssmlSoundResponses: "boolean",
                    voiceResponses: "boolean"
                }
            );
            break;
        default:
            break;
    }
    return response;
};
