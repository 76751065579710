import React from "react";
import {DashboardState, Layout as LayoutType} from "../types";
import {Chart} from "./Chart";
import {Grid} from "@material-ui/core";
import {uuid} from "../test/uuid";
import {AppState, DashboardData} from "../../../../../app/types";

export const Layout = (p: {appState: AppState; dashState: DashboardState; config: DashboardData}) => {
    const {appState} = p;
    const {config} = p;
    // console.log(`layout renders ${config.graphs.length}`);

    const container = (
        <Grid
            spacing={1}
            container
            item
            zeroMinWidth
            style={{
                width: "100%",
                margin: "0px",
                padding: "0px"
            }}>
            <Grid spacing={1} style={{margin: "0px", padding: "0px"}} item container zeroMinWidth>
                {config && !config.miniregular.mini
                    ? ""
                    : config.miniregular.mini.map((data, i) => {
                          return (
                              <Grid key={uuid()} item xs={12} sm={6} md={4} lg={2} xl={2}>
                                  <Chart
                                      key={data.layout.id}
                                      {...{
                                          appState,
                                          graph: data.graph
                                      }}
                                  />
                              </Grid>
                          );
                      })}
            </Grid>
            {config && !config.miniregular.regular
                ? ""
                : config.miniregular.regular
                      .filter(r => r.length)
                      .map((row, i) => {
                          return (
                              <Grid
                                  spacing={1}
                                  style={{margin: "0px", width: "100%", padding: "0px"}}
                                  key={`${i}`}
                                  item
                                  container
                                  zeroMinWidth>
                                  {row.map((data, ii) => {
                                      return (
                                          <Grid
                                              style={{margin: "0px", width: "100%"}}
                                              key={uuid()}
                                              item
                                              xs={data.layout.xs}
                                              sm={data.layout.sm}
                                              md={data.layout.md}
                                              lg={data.layout.lg}
                                              xl={data.layout.xl}
                                              zeroMinWidth>
                                              <Chart
                                                  key={data.layout.id}
                                                  {...{
                                                      appState,
                                                      graph: data.graph
                                                  }}
                                              />
                                          </Grid>
                                      );
                                  })}
                              </Grid>
                          );
                      })}
        </Grid>
    );

    return container;
};
