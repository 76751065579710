import {AppState, LogType} from "../types";
import {observable} from "mobx";

const tags = ["TOOLBOX"];

export const loadState = async (appState: AppState) => {
    try {
        appState.actions.log.addLog({entry: "loading  state", tags, type: LogType.start});
        const reply = await appState.auth.fetchWithAuth(`/load?userId=${appState.auth.userId}`);
        const json = observable(await reply.json());
        if (json.error) {
            throw new Error(json.error);
        }
        appState.actions.log.addLog({entry: "state loaded", tags, type: LogType.end});
        return json.data;
    } catch (e) {
        appState.actions.log.addLog({entry: "state load failed", tags, type: LogType.error});
        return null;
    }
};
