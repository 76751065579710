import { AppState } from "../../../../../app/types";
import { mergeConv } from "../components/Log";
import { agentType, conversation, message, statusUpdates } from "../types";

const url = "https://api.imperson.com/conversations";
const update = "https://api.imperson.com/conversation?action=update";
const putmsg = "https://api.imperson.com/message";
// const url = "http://localhost:31755/conversations";
// const update = "http://localhost:31755/conversation?action=update";
// const putmsg = "http://localhost:31755/message";

export const postNewMessage = (appState: AppState, msg: message, bot: string, key: string, gw: string, userid: string) => {
    return appState.auth.fetchWithAuth(putmsg,
        {
            method: "POST",
            body: JSON.stringify({ ...msg, bot: bot, key: key, gw: gw, userid: userid })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json()
            }
        })
        .catch(error => {
            // console.log("error", error);
            return Promise.resolve([]);
        });
};

export const updateConversation = (appState: AppState, bot: string, ownerid: string, status: number, conversations: conversation[], selectedConv: number, setConversations: any) => {
    return appState.auth.fetchWithAuth(update,
        {
            method: "POST",
            body: JSON.stringify({ "bot": bot, "cuid": conversations[selectedConv].cuid, "ownerid": ownerid, "status": status })
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json()
            }
        })
        .catch(error => {
            // console.log("error", error);
            return Promise.resolve([]);
        });
};

export const getConversations = (appState: AppState, bot: string, agent: agentType, setAgent: any, conversations: conversation[], setConversations: any, setAlerts: any, abortController: AbortController) => {
    return appState.auth.fetchWithAuth(url,
        {
            method: "POST",
            body: JSON.stringify({ "bot": bot, "updated": (conversations.length ? conversations[0].updated : ""), "agent": agent }),
            signal: abortController.signal
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json()
            }
        })
        .then(data => {
            const updates  = data as statusUpdates;
            const respconvs = updates.conversations as conversation[];
            const newconvs = [...conversations] as conversation[];
            respconvs.forEach(respconv => {
                var newi = newconvs.findIndex(sconv => sconv.cuid === respconv.cuid);
                if (newi !== -1) {
                    newconvs[newi] = mergeConv(newconvs[newi], respconv)
                } else {
                    newconvs.push(respconv);
                };
            });
            newconvs.sort((a, b) => a.updated > b.updated ? -1 : (a.updated < b.updated ? 1 : 0));
            setConversations(newconvs);
            setAgent(data.agent);
            setAlerts(data.alerts);
            getConversations(appState, bot, data.agent, setAgent, newconvs, setConversations, setAlerts, abortController);
        })
        .catch(error => {
            // console.log("conversations fetch error", error);
            if (error.name !== 'AbortError') {
                setTimeout(() => getConversations(appState, bot, agent, setAgent, conversations, setConversations, setAlerts, abortController), 5 * 60000);
                return Promise.resolve([]);
                  }
        });
};
