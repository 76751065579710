import React, {useState, useEffect} from "react";
import {DefaultableObjectState} from "./types";
import {Tooltip, IconButton, Button} from "@material-ui/core";
import {ResetToDefault} from "../icons/ResetToDefault";
import {CheckboxTrue} from "../icons/CheckboxTrue";
import {CheckboxFalse} from "../icons/CheckboxFalse";

const styleCenter = {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    justifyContent: "center" as "center"
};

export const KvPBoolean = (p: {data: DefaultableObjectState; updateChanged: () => void; disabled: boolean}) => {
    const {data, updateChanged, disabled} = p;
    const [value, setValue] = useState(Boolean(data.value));
    useEffect(() => {
        setValue(Boolean(data.value));
    }, [p.data]);

    return (
        <div style={{display: "grid", gridTemplateColumns: "1fr auto", gridColumnGap: "8px"}}>
            <Button
                color={value ? "secondary" : "default"}
                fullWidth
                disabled={disabled}
                onClick={() => {
                    data.value = !data.value;
                    data.isUpdated = true;
                    data.isDefault = false;
                    setValue(Boolean(data.value));
                    updateChanged();
                }}>
                {value ? <CheckboxTrue /> : <CheckboxFalse />}
                {value ? "Enabled" : "Disabled"}
            </Button>
            <div style={styleCenter}>
                <Tooltip title="Revert to default">
                    <IconButton
                        size="small"
                        style={{height: "30px"}}
                        onClick={() => {
                            data.value = data.default;
                            data.isDefault = true;
                            data.isUpdated = true;
                            setValue(Boolean(data.value));
                            updateChanged();
                        }}>
                        {!data.isDefault && <ResetToDefault />}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};
