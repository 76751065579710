import React, {useState, useEffect} from "react";
import {Typography, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => {
    const centeredText = {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center"
    };
    return {
        centeredText,
        clickableText: {
            cursor: "pointer",
            ...centeredText
        },
        drilldownText: {
            paddingLeft: `${theme.spacing(1)}px`,
            paddingRight: `${theme.spacing(1)}px`,
            ...centeredText
        },
        breadcrumbsContainer: {
            display: "flex",
            whiteSpace: "nowrap"
        }
    };
});

export type BreadcrumbsState = {
    path: string[];
    goBackUpPath: (steps: number) => void;
    addToPath: (value: string) => void;
};

export const Breadcrumbs = (p: BreadcrumbsState) => {
    const classes = useStyles();
    const [path, setPath] = useState(p.path);
    useEffect(() => {
        setPath(p.path);
    }, [p.path]);

    return (
        <div key={"breadcrumbs"} className={classes.breadcrumbsContainer}>
            {path.map((node, i) => {
                const isLast = i === path.length - 1;
                return (
                    <div key={`path-${i}`} className={classes.breadcrumbsContainer}>
                        <Typography
                            variant={i ? "body2" : "body1"}
                            color={!isLast ? "primary" : "initial"}
                            className={!isLast ? classes.clickableText : classes.centeredText}
                            onClick={() => p.goBackUpPath(i)}>
                            {node}
                        </Typography>
                        {!isLast && <div className={classes.drilldownText}>{">"}</div>}
                    </div>
                );
            })}
        </div>
    );
};
