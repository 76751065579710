import React, {useState} from "react";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {getGatewayProperties} from "./getGatewayProperties";
import {gatewayTypes} from "../dashboard/types";

const gatewayNames = gatewayTypes;

export const AddGatewaysMenu = (p: {appState: AppState; botGateways: {[gatewayId: string]: any}}) => {
    const {appState, botGateways} = p;
    const state = getBotsState(appState);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const addedBotKeys = Object.keys(botGateways);
    return (
        <div>
            <Button color={"primary"} onClick={handleClick}>
                Add Gateway
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {gatewayNames
                    .filter(gw => !addedBotKeys.includes(gw))
                    .map((name, i) => {
                        return (
                            <MenuItem
                                key={`name ${i}`}
                                value={name}
                                onClick={() => {
                                    const deleted = state.gateways[state.expandedBotId].deletedGateways[name];
                                    delete state.gateways[state.expandedBotId].deletedGateways[name];
                                    state.gateways[state.expandedBotId].gatewayChanges[name] =
                                        deleted || getGatewayProperties({gatewayID: name});
                                    state.gateways[state.expandedBotId].selectedGatewayId = name;
                                    handleClose();
                                }}>
                                {name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </div>
    );
};
