import React, {useEffect, useState} from "react";
import {Paper, Tabs, Tab} from "@material-ui/core";
import {autorun} from "mobx";
import {AppState} from "../../../../app/types";
import {Properties} from "../../../../icons/Properties";
import {Engine} from "../../../../icons/Engine";
import {Broadcast} from "../../../../icons/Broadcast";
import {getBotsState} from "../../logic/getBotsState";
import {Link} from "../../../../icons/Link";
import {hasPermission} from "../../../../app/permissions";
import {getBotName} from "../../../users/components/utils/getBotName";

export const BotsControlDetailsTabs = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [value, setValue] = useState(state.selectedTab);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        return autorun(() => setValue(state.selectedTab));
    });
    const onResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const bots = appState.data.bots_statuses;
    const selected = state.expandedBotId;
    if (!selected) return null;
    const bot = bots.find(b => b.key === selected);
    if (!bot) return null;

    return (
        <Paper style={{position: "sticky", top: "0px", zIndex: 11}} elevation={0}>
            <Tabs
                value={value}
                onChange={(e, newValue) => {
                    state.selectedTab = newValue;
                }}
                variant="standard"
                indicatorColor="primary"
                textColor="primary">
                {hasPermission("Bot Links", appState.auth.permissions, getBotName(bot)) && (
                    <Tab value={0} icon={<Link />} label={windowWidth < 600 ? "" : "Links"} />
                )}
                {hasPermission("Bot Properties", appState.auth.permissions) && (
                    <Tab value={1} icon={<Properties />} label={windowWidth < 600 ? "" : "Properties"} />
                )}
                {hasPermission("Bot Engines", appState.auth.permissions) && (
                    <Tab value={2} icon={<Engine />} label={windowWidth < 600 ? "" : "Engines"} />
                )}
                {hasPermission("Bot Broadcasts", appState.auth.permissions) && (
                    <Tab value={3} icon={<Broadcast />} label={windowWidth < 600 ? "" : "Broadcasts"} />
                )}
                {/* {hasPermission("gateways", appState.auth.permissions) && (
                    <Tab value={4} icon={<Gateway />} label={windowWidth < 600 ? "" : "gateways"} />
                )} */}
            </Tabs>
        </Paper>
    );
};
