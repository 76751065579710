import React, {useEffect} from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {AppState} from "../../../app/types";
import {observable, autorun} from "mobx";
import {AddUserDialog} from "./AddUserDialog";
import {getUsersState} from "../logic/getUsersState";
import {ToolInnerContent} from "../../../app/components/ToolInnerContent";
import {SelectionCheckbox} from "../../../app/components/SelectionCheckbox";

export const UsersList = (p: {appState: AppState} & {classes?: any}) => {
    const {appState} = p;
    const state = getUsersState(appState);
    const [selected, setSelected] = React.useState([] as string[]);
    const [numSelected, setNumSelected] = React.useState(state.selectedUserIds.length);
    const [numUsers, setNumUsers] = React.useState(appState.data.users.length);

    useEffect(() => {
        return autorun(() => {
            setSelected(state.selectedUserIds);
            setNumSelected(state.selectedUserIds.length); // for getting updates on indices length change
            setNumUsers(appState.data.users.length); // for getting updates on users length change
        });
    }, [state.selectedUserIds, state.selectedUserIds.length, appState.data.users.length]);

    return (
        <ToolInnerContent style={{height: "50%", overflow: "auto", border: "1px solid lightgray"}}>
            {!numUsers ? null : (
                <div>
                    <Table padding="none" style={{position: "sticky", top: "0px", background: "white", zIndex: 2}}>
                        <TableHead style={{position: "sticky", top: "0px"}}>
                            <TableRow>
                                <TableCell key="cellIsSelected" padding="checkbox">
                                    <SelectionCheckbox
                                        {...{
                                            isSelected: selected.length === appState.data.users.length,
                                            onClick: (e: any) => {
                                                e.stopPropagation();
                                                const isSelected = selected.length === appState.data.users.length;
                                                isSelected
                                                    ? (state.selectedUserIds = [])
                                                    : (state.selectedUserIds = appState.data.users.map(
                                                          user => user.user_id
                                                      ));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell key="cell0" style={{width: "calc(40% - 24px)"}}>
                                    Email
                                </TableCell>
                                <TableCell key="cell1" style={{width: "30%"}}>
                                    First Name
                                </TableCell>
                                <TableCell key="cell2" style={{width: "30%"}}>
                                    Last Name
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Table padding="none" style={{position: "relative"}}>
                        <TableHead
                            style={{position: "sticky" as "sticky", top: "0px", visibility: "collapse" as "collapse"}}
                            hidden={true}>
                            <TableRow>
                                <TableCell key="cellIsSelected" padding="checkbox">
                                    <SelectionCheckbox
                                        {...{
                                            isSelected: selected.length === appState.data.users.length,
                                            onClick: (e: any) => {
                                                e.stopPropagation();
                                                const isSelected = selected.length === appState.data.users.length;
                                                isSelected
                                                    ? (state.selectedUserIds = [])
                                                    : (state.selectedUserIds = appState.data.users.map(
                                                          user => user.user_id
                                                      ));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell key="cell0" style={{width: "calc(40% - 24px)"}}>
                                    Email
                                </TableCell>
                                <TableCell key="cell1" style={{width: "30%"}}>
                                    First Name
                                </TableCell>
                                <TableCell key="cell2" style={{width: "30%"}}>
                                    Last Name
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {appState.data.users.map((entry, i) => {
                                return (
                                    <TableRow
                                        key={`row ${i}`}
                                        role="button"
                                        hover
                                        onClick={() => (state.selectedUserIds = observable([entry.user_id]))}>
                                        <TableCell padding="checkbox">
                                            <SelectionCheckbox
                                                {...{
                                                    isSelected: selected.includes(entry.user_id),
                                                    onClick: (e: any) => {
                                                        e.stopPropagation();
                                                        const isSelected = selected.includes(entry.user_id);
                                                        isSelected
                                                            ? state.selectedUserIds.splice(
                                                                  selected.indexOf(entry.user_id),
                                                                  1
                                                              )
                                                            : state.selectedUserIds.push(entry.user_id);
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{entry.email}</TableCell>
                                        <TableCell>{entry.given_name}</TableCell>
                                        <TableCell>{entry.family_name}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            )}
            <AddUserDialog
                {...{
                    state,
                    addUser: appState.actions.users
                        ? appState.actions.users.addUser
                        : () => console.error("could not find appState.actions.users.addUser")
                }}
            />
        </ToolInnerContent>
    );
};
