import React, { useState, Fragment, useEffect } from "react";
import { autorun } from "mobx";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
    Menu,
    MenuItem,
    Typography,
    IconButton, Grid
} from "@material-ui/core";
import { FaqType, OtherBotsType } from "../types";
import EditIcon from "@material-ui/icons/Edit";
import { makeGetFaqData, makePutFaqData, makeGetOtherBotData, makeCopyCategory } from "../logic/getFaqData";
import { AppState } from "../../../../../app/types";
import { getBotsState } from "../../../../botsControl/logic/getBotsState";
import { getBotName } from "../../../../users/components/utils/getBotName";
import { hasPermission } from "../../../../../app/permissions";
import { uuid } from "../test/uuid";

export const Faq = (p: { appState: AppState }) => {
    const { appState } = p;
    const state = getBotsState(appState);
    const [answers, setAnswers] = useState([] as FaqType);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [inputValue, setInputValue] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [botMenuEl, setBotMenuEl] = useState<null | HTMLElement>(null);
    const [categories, setCategories] = useState([] as FaqType);
    const [category, setCategory] = useState(0);
    const [otherBots, setOtherBots] = useState([] as OtherBotsType);
    const [isConfirmCopyDialogOpen, setIsConfirmCopyDialogOpen] = useState(false);
    const [selectedCopyBot, setSelectedCopyBot] = useState("");
    const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
    useEffect(() => {
        // console.log(`dashboard get faq`);
        const getFaqData = makeGetFaqData(p.appState);
        getFaqData().then(value => {
            if ((value as FaqType).length && (value as FaqType)[0].qid) {
                const cats = [] as FaqType;
                (value as FaqType).forEach(val => {
                    if (cats.find(x => x.category === val.category) === undefined) cats.push(val);
                });
                setCategories(cats);
                setAnswers(value as FaqType);
            }
        });
    }, [p.appState]);
    useEffect(() => {
        if (categories.length) {
            const getOtherBotData = makeGetOtherBotData(p.appState);
            getOtherBotData(categories[category].category).then(value => {
                // console.log(value);
                if (value.error) {
                    setOtherBots([] as OtherBotsType);
                } else {
                    setOtherBots(value);
                    // console.log(`dashboard got otherbots ` + categories.length + " " + category + " " + answers.length + " " + otherBots.length);
                }
            });
        }
    }, [categories, category]);
    let [filteredAnswers] = useState([] as FaqType);
    autorun(() => {
        if (categories.length && answers.length) {
            // console.log(`dashboard get answers ` + categories.length + " " + category + " " + answers.length);
            filteredAnswers = answers.filter(x => x.category === categories[category].category);
        };
    });
    // autorun(() => {
    //     if (categories.length && answers.length) {
    //     };
    // });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleBotMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => setBotMenuEl(event.currentTarget);
    const handleBotMenuClose = () => setBotMenuEl(null);
    if (!bot || !categories) return null;
    return (
        <Fragment>
            <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", padding: "10px" }}>
                <div style={{ display: "flex", alignContent: "center" }}>
                    <Typography variant="body2">Category:&nbsp;</Typography>
                    <Button variant="outlined" onClick={handleClick}>
                        {categories.length && categories[category] ? categories[category].categoryName : ""}
                    </Button>
                </div>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {categories.map((cat, i) => {
                        return (
                            <MenuItem
                                key={`${i}`}
                                value={cat.category}
                                onClick={() => {
                                    setCategory(i);
                                    handleClose();
                                }}>
                                {cat.categoryName}
                            </MenuItem>
                        );
                    })}
                </Menu>
                {otherBots.length ?
                    <Fragment>
                        <Button variant="outlined" onClick={handleBotMenuClick}>Copy Answers From...</Button>
                        <Menu anchorEl={botMenuEl} open={Boolean(botMenuEl)} onClose={handleBotMenuClose}>
                            {otherBots.map((obot, i) => {
                                return (
                                    <MenuItem
                                        key={`${i}`}
                                        value={obot.bot}
                                        onClick={() => {
                                            setSelectedCopyBot(obot.bot);
                                            handleBotMenuClose();
                                            setIsConfirmCopyDialogOpen(true);
                                        }}>
                                        {obot.bot} ({obot.count} answers)
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Fragment>
                    : null}
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={isConfirmCopyDialogOpen}
                    onClose={() => { setIsConfirmCopyDialogOpen(false); setSelectedCopyBot(""); }}
                    aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to copy and overwrite all the answers for this category from "{selectedCopyBot}"?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setIsConfirmCopyDialogOpen(false); setSelectedCopyBot(""); }} color="primary">
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                const copyCategory = makeCopyCategory(p.appState);
                                copyCategory(categories[category].category, selectedCopyBot).then(x => {
                                    console.log(`dashboard get faq data after copy`);
                                    const getFaqData = makeGetFaqData(p.appState);
                                    getFaqData().then(value => {
                                        if ((value as FaqType).length && (value as FaqType)[0].qid) {
                                            const cats = [] as FaqType;
                                            (value as FaqType).forEach(val => {
                                                if (cats.find(x => x.category === val.category) === undefined) cats.push(val);
                                            });
                                            setCategories(cats);
                                            setAnswers(value as FaqType);
                                        }
                                    });
                                });
                                setIsConfirmCopyDialogOpen(false);
                                setSelectedCopyBot("");
                            }}
                            color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "5%" }}>#</TableCell>
                        <TableCell style={{ width: "30%" }}>Question</TableCell>
                        <TableCell style={{ width: "60%" }}>Answer</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredAnswers.map((value, i) => {
                        return (
                            <TableRow hover key={i} onClick={() => {
                                if (hasPermission("faqupdate", appState.auth.permissions, getBotName(bot))) {
                                    setInputValue(value.answer);
                                    setSelectedIndex(i);
                                    setIsEditDialogOpen(true);
                                }
                            }}>
                                <TableCell style={{ width: "5%" }}>{value.index}</TableCell>
                                <TableCell style={{ width: "30%", whiteSpace: "initial" }}>{value.question}</TableCell>
                                <TableCell style={{ width: "60%", whiteSpace: "initial" }}>
                                    {value.answer.split(/\r?\n/).map((val, r) => {
                                        return r % 2 === 0 ? (
                                            <div key={uuid()} id={uuid()}>
                                                {val}
                                            </div>
                                        ) : (
                                            <div style={{ backgroundColor: "#F6F6F6" }} key={uuid()} id={uuid()}>
                                                {val}
                                            </div>
                                        );
                                    })}
                                </TableCell>
                                <TableCell>
                                    {hasPermission("faqupdate", appState.auth.permissions, getBotName(bot)) ? (
                                        <IconButton
                                            size="small"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setInputValue(value.answer);
                                                setSelectedIndex(i);
                                                setIsEditDialogOpen(true);
                                            }}>
                                            <EditIcon />
                                        </IconButton>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {filteredAnswers.length ? (
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={isEditDialogOpen}
                    onClose={() => setIsEditDialogOpen(false)}
                    aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <DialogContentText>
                            Please provide the answer for question "{filteredAnswers[selectedIndex].question}":
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="name"
                            label="Answer"
                            type="text"
                            fullWidth
                            multiline
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsEditDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                const putFaq = makePutFaqData(appState);
                                putFaq(getBotName(bot), filteredAnswers[selectedIndex].qid, inputValue);
                                filteredAnswers[selectedIndex].answer = inputValue;
                                setIsEditDialogOpen(false);
                            }}
                            color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                ""
            )}
        </Fragment>
    );
};
