import React, {ComponentProps} from "react";
import {Paper} from "@material-ui/core";

export const ToolContent = (p: ComponentProps<any>) => {
    return (
        <Paper
            style={{
                overflow:"hidden",
                backgroundColor: "#fafafa",
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column" as "column",
                ...p.style
            }}
            elevation={0}>
            {p.children}
        </Paper>
    );
};
