import {observable, action} from "mobx";
import {AppState} from "./types";

const version = 2;

const handleOldVersion = action((appState: AppState) => {
    if (!appState.state.drawer) return;
    const entry = `State version ${appState.state.drawer.version} is != ${version}, using default state`;
    appState.actions.log &&
        appState.actions.log.addLog({
            tags: ["DRAWER"],
            entry
        });
    appState.state.drawer = undefined;
});

export const getDrawerState = (appState: AppState) => {
    appState.state.drawer &&
        (isNaN(appState.state.drawer.version) || appState.state.drawer.version !== version) &&
        handleOldVersion(appState);

    const state =
        appState.state.drawer ||
        observable({
            isCollapsed: false,
            selectedItemId: "home",
            isClearingState: false,
            version
        });
    !appState.state.drawer && (appState.state.drawer = state);
    return state;
};
