import React, {useEffect, useState} from "react";
import {AppState} from "../../../app/types";
import {ActionsBar} from "../../../app/components/ActionsBar";
import {autorun, action} from "mobx";
import {Typography} from "@material-ui/core";
import {getUsersState} from "../logic/getUsersState";

export const UsersActionsBar = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getUsersState(appState);
    const [isCanSave, setIsCanSave] = useState(false);
    const [numSelected, setNumSelected] = useState(0);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        return autorun(() => {
            setIsCanSave(!!state.updates.length);
        });
    }, [state.updates.length]);

    useEffect(() => {
        return autorun(() => {
            setNumSelected(state.selectedUserIds.length);
        });
    }, [state.selectedUserIds.length]);

    useEffect(() => {
        return autorun(() => {
            setIsReady(!!appState.data.users.length);
        });
    }, [appState.data.users.length]);

    const options = [
        {
            title: "Selected",
            component: () => (
                <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Typography variant="caption" color="textPrimary" key={`before_numSelected_badge`}>
                        {numSelected} selected
                    </Typography>
                </div>
            )
        },
        {
            title: "Save",
            disabled: !isCanSave,
            action: () => {
                appState.actions.users && appState.actions.users.saveUsers();
            }
        },
        {
            title: "Discard Changes",
            disabled: !isCanSave,
            action: action(() => {
                while (state.updates.length) state.updates.pop();
            })
        },
        {
            title: "Add New User",
            disabled: !isReady,
            action: action(() => {
                state.isShowingAddUser = true;
            })
        }
    ];
    const before = [<Typography variant="body1" color="textPrimary" key={`users`}>{`Users`}</Typography>];
    return <ActionsBar {...{before, options}} />;
};
