import { Auth } from "../auth/types";
import { DefaultableObjectState } from "../kvp/types";
import { DashboardState, GraphState, Layout, QueryState, miniRegularGraphs } from "../content//botsControl/components/dashboard/types";

export type DrawerItemData = {
    uid: string;
    drawerItem: (props: { appState: AppState }) => JSX.Element;
    content: (props: { appState: AppState } & any) => JSX.Element | null;
};

export type UserData = {
    user_id: string;
    email: string;
    given_name: string;
    family_name: string;
    app_metadata: { permissions: string[] };
};

export type UserUpdateData = {
    user_id: string;
    app_metadata: { permissions: string[] };
};

export type BotsData = { personalityID: string; instanceID: string };

export type ServerInstanceData = {
    activeServers: number;
    deployments: [
        {
            activeServers: number;
            engines: [{ id: string; instance: string; name: string }];
            key: string;
            maxServers: number;
            name: string;
            startTime: string;
            status: string;
        }
    ];
    group: string;
    instance: string;
    maxServers: number;
    startTime: string;
    unloading: boolean;
};

export type ServerEngineData = {
    id: string;
    instance: string;
    status: "Unloading" | "Listening" | "Not listening" | "OK";
    name: string;
};

export type EngineStatusData = {
    activeSessions: number;
    maxSessions: number;
    response: string;
    servers: number;
    totalSessions: number;
};

export type GatewayData = { gatewayID: string; configData?: any; callbackURL?: string; externalID?: string };
export type BotStatusData = {
    canReload: boolean;
    configData: BotPropertiesState;
    // configData: any;
    contentDB: string;
    customer: string;
    editContentURL: string;
    gateways: GatewayData[];
    instanceID: string;
    isProduction: boolean;
    key: string;
    lastReload: string;
    launchDate: string;
    parentPersonalityID: string;
    personalityID: string;
    scalingGroup: string;
    scriptURL: string;
    dashboardURL: string;
    secret: string;
    viewSessionsURL: string;
    webTestURL: string;
    editParentContentURL: string;
};

export type LogEntryData = {
    entry: string;
    style?: any;
    tags: string[];
    type: LogType;
    timestamp: Date;
};

export type DatabaseData = {
    name: string;
};

export type SessionsRow = {
    userid: string;
    username: string;
    starttime: Date;
    endtime: Date;
    numturns: number;
    gateway: string;
    sessionid: string;
};

export type MessagesRow = {
    turnTimestamp: Date;
    input: string;
    messageType: string;
    responseText: string;
    responseActions: string;
    responseAudioURL: string;
    responseVideoURL: string;
    responseImageURL: string;
};

export type DashboardData = {
    key: string;
    layout: Layout;
    graphs: GraphState[];
    queries: QueryState[];
    miniregular: miniRegularGraphs;
    sessionsData: { result: SessionsRow[] };
    turnsData: { [sessionid: string]: MessagesRow[] };
};

export type Data = {
    drawerItems: DrawerItemData[];
    users: UserData[];
    bots: BotsData[];
    databases: DatabaseData[];
    server_instances: ServerInstanceData[];
    server_engines: ServerEngineData[];
    engine_statuses: {
        [key: string]: EngineStatusData;
    };
    bots_statuses: BotStatusData[];
    log: LogEntryData[];
};

export enum LogType {
    "info" = "info",
    "warning" = "warning",
    "start" = "start",
    "end" = "end",
    "error" = "error"
}

export type Actions = { [key: string]: Function };

export type DrawerState = {
    isCollapsed: boolean;
    selectedItemId: string;
    isClearingState: boolean;
    version: number;
};

export type LogState = {
    maxLength: number;
    isCollapsed: boolean;
    isMinimized: boolean;
    version: number;
};

export type UsersState = {
    updates: UserUpdateData[];
    selectedUserIds: string[];
    selectedTab: number;
    isShowingAddUser: boolean;
    version: number;
};

export type ServersState = {
    selectedInstanceId: string;
    version: number;
};

export type ReloadAreYouSureState = {
    key: string;
    botName: string;
    isSoftReload: boolean;
    isShowingAreYouSure: boolean;
};

export type BotPropertiesState = {
    contentDb: string;
    enginesPerInstance: DefaultableObjectState;
    timezone: DefaultableObjectState;
    dashPassword: DefaultableObjectState;
    customDash: DefaultableObjectState;
    intentServerURL: DefaultableObjectState;
    dashTable: DefaultableObjectState;
    userSessionTimeoutMinutes: DefaultableObjectState;
    variant: DefaultableObjectState;
    firstResponseDelayMilliseconds: DefaultableObjectState;
    longBubbleDelayMilliseconds: DefaultableObjectState;
    shortBubbleDelayMilliseconds: DefaultableObjectState;
    mediumBubbleDelayMilliseconds: DefaultableObjectState;
    translationLanguage: DefaultableObjectState;
    layout?: Layout;
    dashTimezones?: string;
    dashSubGoals: Array<{ title: string; filter: string }>;
    dashPlatforms?: string;
    dashRegions?: string;
    configJSON: DefaultableObjectState;
};

export type BotGatewaysState = {
    selectedGatewayId: string;
    gatewayChanges: { [gatewayId: string]: { [key: string]: DefaultableObjectState } };
    deletedGateways: { [gatewayId: string]: any };
};

export type BotsControlState = {
    selectedBotIds: string[];
    // cocoWhitelist: string[];
    filter: string;
    path: string[];
    selectedTab: number;
    expandedCustomers: { [key: string]: boolean };
    propertiesChanges: { [key: string]: BotPropertiesState };
    gateways: { [key: string]: BotGatewaysState };
    dashboards: { [key: string]: DashboardState };
    expandedBotId: string;
    version: number;
    isShowingReloadIFrame: boolean;
    isShowingAreYouSureReload: boolean;
    isShowingGroupChoiceDialog: boolean;
};

export type LogActions = {
    addLog: (entry: { entry: string; type?: LogType; tags?: string[]; style?: any }) => void;
};

export type UsersActions = {
    loadUsersData: () => void;
    addUser: (email: string) => void;
    saveUsers: () => void;
    updateUsers: (updates: { [key: string]: string[] }) => void;
};

export type ServerActions = {
    getData: () => void;
};

export type DashboardActions = {
    getData: () => void;
};

export type ReloadActionParams = {
    key: string;
    isSoftReload: boolean;
    botName: string;
};

export type BotsControlActions = {
    getData: () => Promise<boolean>;
    save: () => Promise<void>;
    undeploy: () => Promise<void>;
    unload: () => Promise<void>;
    moveBots: (groupTo: string) => Promise<void>;
    reload: (params: ReloadActionParams[]) => Promise<void>;
    reloadCoco: (keys: string[]) => Promise<void>;
    goBackUpPath: (steps?: number) => void;
    addToPath: (value: string) => void;
};

export type AppStates = {
    drawer?: DrawerState;
    logState?: LogState;
    users?: UsersState;
    servers?: ServersState;
    botsControl?: BotsControlState;
};

export type AppState = {
    auth: Auth;
    data: Data;
    version: string;
    state: AppStates;
    user: UserState;
    actions: {
        log: LogActions;
        users?: UsersActions;
        servers?: ServerActions;
        botsControl?: BotsControlActions;
    };
};

export type UserState = {
    bot?: string;
    key?: string;
    website?: string;
    botName?: string;
    businessName?: string;
    userType: UserType;
    setUser: any;
}

export enum UserType {
    initial = 0,
    loggedin = 1,
    authorized = 2,
    registered = 3,
    progress = 4,
    premium = 5
}
