import React from "react";
import {AppState} from "../../../../../app/types";
import {ActionsBar, ActionsBarOption} from "../../../../../app/components/ActionsBar";
import {getBotsState} from "../../../logic/getBotsState";
import {Breadcrumbs} from "../../../../../app/components/Breadcrumbs";

// const useStyles = makeStyles((theme: Theme) => {
//     return {
//         clickableText: {
//             cursor: "pointer"
//         },
//         drilldownText: {
//             paddingLeft: `${theme.spacing(1)}px`,
//             paddingRight: `${theme.spacing(1)}px`
//         },
//         breadcrumbsContainer: {
//             display: "flex"
//         }
//     };
// });

export const FaqsActionsBar = (p: { appState: AppState }) => {
    const {appState} = p;
    const state = getBotsState(appState);

    const options: ActionsBarOption[] = [];

    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: state.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];

    return <ActionsBar {...{before, options}} />;
};
