import React, {useState, useEffect, useReducer} from "react";
import {AppState, BotStatusData, MessagesRow} from "../../../../../app/types";
import {DashboardActionsBar} from "./actionsBar/DashboardActionsBar";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {BotsControlState, DashboardData, SessionsRow} from "../../../../../app/types";
import {observable, autorun} from "mobx";
import {hasPermission} from "../../../../../app/permissions";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {Divider} from "@material-ui/core";
import {ToolInnerContent} from "../../../../../app/components/ToolInnerContent";
import {
    Layout as LayoutType,
    ChartLayout,
    GraphState,
    DataUnit,
    QueryLayout,
    Aggregator,
    QueryState,
    miniRegularGraphs,
    DashboardState
} from "../types";
import layoutJson from "./../test/config.json";
import {uuid} from "../test/uuid";
import { Layout } from "./Layout";

const makeQueryData = (query: QueryLayout) => {
    return observable({
        query: query.query,
        chartIds: query.chartIds,
        isLoading: false
    });
};

const makeGraphData = (chart: ChartLayout) => {
    return observable({
        id: chart.id,
        data: [] as DataUnit[],
        dataTypes: {
            x: {id: uuid(), value: chart.axes.x, isEnabled: true, label: chart.axes.x},
            y: chart.axes.y.map(y => ({id: uuid(), value: y.value, isEnabled: true, label: y.label}))
        },
        axesLabels: chart.axesLabels,
        legend: chart.legend,
        chartType: chart.chartType,
        title: chart.title,
        aggregator: chart.aggregator || Aggregator.average,
        formatters: chart.formatters || [],
        isReloading: false,
        dataProperty: chart.dataProperty.split(","),
        valueSelector: chart.valueSelector.split(","),
        perGWDataProperty: chart.perGWDataProperty ? chart.perGWDataProperty.split(",") : undefined,
        filterIndex: chart.filterIndex,
        filters: chart.filters
    });
};

const makeQueries = (layout: LayoutType): Array<QueryState> => {
    const tQueries = new Array<QueryState>();
    if (!layout.queries) return tQueries;
    layout.queries.forEach(q => tQueries.push(makeQueryData(q)));
    return tQueries;
};

const makeGraphs: (
    minix: ChartLayout[],
    rowsMapx: Map<number, ChartLayout[]>
) => {
    mini: {layout: ChartLayout; graph: GraphState}[];
    regular: Array<{layout: ChartLayout; graph: GraphState}[]>;
} = (minix, rowsMapx) => {
    return {
        mini: minix.map(chart => ({layout: chart, graph: makeGraphData(chart)})),
        regular: Array.from(rowsMapx.keys())
            .sort()
            .map((key, i) => (rowsMapx.get(key) || []).map(chart => ({layout: chart, graph: makeGraphData(chart)})))
    };
};

const generateConfigFromBot = (config: any, b: any) => {
    let tLayout = {} as LayoutType;
    let tGraphs = [] as GraphState[];
    let minreggraphs = {} as miniRegularGraphs;
    if (b) {
        // console.log(`dashboard generate layout config: ${b.key}`);
        tLayout =
            b.configData && b.configData.layout
                ? b.configData.layout
                : (JSON.parse(JSON.stringify(layoutJson)) as LayoutType);
        if (b.configData && b.configData.dashSubGoals) {
            if (tLayout) {
                // console.log(`dashboard layout subgoals: ${b.key}`);
                const marketingGoalsChart = tLayout.charts.find(x => x.id == "Marketing Goals");
                const marketingGoalsQuery = tLayout.queries.find(x => x.query == "marketingGoalsQuery");
                if (marketingGoalsChart) {
                    marketingGoalsChart.filters = b.configData.dashSubGoals;
                    marketingGoalsChart.filterIndex = 100;
                    b.configData.dashSubGoals.forEach((value: any, index: number) => {
                        const marketingGoalsChartIndex = tLayout.charts.findIndex(x => x.id == "Marketing Goals");
                        const existingGoal = tLayout.charts.findIndex(x => x.id == "subgoal-" + index);
                        if (existingGoal == -1) {
                            const newSubGoal = JSON.parse(JSON.stringify(marketingGoalsChart)) as ChartLayout;
                            newSubGoal.filterIndex = index;
                            newSubGoal.filters = b.configData.dashSubGoals;
                            newSubGoal.title = value.title;
                            newSubGoal.id = "subgoal-" + index;
                            if (marketingGoalsQuery) marketingGoalsQuery.chartIds.push(newSubGoal.id);
                            tLayout.charts.splice(marketingGoalsChartIndex, 0, newSubGoal);
                        }
                    });
                }
            }
        }

        const mini = tLayout.charts.filter(chart => chart.isMini);
        const nonMini = tLayout.charts.filter(chart => !chart.isMini);
        const getRows = (nonMiniCharts: ChartLayout[]) =>
            nonMiniCharts.reduce((acc, cur) => {
                let existing = acc.get(cur.row);
                if (!existing) {
                    existing = new Array<ChartLayout>();
                    acc.set(cur.row, existing);
                }
                existing.push(cur);
                return acc;
            }, new Map<number, ChartLayout[]>());
        const rowsMap = getRows(nonMini);

        const rowKeys = Array.from(rowsMap.keys()).sort();
        const biggestKey = rowKeys[rowKeys.length - 1];
        for (let index = 0; index < biggestKey; index++) {
            const r = rowsMap.get(index);
            if (!r) rowsMap.set(index, new Array<ChartLayout>());
        }

        minreggraphs = makeGraphs(mini, rowsMap);
        minreggraphs.mini.forEach(data => tGraphs.push(data.graph));
        minreggraphs.regular.forEach(row => row.forEach(data => tGraphs.push(data.graph)));
        // console.log(`finished generating dashboard layout config: ${b.key}`);
    }
    const result = getConfig(b);
    result.layout = tLayout;
    result.graphs = tGraphs;
    result.queries = makeQueries(tLayout);
    result.miniregular = minreggraphs;
    // console.log(`finished generating dashboard layout config: ${result.graphs.length}`);
    return result;
};

const getConfig = (bot: BotStatusData | undefined): DashboardData => {
    return observable({
        key: bot ? bot.key : "",
        layout: {} as LayoutType,
        graphs: [] as GraphState[],
        queries: [] as QueryState[],
        miniregular: {} as miniRegularGraphs,
        sessionsData: {result: [] as SessionsRow[]},
        turnsData: {result: [] as MessagesRow[]}
    });
};

const getDashboard = (b: BotStatusData): DashboardState => {
    // console.log(`dashboard generate dashboard state: ${b.key}`);
    let tzone = "";
    if (!b.configData) {
        tzone = "UTC";
    } else {
        if (b.configData.timezone) {
            if (b.configData.timezone.value) {
                tzone = b.configData.timezone.value as string;
            } else if (typeof b.configData.timezone === "string") {
                tzone = b.configData.timezone as string;
            } else {
                tzone = "UTC";
            }
        } else {
            tzone = "UTC";
        }
    }
    return observable({
        data: {
            timeInterval: "hourly",
            timeRange: [0, 0],
            timeRangeOptionIndex: 0,
            timezone: tzone,
            defaultTimezone: tzone,
            gateway: "ALL",
            region: "ALL"
        }
    });
};

export const Dashboard = (p: {appState: AppState}) => {
    // console.log("dashboard init");
    const {appState} = p;
    const state = getBotsState(appState);
    const bots = p.appState.data.bots_statuses;
    const [bot, setbot] = useState(bots.find(b => b.key === state.expandedBotId));
    const [dashState, setdashState] = useState(state.dashboards[state.expandedBotId]);
    const [config, setConfig] = useReducer(generateConfigFromBot, getConfig(bot));

    useEffect(() => {
        return autorun(() => {
            if (!bot) return
            setConfig(bot);
            state.dashboards[state.expandedBotId] = getDashboard(bot);
            setdashState(state.dashboards[state.expandedBotId]);
        });
    }, [bots, state.expandedBotId, bot]);

    // console.log("dashboard checks", bot, "dashstate", dashState, "config", config);

    if (!bot || !dashState ) return null;
    if (!hasPermission("dashboard", appState.auth.permissions, getBotName(bot))) return null;

    // console.log(`dashboard render`);

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", flex: 1, overflow: "hidden"}}>
            <DashboardActionsBar {...{appState, dashState, config}} /> <Divider />
            <ToolInnerContent
                style={{
                    height: "calc(100% - 53px)",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid lightgrey",
                    overflowY: "scroll"
                }}>
                {config && !config.miniregular ? "" : <Layout {...{appState, dashState, config}} />}
            </ToolInnerContent>
        </div>
    );
};
