import React from "react";
import {CircularProgress} from "@material-ui/core";

export const Reloading = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
            <div>
                <CircularProgress size={12} />
            </div>
        </div>
    );
};
