import React, {useState, useEffect} from "react";
import {FocusedInputShape, DayPickerRangeController} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment, {Moment} from "moment";
import {Button, Typography} from "@material-ui/core";
import {DashboardState} from "../../types";
import {autorun} from "mobx";
import {getDateString} from "../../logic/getData";

export const AirBnBDateRangePicker = (p: {
    dashState: DashboardState;
    onChange: (dates: Date[]) => void;
    isSelected: boolean;
}) => {
    const [focusedInput, setfocusedInput] = useState<FocusedInputShape>("startDate");
    const [isOpen, setisOpen] = useState(false);
    const [isSelected, setisSelected] = useState(p.isSelected);
    useEffect(() => {
        setisSelected(p.isSelected);
    }, [p.isSelected]);

    const [dates, setDates] = useState(p.dashState.data.timeRange.map(r => (r ? moment(r) : moment())));
    useEffect(
        () =>
            autorun(() => {
                setDates(p.dashState.data.timeRange.map(r => (r ? moment(r) : moment())));
            }),
        []
    );
    const toggleIsOpen = () => setisOpen(!isOpen);
    return (
        <div>
            <div style={{display: "flex", flexDirection: "row"}}>
                <Button variant="text" color={isSelected ? "primary" : "default"} onClick={toggleIsOpen}>
                    {"custom"}
                </Button>
                {isSelected && (
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Typography variant="caption">{`${dates
                            .map(r => r.format("YYYY/MM/DD"))
                            .join("-")}`}</Typography>
                    </div>
                )}
            </div>
            {isOpen && (
                <div style={{position: "absolute", transform: "translateX(-330px)"}}>
                    <DayPickerRangeController
                        numberOfMonths={2}
                        onOutsideClick={() => setisOpen(false)}
                        startDate={dates[0]}
                        endDate={dates[1]}
                        // maxDate={moment()}
                        isOutsideRange={date => date > moment()}
                        minimumNights={0}
                        onDatesChange={({startDate, endDate}) => {
                            setDates([
                                startDate || dates[0],
                                focusedInput === "startDate" ? startDate || dates[0] : endDate || startDate || dates[0]
                            ]);
                            if (focusedInput != "startDate" && startDate && endDate) {
                                p.onChange([startDate.toDate(), endDate.toDate()]);
                                setisOpen(false);
                            }
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={fi => {
                            setfocusedInput(fi || "startDate");
                        }}
                        enableOutsideDays={true}
                        // initialVisibleMonth={() => moment()}
                        // appendToBody={true}
                        // showClearDate={true}
                        // renderCalendarInfo={() => (
                        //     <div
                        //         style={{
                        //             padding: "10px 21px",
                        //             borderTop: "1px solid #dce0e0",
                        //             color: "#484848"
                        //         }}>
                        //         {/* <Button
                        //             variant="text"
                        //             color={"default"}
                        //             onClick={() => {
                        //                 // setDates([moment(), moment()]);
                        //                 //p.onChange([moment().toDate(), moment().toDate()]);
                        //                 // setisOpen(false);
                        //             }}>
                        //             {"Today"}
                        //         </Button> */}
                        //     </div>
                        // )}
                    />
                </div>
            )}
        </div>
    );
};
