import {AppState, LogType} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {FaqType, OtherBotsType} from "../types";
import {Grow} from "@material-ui/core";

const url = "https://api.imperson.com/faq/";
// const url = "http://localhost:31755/faq/";

const tags = ["bots", "faq"];

export const makeGetFaqData = (appState: AppState) => {
    return async () => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const faqData = [] as FaqType;

        const query = "all_qa";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            const owner = getBotName(bot);
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(url + query + "?owner=" + owner, {
                method: "GET"
            });
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                let q = "";
                let qrow = {} as any;
                Object.keys(data).forEach((row, index) => {
                    if (q != "" && q != row.split("_")[0]) {
                        faqData.push(qrow);
                        qrow = {} as any;
                    }
                    q = row.split("_")[0];
                    if (row == q + "_id") {
                        qrow.qid = data[q + "_id"];
                        qrow.index = data[q + "_id"];
                    }
                    if (row == q + "_question") qrow.question = data[q + "_question"];
                    if (row == q + "_answer") qrow.answer = data[q + "_answer"];
                    if (row == q + "_category_id") qrow.category = data[q + "_category_id"];
                    if (row == q + "_category_name") qrow.categoryName = data[q + "_category_name"];
                    if (index == Object.keys(data).length - 1) {
                        faqData.push(qrow);
                    }
                });
                return Promise.resolve(faqData);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makePutFaqData = (appState: AppState) => {
    return async (owner: string, qid: string, answer: string) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;

        const query = "qa";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(url + query + "?owner=" + owner + "&id=" + qid, {
                body: JSON.stringify({answer: answer}),
                method: "POST"
            });
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                return Promise.resolve(data);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makeGetOtherBotData = (appState: AppState) => {
    return async (cat: number) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;

        const query = "all_category";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            const owner = getBotName(bot);
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(url + query + "?owner=" + owner + "&cat=" + cat, {
                method: "GET"
            });
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                return Promise.resolve(data);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makeCopyCategory = (appState: AppState) => {
    return async (cat: number, frombot: string) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;

        const query = "copy_category";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            const owner = getBotName(bot);
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(url + query + "?owner=" + owner + "&cat=" + cat + "&frombot=" + frombot, {
                method: "POST"
            });
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                return Promise.resolve(data);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
