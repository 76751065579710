import React from "react";
import {AppState} from "../../../app/types";
import {UsersList} from "./UsersList";
import {UsersDetailsContainer} from "./UsersDetailsContainer";
import {UsersActionsBar} from "./UsersActionsBar";
import {ToolContent} from "../../../app/components/ToolContent";
import {Divider} from "@material-ui/core";

export const UsersListContainer = (p: {appState: AppState} & {classes?: any}) => {
    return (
        <ToolContent>
            <UsersActionsBar {...p} />
            <Divider />
            <UsersList {...p} />
            <Divider />
            <UsersDetailsContainer {...p} />
        </ToolContent>
    );
};
