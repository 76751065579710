import React, { useState, useEffect } from "react";
import { EnginesTable } from "./EnginesTable";
import { ServerTable } from "./ServerTable";
import { AppState } from "../../../app/types";
import { TabPanel, ToolInnerContent } from "../../../app/components/ToolInnerContent";
import { ToolContent } from "../../../app/components/ToolContent";
import { Typography, Tabs, Tab} from "@material-ui/core";
import { autorun } from "mobx";

export function Servers(props: { appState: AppState }) {
    const { appState } = props;
    const before = [<Typography variant="body1" color="textPrimary" key={`servers`}>{`Servers`}</Typography>];
    const [server_instances, setServerInstances] = useState(appState.data.server_instances);
    const [server_engines, setServerEngines] = useState(appState.data.server_engines);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        return autorun(() => {
            const l = appState.data.server_instances.length;
            setServerInstances(appState.data.server_instances);
        });
    }, [appState.data.server_instances, appState.data.server_instances.length]);

    useEffect(() => {
        return autorun(() => {
            const l = appState.data.server_engines.length;
            setServerEngines(appState.data.server_engines);
        });
    }, [appState.data.server_engines, appState.data.server_engines.length]);

    return (
        <ToolContent>
            <ToolInnerContent>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Servers" aria-label="servers" />
                    <Tab label="Engines" aria-label="engines" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ServerTable rows={server_instances} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EnginesTable engines={server_engines} />
                </TabPanel>
            </ToolInnerContent>
        </ToolContent>
    );
}
