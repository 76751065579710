import React, {Component} from "react";
import {Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
export class EnginesTable extends Component {
    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Engine</TableCell>
                        <TableCell>Instance</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                {this.props.engines.length ? (
                    <TableBody>
                        {this.props.engines.map((engine, i) => {
                            return (
                                <TableRow key={`engine${i}`}>
                                    <TableCell>{engine.id}</TableCell>
                                    <TableCell>{engine.instance}</TableCell>
                                    <TableCell>{engine.name}</TableCell>
                                    <TableCell>{engine.status}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                ) : null}
            </Table>
        );
    }
}
