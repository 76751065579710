import React from "react";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {Select, Input, MenuItem} from "@material-ui/core";

export const SelectGatewaysMenu = (p: {
    appState: AppState;
    botGateways: {[gatewayId: string]: any};
    selectedGateway: string;
}) => {
    const {appState, botGateways, selectedGateway} = p;
    const state = getBotsState(appState);

    return (
        <div
            style={{
                minWidth: "160px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}>
            <Select
                displayEmpty
                value={selectedGateway}
                onChange={(event: React.ChangeEvent<any>) => {
                    const gw = event.currentTarget.dataset.value;
                    state.gateways[state.expandedBotId].selectedGatewayId = gw;
                }}
                input={<Input name="age" id="age-helper" />}>
                {[
                    <MenuItem key={`empty`} value={""} disabled>
                        <em>Select a gateway</em>
                    </MenuItem>,
                    ...Object.keys(botGateways).map((name, i) => {
                        return (
                            <MenuItem key={`name ${i}`} value={name}>
                                {name}
                            </MenuItem>
                        );
                    })
                ]}
            </Select>
        </div>
    );
};
