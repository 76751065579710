import React from "react";
import {makeStyles} from "@material-ui/styles";
import {Theme, Typography, Link, Input, Tooltip, Grid, Switch} from "@material-ui/core";

export type KvPValue = ((p: any) => JSX.Element) | JSX.Element | Element | string | boolean | number | undefined;

export enum KvpType {
    "text" = "text",
    "link" = "link",
    "custom" = "custom",
    "inputText" = "inputText",
    "inputNumber" = "inputNumber",
    "boolean" = "boolean",
    "date" = "date"
}

export type KvPEntry = {data: KvPValue; type: KvpType; tooltip?: string; props?: any; style?: any};

export type Kvp = {
    left: KvPEntry;
    right: KvPEntry;
};

const useStyles = makeStyles((theme: Theme & {transition: string}) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        halfStyle: {
            paddingLeft: "8px",
            paddingRight: "8px",
            height: "100%",
            display: "flex" as "flex",
            flexDirection: "column" as "column",
            justifyContent: "center" as "center",
            minWidth: "120px",
            minHeight: "32px"
        }
    };
});

const parseKvPEntry = (e: KvPEntry, isLeft: boolean, index: number, classes: any) => {
    let value = e.data;

    switch (e.type) {
        case KvpType.text:
            value = (
                <Typography variant="body2" style={{textAlign: isLeft ? "right" : "left", ...e.style}}>
                    {e.data}
                </Typography>
            );
            break;
        case KvpType.link:
            value = (
                <Link
                    target="_blank"
                    href={e.data as string}
                    style={{textAlign: isLeft ? "right" : "left", ...e.style}}>
                    {e.data}
                </Link>
            );
            break;
        case KvpType.inputText:
            value = (
                <Input
                    type="text"
                    value={e.data}
                    style={{textAlign: isLeft ? "right" : "left", ...e.style}}
                    onChange={c => {
                        const value = c.currentTarget.value;
                        e.props && e.props.onChange && e.props.onChange(value);
                    }}
                />
            );
            break;
        case KvpType.inputNumber:
            value = (
                <Input
                    type="number"
                    value={e.data}
                    style={{textAlign: isLeft ? "right" : "left"}}
                    onChange={c => {
                        const value = c.currentTarget.value;
                        e.props && e.props.onChange && e.props.onChange(parseInt(value));
                    }}
                />
            );
            break;
        case KvpType.date:
            value = (
                <Input
                    type="date"
                    value={e.data}
                    // style={{textAlign: isLeft ? "right" : "left"}}
                    onChange={c => {
                        const value = c.currentTarget.value;
                        e.props && e.props.onChange && e.props.onChange(value);
                    }}
                />
            );
            break;
        case KvpType.boolean:
            value = (
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Off</Grid>
                    <Grid item>
                        <Switch checked={Boolean(e.data)} onChange={() => {}} />
                    </Grid>
                    <Grid item>On</Grid>
                </Grid>
            );
            break;
        case KvpType.custom:
        default:
            break;
    }

    return (
        <Tooltip key={`${index}_${isLeft}`} title={e.tooltip || ""}>
            <div className={classes.halfStyle}>{value}</div>
        </Tooltip>
    );
};

export const KvpDisplay = (p: {data: Kvp[]}) => {
    const {data} = p;
    const classes = useStyles();

    const leftArr = [] as KvPValue[];
    const rightArr = [] as KvPValue[];

    data.forEach((kvp, i) => {
        leftArr.push(parseKvPEntry(kvp.left, true, i, classes));
        rightArr.push(parseKvPEntry(kvp.right, false, i, classes));
    });

    return (
        <div key={"kvp_root"} style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column", minHeight: "32px", paddingTop: "8px"}}>
                {leftArr}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "32px",
                    paddingTop: "8px",
                    flex: 1
                }}>
                {rightArr}
            </div>
        </div>
    );
};
