import React, {useEffect, useState} from "react";
import {GraphState, ChartType} from "../types";
import {makeStyles} from "@material-ui/styles";
import {Theme, Paper} from "@material-ui/core";
import {autorun} from "mobx";
import {BarChart} from "./charts/BarChart";
import {PieChart} from "./charts/PieChart";
import {LineChart} from "./charts/LineChart";
import {AreaChart} from "./charts/AreaChart";
import {CounterChart} from "./charts/CounterChart";
import {TableChart} from "./charts/TableChart";
import {AppState} from "../../../../../app/types";
import {Reloading} from "./Reloading";
import {ChartWrapper} from "./charts/ChartWrapper";

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            // display: "flex" as "flex",
            // flexDirection: "column" as "column",
            width: "100%",
            // height: "100%",
            border: "1px solid lightgray",
            overflow: "hidden"
        }
    };
});

const getChart = (graph: GraphState, chartType: ChartType) => {
    let a = null;
    switch (chartType) {
        case ChartType.bar:
            a = <ChartWrapper {...{graph, chart: BarChart}} />;
            break;
        case ChartType.line:
            a = <ChartWrapper {...{graph, chart: LineChart}} />;
            break;
        case ChartType.pie:
            a = <ChartWrapper {...{graph, chart: PieChart}} />;
            break;
        case ChartType.area:
            a = <ChartWrapper {...{graph, chart: AreaChart}} />;
            break;
        case ChartType.table:
            a = <ChartWrapper {...{graph, chart: TableChart}} />;
            break;
            case ChartType.simpletable:
                a = <ChartWrapper {...{graph, chart: TableChart}} />;
                break;
            case ChartType.counter:
            a = <CounterChart {...{graph}} />;
            break;
        default:
            break;
    }
    return a;
};

export const Chart = (p: {appState: AppState; graph: GraphState; style?: {}}) => {
    const classes = useStyles();

    const [chartType, setChartType] = useState(p.graph.chartType);
    useEffect(() => {
        return autorun(() => {
            setChartType(p.graph.chartType);
        });
    }, []);

    return (
        <Paper className={classes.container} style={p.style} elevation={0}>
            {getChart(p.graph, chartType)}
        </Paper>
    );
};
