import {AppState, LogType} from "../types";
import {getDrawerState} from "../getDrawerState";
import {getBotsState} from "../../content/botsControl/logic/getBotsState";
const tags = ["TOOLBOX"];

export const saveState = async (appState: AppState) => {
    try {
        let path = [] as string[];
        const d = getDrawerState(appState);
        if (!d) return;
        path.push(d.selectedItemId);
        if (d.selectedItemId === "bots") {
             const botsState = getBotsState(appState);
             path = [...botsState.path];
             // path=["bots"];
        }
        const newState = path.join("/");
        // console.log("new path",newState);
        window.history.pushState({}, path.join("/"), "/" + newState);
        window.document.title = path.join(" | ")+" | Toolbox";
        // console.log(`history pushed with state /${newState}`,path, path.join("|")+"|Toolbox");
        // appState.actions.log.addLog({entry: "saving  state", tags, type: LogType.start});
        const body = JSON.stringify(
            Object.keys(appState.state).reduce((acc, cur) => {
                (acc as any)[cur] = (appState.state as any)[cur];
                return acc;
            }, {})
        );
        // console.log(`history pushed with state ${newState} ${body}`);
        const headers = new Headers();
        headers.append(`Content-Type`, `application/json;charset=UTF-8`);
        const reply = await appState.auth.fetchWithAuth(`/save?userId=${appState.auth.userId}`, {
            method: "POST",
            body,
            headers
        });
        const json = await reply.json();
        if (json.error) {
            throw new Error(json.error);
        }
        // appState.actions.log.addLog({entry: "state saved", tags, type: LogType.end});
        return json;
    } catch (e) {
        appState.actions.log.addLog({entry: "state save failed", tags, type: LogType.error});
        return {error: e};
    }
};
