import React from "react";
import {AppState} from "../../../app/types";
import {BotsControlTableContainer} from "./BotsControlTableContainer";
import {ToolContent} from "../../../app/components/ToolContent";
import {ReloadDialog} from "./reload/ReloadDialog";
import {AreYouSureReloadDialog} from "./reload/AreYouSureReloadDialog";
import {ChooseGroupDialog} from "./actionsBar/ChooseGroupDialog";

export const BotsContent = (p: {appState: AppState}) => {
    const {appState} = p;
    return (
        <ToolContent>
            <BotsControlTableContainer {...{appState}} />
            <ReloadDialog {...{appState}} />
            <AreYouSureReloadDialog {...{appState}} />
            <ChooseGroupDialog {...{appState}} />
        </ToolContent>
    );
};
