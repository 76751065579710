import React, {useState, useEffect} from "react";
import {autorun} from "mobx";
import {AppState, BotStatusData} from "../../../../../../app/types";
import {DashboardState} from "../../types";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {getBotsState} from "../../../../../botsControl/logic/getBotsState";

export const TimezoneMenu = (p: {appState: AppState; dashState: DashboardState}) => {
    const {appState} = p;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [timezone, setSelected] = useState(p.dashState.data.timezone);

    let timezones = [] as string[];
    const botsState = getBotsState(appState);
    const bot = appState.data.bots_statuses.find(bot => bot.key === botsState.expandedBotId);

    useEffect(() => {
        p.dashState.data.timezone = timezone;
    }, [timezone]);

    if (!bot) return null;
    const configData = (bot.configData || {}) as any;
    const tzones = (configData.dashTimezones || "") as string;
    timezones = tzones == "" ? [] : tzones.split(",");
    timezones.push(p.dashState.data.defaultTimezone);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Button onClick={handleClick}>{timezone ? timezone : ""}</Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {timezones.map((name, i) => {
                    return (
                        <MenuItem
                            key={`name ${i}`}
                            value={name}
                            onClick={() => {
                                setSelected(name);
                                handleClose();
                            }}>
                            {name}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
