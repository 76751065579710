import React, { useState, useEffect, Fragment } from "react";
import { AppState, UserType } from "../../app/types";
import { Theme, Typography, makeStyles, FormControl, FormHelperText, Input, InputLabel, Button, CircularProgress, Tab, Tabs, Card, CardActions, CardContent, Grid, Paper } from "@material-ui/core";
import { getDrawerState } from "../../app/getDrawerState";
import { autorun } from "mobx";
import { ToolContent } from "../../app/components/ToolContent";
import { TabPanel, ToolInnerContent } from "../../app/components/ToolInnerContent";
import { getUser } from "./logic/getUser";
import { getBotsState } from "../botsControl/logic/getBotsState";

const geoserver = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyA34CIzvJZeanbE9p1ExzChGmbPk-lXm2k";

const useStyles = makeStyles((theme: Theme) => {
    const padded = { padding: theme.spacing(0) };
    return {
        content: {
            display: "flex",
            flexDirection: "column" as "column",
            flexGrow: 1,
            ...padded
        },
        title: { ...padded, textAlign: "center" as "center" },
        button: { ...padded, maxWidth: "200px", fontSize: "14px" },
        input: { }
    };
});

const StyledHomeContent = (p: { appState: AppState }) => {
    const { appState } = p;
    const classes = useStyles();
    const state = getBotsState(appState);
    const drawer = getDrawerState(appState);
    const [isClearingState, setisClearingState] = useState(drawer.isClearingState);
    useEffect(() => {
        return autorun(() => {
            setisClearingState(drawer.isClearingState);
        });
    }, [drawer.isClearingState]);

    const [user, setUser] = useState(appState.user.userType);
    const [bWebsite, setbWebsite] = useState(null as string | null);
    const [bName, setbName] = useState(null as string | null);
    const [bCat, setbCat] = useState(null as string | null);
    const [bDesc, setbDesc] = useState(null as string | null);
    const [bAddr, setbAddr] = useState(null as string | null);
    const [bEmail, setbEmail] = useState(null as string | null);
    const [bNickname, setbNickname] = useState(null as string | null);
    const [eWebsite, seteWebsite] = useState(false);
    const [eName, seteName] = useState(false);
    const [eCat, seteCat] = useState(false);
    const [eDesc, seteDesc] = useState(false);
    const [eAddr, seteAddr] = useState(false);
    const [eEmail, seteEmail] = useState(false);
    const [eNickname, seteNickname] = useState(false);
    const [entryError, setEntryerror] = useState(false);
    const [value, setValue] = useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const getLocation = (url: string) => {
        var temp = document.createElement("a")
        temp.href = url;
        var result = temp.host
        return result
    }
    const validWebsite = (website: string) => {
        return fetch("/testwebsite", {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "url": website })
        })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                } else {
                    return response.text()
                }
            })
            .then(res => {
                if (!res) return false;
                return true;
            })
            .catch(error => {
                return false;
            });
    }


    const validAddress = (address: string) => {
        return fetch(geoserver + "&address=" + address + "&components=country:US")
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                } else {
                    return response.json()
                }
            })
            .then(res => {
                // console.log(res);
                if (!res) return false;
                if (res.status != "OK") return false;
                if (!res.results || (res.results && !res.results[0])) return false;
                var countryindex = res.results[0].address_components.findIndex((element: any) => element.types.findIndex((type: string) => type === "country") != -1);
                if (countryindex == -1) return false;
                if (res.results[0].address_components[countryindex].short_name !== "US") return false;
                return true;
            })
            .catch(error => {
                // console.log("error", error);
                return false;
            });
    }

    const validInput = async () => {
        let ret = true;
        const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const singleWord = /^\w+$/
        const aFewWords = /^ *\w+(?: +\w+){2,}$/
        if (!bName || (bName && bName.length == 0)) { seteName(true); ret = false; } else { seteName(false); }
        if (!bCat || (bCat && bCat.length == 0)) { seteCat(true); ret = false; } else { seteCat(false); }
        if (!bDesc || (bDesc && bDesc.length == 0) || (bDesc && !bDesc.match(aFewWords))) { seteDesc(true); ret = false; } else { seteDesc(false); }
        if (!bEmail || (bEmail && bEmail.length == 0) || (bEmail && !bEmail.match(mailformat))) { seteEmail(true); ret = false; } else { seteEmail(false); }
        if (!bNickname || (bNickname && bNickname.length == 0) || (bNickname && !bNickname.match(singleWord))) { seteNickname(true); ret = false; } else { seteNickname(false); }
        if (!bAddr || (bAddr && bAddr.length == 0)) { seteAddr(true); ret = false; } else { seteAddr(false); }
        if (!bWebsite || (bWebsite && bWebsite.length == 0)) { seteWebsite(true); ret = false; } else { seteWebsite(false); }
        if (bAddr && bAddr.length > 0) {
            await validAddress(bAddr)
                .then(res => {
                    // console.log("address result", res)
                    if (!res) {
                        { seteAddr(true); ret = false; }
                    }
                    else {
                        seteAddr(false);
                    }
                })
        }
        if (bWebsite && bWebsite.length > 0) {
            await validWebsite(bWebsite)
                .then(res => {
                    // console.log("website result", res)
                    if (!res) {
                        { seteWebsite(true); ret = false; }
                    }
                    else {
                        seteWebsite(false);
                    }
                })
        }
        // console.log("entry result", ret)
        setEntryerror(!ret);
        return ret;
    }

    const createBot = async () => {
        var res = await validInput();
        if (!res) { console.log("not valid input"); return; };
        setUser(UserType.progress);
        getUser(appState, "register", { business: { website: bWebsite, name: bName, category: bCat, description: bDesc, address: bAddr, email: bEmail, nickname: bNickname } })
            .then((res) => {
                // console.log("create bot success");
                window.location.reload();
            })
            .catch((error) => {
                // console.log("create bot error", error);
                setUser(UserType.loggedin);
            })
    }

    return (user === UserType.progress || user === UserType.initial ?
        <ToolContent>
            <div className="{classes.title}">
                <CircularProgress />
                <Typography variant="h6">
                    Please Wait...
                </Typography>
            </div>
        </ToolContent>
        : user === UserType.loggedin ?
            <ToolContent>
                <ToolInnerContent>
                    <Card variant="outlined" style={{ margin: "5px", height: "calc(100% - 10px)", overflowY:"auto" }}>
                        <CardContent>
                            <Typography className="heading">
                                Welcome to imperson toolbox!
                </Typography>
                            <Typography className="paragraph">
                                To create your free chatbot, please fill-in the following details and press "Create My Chatbot".
                </Typography>
                            <Typography className="paragraph">
                                This information will used to create the chatbot FAQ knowledgbase. You will be able to update this later.
                </Typography>
                            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} >
                                <FormControl className={classes.input} error={eWebsite}>
                                    <InputLabel htmlFor="business-website-input">Business Website</InputLabel>
                                    <Input id="businesswebsite" aria-describedby="business-website-text" onChange={(event) => { setbWebsite(event.target.value) }} />
                                    <FormHelperText id="business-website-text">Enter the full business website address (i.e. https://yourwebsite.com) where you want to embed the chatbot.</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eName}>
                                    <InputLabel htmlFor="business-name-input">Business Name</InputLabel>
                                    <Input id="businessname" aria-describedby="business-name-text" onChange={(event) => { setbName(event.target.value) }} />
                                    <FormHelperText id="business-name-text">Enter the response to the question "what is the name of your business?"</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eCat}>
                                    <InputLabel htmlFor="business-category-input">Business Category</InputLabel>
                                    <Input id="businesscategory" aria-describedby="business-category-text" onChange={(event) => { setbCat(event.target.value) }} />
                                    <FormHelperText id="business-category-text">Enter the category of your business (services, travel, consulting, etc.).</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eDesc}>
                                    <InputLabel htmlFor="business-description-input">Business Description</InputLabel>
                                    <Input id="businessdescription" aria-describedby="business-description-text" onChange={(event) => { setbDesc(event.target.value) }} />
                                    <FormHelperText id="business-description-text">Enter a short response (a few words) to the question "What do you do?".</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eAddr}>
                                    <InputLabel htmlFor="business-address-input">Business Address</InputLabel>
                                    <Input id="businessaddress" aria-describedby="business-address-text" onChange={(event) => { setbAddr(event.target.value) }} />
                                    <FormHelperText id="business-address-text">Enter your business address. Only valid US-Based addresses are accepted.</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eEmail}>
                                    <InputLabel htmlFor="business-email-input">Contact Email</InputLabel>
                                    <Input id="businessemail" aria-describedby="business-email-text" onChange={(event) => { setbEmail(event.target.value) }} />
                                    <FormHelperText id="business-email-text">Enter the email you want customers to contact you with.</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.input} error={eNickname}>
                                    <InputLabel htmlFor="business-nickname-input">Chatbot Nickname</InputLabel>
                                    <Input id="businessnickname" aria-describedby="business-nickname-text" onChange={(event) => { setbNickname(event.target.value) }} />
                                    <FormHelperText id="business-nickname-text">Enter a one-word nickname you want to give the chatbot when presenting itself to customers. Please use only alphanumeric characters.</FormHelperText>
                                </FormControl>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Button color="primary" onClick={createBot}>Create My Chatbot</Button>
                            {entryError ? <FormHelperText id="entry-error" error>Please correct the input on the fields above colored red and try again.</FormHelperText> : null}
                        </CardActions>
                    </Card>
                </ToolInnerContent>
                {/* <ToolInnerContent style={{flex: 1, padding: "8px"}}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isClearingState}
                            onChange={() => (state.isClearingState = !state.isClearingState)}
                            value={isClearingState}
                            color="primary"
                        />
                    }
                    label="Do not save state"
                />
            </ToolInnerContent> */}
            </ToolContent>
            : user === UserType.registered || user === UserType.premium ?
                <ToolContent>
                    <ToolInnerContent>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="Home" aria-label="home" />
                            <Tab label="Test Your ChatBot" aria-label="test your chatbot" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card variant="outlined" style={{ margin: "5px" }}>
                                        <CardContent>
                                            <div className="heading">
                                                Update your responses (FAQs)
                                        </div>
                                            <div className="paragraph">
                                                Update the responses the chatbot provides. You can change the responses you provided when registering, or update other responses.
                                        </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button color="primary"
                                                onClick={() => {
                                                    state.path = ["bots"];
                                                    drawer.selectedItemId = "bots";
                                                    if (appState.user.bot) {
                                                        appState.actions.botsControl && appState.actions.botsControl.addToPath(appState.user.bot);
                                                        appState.actions.botsControl && appState.actions.botsControl.addToPath("faq");
                                                    }
                                                }}>Update Responses (FAQs)</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Card variant="outlined" style={{ margin: "5px" }}>
                                        <CardContent>
                                            <div className="heading">
                                                View chatbot conversations
                                            </div>
                                            <div className="paragraph">
                                                View detailed logs of conversations your users are having with your chatbot on your website.
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <Button color="primary"
                                                onClick={() => {
                                                    state.path = ["bots"];
                                                    drawer.selectedItemId = "bots";
                                                    if (appState.user.bot) {
                                                        appState.actions.botsControl && appState.actions.botsControl.addToPath(appState.user.bot);
                                                        appState.actions.botsControl && appState.actions.botsControl.addToPath("conversations");
                                                    }
                                                }}>View Conversations</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card variant="outlined" style={{ margin: "5px" }}>
                                    <CardContent>
                                        <div className="heading">
                                            Deploy the chatbot to your website
                        </div>
                                        <div className="paragraph">
                                            To deploy your chatbot to your website, embed the following tag into your website HTML, just before the closing BODY tag.
                        </div>
                                        <div style={{ margin: "12px", padding: "10px", backgroundColor: "#fafafa" }}>
                                            <code>
                                                &#x3C;script id=&#x22;impersonloader&#x22; src=&#x22;https://chat.imperson.com/loader.js&#x22; data-css=&#x22;https://chat.imperson.com/loader.css&#x22; data-title=&#x22;{appState.user.botName ? "Chat with " + appState.user.botName : "Chat"}&#x22; data-message=&#x22;{appState.user.businessName ? "Click to chat about " + appState.user.businessName : "Click to Chat"}&#x22; data-key=&#x22;{appState.user.key}&#x22;&#x3E;&#x3C;/script&#x3E;
                            </code>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </TabPanel>

                        <TabPanel value={value} index={1} expandClient>
                            <Paper variant="outlined" style={{ margin: "5px", height: "calc(100% - 10px)" }}>
                                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                    <div className="paragraph" style={{ padding: "10px" }}>
                                        Chat with your chatbot here to test your responses.
                            </div>
                                    <iframe style={{ flexGrow: 1, width: "100%" }} frameBorder={0} src={"https://chat.imperson.com?key=" + appState.user.key + (appState.user.website ? "&host=" + getLocation(appState.user.website) : "")} />
                                </div>
                            </Paper>
                        </TabPanel>
                        {/* {appState.user.userType != UserType.premium
                            ? <Fragment><Typography variant="h6">
                                buy premium
                        </Typography>
                                <Typography variant="body1">
                                    sub title buy premium
                        </Typography>
                                <Button color="primary" variant="outlined"
                                    onClick={() => { setPaymentDialog(true); }}>Subscribe to Toolbox Premium</Button>
                                {paymentDialog ? <Payment {...{ appState: appState, email: appState.auth.userEmail, user: appState.user, setPaymentDialog: setPaymentDialog }} /> : null}
                            </Fragment>
                            : null} */}
                    </ToolInnerContent>
                </ToolContent>
                : null
    );
};
export function HomeContent(p: { appState: AppState }) {
    return <StyledHomeContent {...{ appState: p.appState }} />;
}
