import {observable, action} from "mobx";
import {AppState} from "../types";

const version = 1;

const handleOldVersion = action((appState: AppState) => {
    if (!appState.state.logState) return;
    const entry = `State version ${appState.state.logState.version} is != ${version}, using default state`;
    appState.actions.log &&
        appState.actions.log.addLog({
            tags: ["LOG"],
            entry
        });
    appState.state.logState = undefined;
});

export const getLogState = (appState: AppState) => {
    appState.state.logState &&
        (isNaN(appState.state.logState.version) || appState.state.logState.version !== version) &&
        handleOldVersion(appState);

    const state =
        appState.state.logState ||
        observable({
            maxLength: 1000,
            isCollapsed: true,
            isMinimized: false,
            version
        });
    action(() => !appState.state.logState && (appState.state.logState = state))();
    return state;
};
