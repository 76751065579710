import React, {useState, useEffect} from "react";
import {Dialog, DialogContent, DialogActions, Button, Slide, DialogTitle} from "@material-ui/core";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {autorun} from "mobx";
import {ControlGroupMenu} from "./ControlGroupMenu";

// function Transition(props: any) {
//     return <Slide direction="up" {...props} />;
// }

export const ChooseGroupDialog = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [selectedGroup, setselectedGroup] = useState("");

    const [isShowingGroupChoiceDialog, setisShowingGroupChoiceDialog] = useState(state.isShowingGroupChoiceDialog);
    useEffect(() => {
        return autorun(() => setisShowingGroupChoiceDialog(state.isShowingGroupChoiceDialog));
    }, [state.isShowingGroupChoiceDialog]);
    const handleClose = () => {
        setselectedGroup("");
        state.isShowingGroupChoiceDialog = false;
    };
    const handleConfirm = () => {
        appState.actions.botsControl && appState.actions.botsControl.moveBots(selectedGroup);
        handleClose();
    };

    return (
        <Dialog open={isShowingGroupChoiceDialog} keepMounted onClose={handleClose}>
            <DialogTitle>{`Please choose a group to move selected bots to`}</DialogTitle>
            <DialogContent>
                <ControlGroupMenu {...{appState, setselectedGroup, group: selectedGroup}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={!Boolean(selectedGroup)} onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
