import React, {useEffect} from "react";
import {AppState} from "../../app/types";
import {UsersListContainer} from "./components/UsersListContainer";

export const UsersContent = (p: {appState: AppState}) => {
    const {appState} = p;
    useEffect(() => {
        !appState.data.users.length && appState.actions.users && appState.actions.users.loadUsersData();
    }, [appState.data.users.length, appState.actions.users]);
    return <UsersListContainer {...{appState: p.appState}} />;
};
