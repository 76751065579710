import {AppState, LogType, DashboardData} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {GraphState, ChartType, EntryType, Aggregator, QueryState, Data, DataRow, gwResult} from "../types";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {getPeriod} from "./getData";
import {uuid} from "../test/uuid";
import moment from "moment";
import {toJS} from "mobx";
import { resultsAriaMessage } from "react-select/src/accessibility";
import { DayPickerRangeController } from "react-dates";

const url = "https://api.imperson.com/queries/";

// const url = "http://localhost:31755/queries/";

const processReplyAsCounter = (graph: GraphState, data: Data) => {
    const dataProperty = graph.gateway ? graph.perGWDataProperty : graph.dataProperty;
    const valueSelector = graph.valueSelector;
    if (!dataProperty) return;
    graph.data = [];
    if (graph.gateway && graph.gateway != "ALL") {
        dataProperty.forEach((pvalue: string, pi: number) => {
            data.totals[pvalue]
                .filter((x: any) => x.gateway == graph.gateway)
                .forEach((xvalue: any) => {
                    let tValue =
                        graph.dataTypes.y[0].value == "sessionDuration"
                            ? xvalue.result[valueSelector[pi]] / 1000 / 60
                            : xvalue.result[valueSelector[pi]];
                    graph.data.push({x: pi, y: [tValue]});
                });
        });
    } else {
        dataProperty.forEach((pvalue: string, pi: number) => {
            let tValue =
                graph.dataTypes.y[pi].value == "sessionDuration"
                    ? data.totals[pvalue][valueSelector[pi]] / 1000 / 60
                    : data.totals[pvalue][valueSelector[pi]];
            graph.data.push({x: pi, y: [tValue]});
        });
    }
};

const processReplyAsTimeseries = (graph: GraphState, data: Data) => {
    const dataProperty = graph.gateway ? graph.perGWDataProperty : graph.dataProperty;
    const valueSelector = graph.valueSelector;
    const result = data.result;
    if (!result.length) return;
    if (!dataProperty) return;
    graph.data = [];
    const first = data.result[0];
    data.result.forEach((row: DataRow, rowi: any) => {
        let yvalues: number[] = [];
        if (graph.gateway && graph.gateway != "ALL") {
            dataProperty.forEach((pvalue: any, pi) => {
                if (rowi == 0) {
                    const yData = graph.dataTypes.y;
                    first[pvalue]
                        .filter((x: any) => x.gateway == graph.gateway)
                        .forEach((value: any, i: any) => {
                            if (yData.length <= i)
                                yData.push({isEnabled: true, id: uuid(), value: value.EntryType, label: value.label});
                            // yData[i].value = (value.gateway as any) as EntryType;
                        });
                }
                row[pvalue]
                    .filter((x: any) => x.gateway == graph.gateway)
                    .forEach((xvalue: any) => {
                        let tValue =
                            graph.dataTypes.y[0].value == "sessionDuration"
                                ? xvalue.result[valueSelector[pi]] / 1000 / 60
                                : xvalue.result[valueSelector[pi]];
                        yvalues.push(tValue);
                    });
            });
            graph.data.push({x: new Date(row.timeframe.start), y: yvalues});
            // console.log({graph: graph.id, x: row.timeframe.start, y: yvalues, titles: toJS(graph.dataTypes)});
        } else {
            dataProperty.forEach((pvalue: any, pi) => {
                if (first[pvalue] instanceof Array) {
                    if (rowi == 0) {
                        const yData = graph.dataTypes.y;
                        first[pvalue].forEach((value: any, i: any) => {
                            if (yData.length <= i)
                                yData.push({isEnabled: true, id: uuid(), value: value.EntryType, label: value.label});
                            yData[i].value = (value.gateway as any) as EntryType;
                        });
                    }
                    row[pvalue].forEach((xvalue: any) => {
                        let tValue =
                            graph.dataTypes.y[0].value == "sessionDuration"
                                ? xvalue.result[valueSelector[pi]] / 1000 / 60
                                : xvalue.result[valueSelector[pi]];
                        yvalues.push(tValue);
                    });
                } else {
                    let tValue =
                        graph.dataTypes.y[0].value == "sessionDuration"
                            ? row[pvalue][valueSelector[pi]] / 1000 / 60
                            : row[pvalue][valueSelector[pi]];
                    yvalues.push(tValue);
                }
            });
            graph.data.push({x: new Date(row.timeframe.start), y: yvalues});
        }
    });
    // console.log({data: toJS(graph.data), titles: toJS(graph.dataTypes)});
};

const processReplyAsPie = (graph: GraphState, data: Data) => {
    {
        const dataProperty = graph.dataProperty[0];
        const valueSelector = graph.valueSelector[0];
        const result: gwResult[] = data.totals[dataProperty];
        if (!result.length) return;
        graph.data = [];
        graph.data = [
            {x: graph.dataTypes.x.value as any, y: graph.dataTypes.y[0].label as any},
            ...result.map(z => ({x: z.gateway, y: (z as any).result[valueSelector]}))
        ];

        // result.forEach((gw: any) => {
        //     graph.data.push({x: gw.gateway, y: gw.result[valueSelector]});
        // });

        // const titles = Object.keys(result[0]);
        // const newData = [{x: "", y: titles}, ...result.map((r, i) => ({x: "", y: titles.map(t => (r as any)[t])}))];
        // graph.data = newData;
    }
};

const processReplyAsTable = (graph: GraphState, data: Data) => {
    {
        const filteredIndex = graph.filterIndex == undefined ? "100" : graph.filterIndex.toString();
        const result = data.result;
        var filteredResult: {[key: string]: DataRow[]} = {"100": []};
        if (!result.length) return;
        result.forEach((res, i) => {
            var found = false;
            if (graph.filters) {
                graph.filters.forEach((value, index) => {
                    if (!filteredResult[index.toString()]) filteredResult[index.toString()] = [];
                    if (res.goal.match(value.filter) != null) {
                        filteredResult[index.toString()].push(res);
                        found = true;
                    }
                });
            }
            if (!found && filteredIndex == "100") filteredResult[filteredIndex].push(res);
        });
        const first = filteredResult[filteredIndex][0] || {};
        const headers = Object.keys(first);
        const d = [
            {x: "", y: headers},
            ...filteredResult[filteredIndex].map(r => ({x: "", y: headers.map(h => r[h])}))
        ];
        graph.data = d;
    }
};

const processReplyAsSimpleTable = (graph: GraphState, data: Data) => {
    {
        
        // const filteredIndex = graph.filterIndex == undefined ? "100" : graph.filterIndex.toString();
        // const result = data.result;
        // var filteredResult: {[key: string]: DataRow[]} = {"100": []};
        // if (!result.length) return;
        // result.forEach((res, i) => {
        //     var found = false;
        //     if (graph.filters) {
        //         graph.filters.forEach((value, index) => {
        //             if (!filteredResult[index.toString()]) filteredResult[index.toString()] = [];
        //             if (res.goal.match(value.filter) != null) {
        //                 filteredResult[index.toString()].push(res);
        //                 found = true;
        //             }
        //         });
        //     }
        //     if (!found && filteredIndex == "100") filteredResult[filteredIndex].push(res);
        // });
        // const first = filteredResult[filteredIndex][0];
        // const headers = Object.keys(first);
        // const d = [
        //     {x: "", y: headers},
        //     ...filteredResult[filteredIndex].map(r => ({x: "", y: headers.map(h => r[h])}))
        // ];
        if (data.links == undefined) return;
        if (!data.links.length) return;
        const d = [ { x:"",y:["url","platform","count"]},
                      ...data.links.flatMap(row => row.clicks.filter(gtw=> !graph.gateway?true:graph.gateway=="ALL"?true:gtw.gateway == graph.gateway).map(gtw => ({ x:"", y:[row.url,gtw.gateway,gtw.result.all] }) ))
        ];
        graph.data = d;
        // console.log(d);
    }
};

const processReplyData = (graphs: Array<GraphState>, chartIds: string[], data: Data) => {
    chartIds.forEach(id => {
        const graph = graphs.find(x => x.id == id);
        if (!graph) return;
        switch (graph.chartType) {
            case ChartType.counter:
                processReplyAsCounter(graph, data);
                break;
            case ChartType.bar:
            case ChartType.area:
            case ChartType.line:
                processReplyAsTimeseries(graph, data);
                break;
            case ChartType.pie:
                processReplyAsPie(graph, data);
                break;
            case ChartType.table:
                processReplyAsTable(graph, data);
                break;
            case ChartType.simpletable:
                processReplyAsSimpleTable(graph, data);
                break;
            default:
                break;
        }
        graph.isReloading = false;
    });
};

const tags = ["bots", "DASHBOARD"];

export const makeGetQueryData = (appState: AppState, config: DashboardData) => {
    return async (querystate: QueryState) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const dash = state.dashboards[state.expandedBotId];
        const layout = config.layout;
        const graphs = config.graphs;
        if (!dash || !layout) return;
        const query = querystate.query;
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});

            const period = getPeriod(dash);

            const body = {
                query,
                dates: Boolean(period)
                    ? {start: "", end: ""}
                    : {
                          start: moment(dash.data.timeRange[0]).format("YYYY-MM-DD"),
                          end: moment(dash.data.timeRange[1]).format("YYYY-MM-DD")
                      },
                period,
                interval: dash.data.timeInterval,
                minturns: 2,
                personality: getBotName(bot),
                relative: Boolean(period),
                timezone: dash.data.timezone,
                gateway: dash.data.gateway && dash.data.gateway !== "ALL" ? dash.data.gateway : undefined,
                bookmark: dash.data.region && dash.data.region !== "ALL" ? "region:" + dash.data.region : undefined
            };
            querystate.isLoading = true;
            const reply = await appState.auth.fetchWithAuth(url, {
                method: "POST",
                body: JSON.stringify(body)
            });

            if (reply.ok) {
                const data = await reply.json();
                querystate.isLoading = false;
                const result = Promise.resolve(processReplyData(graphs, querystate.chartIds, JSON.parse(data)));
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                return result;
            } else {
                querystate.isLoading = false;
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            querystate.isLoading = false;
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makeGetSessionData = (appState: AppState, config: DashboardData) => {
    return async () => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const dash = state.dashboards[state.expandedBotId];

        if (!dash) return;
        const query = "sessionsLogQuery";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            addLog({
                tags,
                entry: `Query: ${query} start`,
                type: LogType.start
            });

            const period = getPeriod(dash);

            const body = {
                query,
                dates: Boolean(period)
                    ? {start: "", end: ""}
                    : {
                          start: moment(dash.data.timeRange[0]).format("YYYY-MM-DD"),
                          end: moment(dash.data.timeRange[1]).format("YYYY-MM-DD")
                      },
                period,
                interval: dash.data.timeInterval,
                minturns: 2,
                personality: getBotName(bot),
                relative: Boolean(period),
                timezone: dash.data.timezone,
                bookmark: dash.data.region && dash.data.region !== "ALL" ? "region:" + dash.data.region : undefined
            };
            const reply = await appState.auth.fetchWithAuth(url, {
                method: "POST",
                body: JSON.stringify(body)
            });

            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                const ses = JSON.parse(data);
                config.sessionsData = ses;
                return Promise.resolve(ses);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makeGetTurnsData = (appState: AppState, config: DashboardData) => {
    return async (sessionid: string, gateway: string) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;

        const query = "sessionTurnsQuery";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});

            const body = {
                query,
                personality: getBotName(bot),
                sessionid,
                gateway,
                key: bot.key,
                secret: bot.secret
            };
            const reply = await appState.auth.fetchWithAuth(url, {
                method: "POST",
                body: JSON.stringify(body)
            });

            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                // const res = JSON.parse(data);
                config.turnsData = {...config.turnsData, [sessionid]: data.result};
                return Promise.resolve(data);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
