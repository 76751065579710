import auth0, {WebAuth} from "auth0-js";
import {Auth} from "./types";

let gAccessToken = "";
let gUserName = "";
let gUserId = ";";
let gUserEmail = "";
let gPermissions = new Set<string>();
let webAuth: WebAuth;
let tokenExpiresAt: number;
let isFailed = false;

const CALLBACK_URL = "auth-callback";
const CLIENT_ID = "xgzeb3pAEdiBHOqvHMONxSUvLRsgOcFl";
const USER_PROFILE_ENDPOINT = "https://auth0-proxy-dot-toolbox-235607.appspot.com/userinfo";

function saveState(state: any) {
    let key = Math.random()
        .toString(36)
        .replace("0.", "");
    localStorage.setItem(key, JSON.stringify(state));
    return key;
}

function loadState(key: string) {
    let stateStr = localStorage.getItem(key);
    if (stateStr) {
        localStorage.removeItem(key);
        return JSON.parse(stateStr);
    }
    return null;
}

function logout() {
    gAccessToken = "";
    gPermissions.clear();
    gUserName = "";
    gUserEmail = "";
    webAuth.logout({
        clientID: CLIENT_ID
    });
}

async function renewToken() {
    webAuth.checkSession({}, (err, authResult) => {
        if (err) {
            // console.error("Token renewal error:" + err.error + "-" + err.errorDescription);
            return false;
        } else {
            gAccessToken = authResult.accessToken;
            tokenExpiresAt = Date.now() + parseInt(authResult.expiresIn) * 1000;
            // console.log("Token refreshed");
            return true;
        }
    });
    // console.log(`renew token got to unexpected end`);
    return false;
}

function doLogin(wAuth: WebAuth, onSuccess: () => void) {
    wAuth.parseHash(async (err, authResult) => {
        if (err) {
            wAuth.authorize({state: saveState({href: window.location.href})});
            // console.log("wAuth.parseHash error");
            return;
        }
        if (!authResult || !authResult.accessToken || !authResult.state) {
            wAuth.authorize({state: saveState({href: window.location.href})});
            // console.log("wAuth.parseHash missing token or state");
            return;
        }
        gAccessToken = authResult.accessToken;
        tokenExpiresAt = authResult.expiresIn ? Date.now() + authResult.expiresIn * 1000 : Date.now();
        const state = loadState(authResult.state);
        if (!state) {
            console.error("Error restoring state");
            throw Error("Error restoring state");
        }
        try {
            const result = await fetch(USER_PROFILE_ENDPOINT, {headers: {Authorization: "Bearer " + gAccessToken}});
            if (result.ok) {
                const user = await result.json();
                gUserName = user["https://imperson.com/name"];
                gUserId = user["https://imperson.com/user_id"];
                gUserEmail = user["https://imperson.com/email"];
                const metadata = user["https://imperson.com/app_metadata"];
                if (metadata && metadata.permissions) gPermissions = new Set(metadata.permissions);
                window.history.pushState("", document.title, state.href);
                onSuccess();
            } else {
                throw Error("Error fetching user profile: " + result.statusText + ", code:" + result.status);
            }
        } catch (e) {
            throw Error("Error fetching user profile: " + e);
        }
    });
}

const doFetch = async (input: any, init?: RequestInit | undefined) => {
    const data = init ? init : {};
    const headers = input.headers || data.headers || {};
    headers.Authorization = "Bearer " + gAccessToken;
    data.headers = headers;
    const request = new Request(input, data);
    const response = await fetch(request);
    return response;
};

const fetchWithAuth = (onAuthFailure: (reason: string) => void) => {
    let isRenewed = false;

    const fail = async (message: string) => {
        isFailed = true;
        // console.error(`auth0 failed: ${message}`);
        onAuthFailure(message);
        return new Response();
    };

    const getResponse = async (input: any, init?: RequestInit | undefined): Promise<Response> => {
        const response = await doFetch(input, init);
        if (!response.ok && response.status === 401) {
            // console.log(`auth0 response failed with 401`);
            if (!isRenewed) {
                // console.log(`auth0 renewing token after failure`);
                isRenewed = await renewToken();
                if (!isRenewed) return fail(`Could not renew token`);
                return getResponse(input, init);
            }
            return fail(`Could not authenticate`);
        }
        return response;
    };

    return async (input: any, init?: RequestInit | undefined) => {
        // console.log(`fetching, isFailed? ${isFailed}`);
        if (isFailed) return new Response();
        const isExpired = Date.now() * 1000 - tokenExpiresAt <= 0;
        if (isExpired) {
            // console.log(`token expired, getting a new one`);
            isRenewed = await renewToken();
            if (!isRenewed) return fail(`Could not renew token`);
        }
        const response = await getResponse(input, init);
        return response;
    };
};

export async function auth(
    loginCallback: (authData: Auth) => void,
    onAuthFailure: (reason: string) => void,
    authCallbackSuffix: string = ""
) {
    !webAuth &&
        (webAuth = new auth0.WebAuth({
            domain: "imperson.auth0.com",
            clientID: CLIENT_ID,
            responseType: "token",
            scope: "openid offline_access",
            redirectUri: window.location.origin + "/" + CALLBACK_URL + authCallbackSuffix
        }));

    try {
        doLogin(webAuth, () =>
            loginCallback({
                userName: gUserName,
                userId: gUserId,
                userEmail:gUserEmail,
                token: gAccessToken,
                logout,
                permissions: gPermissions,
                fetchWithAuth: fetchWithAuth(onAuthFailure)
            })
        );
    } catch (e) {
        onAuthFailure(e);
    }
}
