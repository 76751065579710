import React, {useEffect, useState} from "react";
import {AppState} from "../../../../app/types";
import {ActionsBar, ActionsBarOption} from "../../../../app/components/ActionsBar";
import {autorun, action} from "mobx";
import {Typography, Button, TextField} from "@material-ui/core";
import {getBotsState} from "../../logic/getBotsState";
import {Breadcrumbs} from "../../../../app/components/Breadcrumbs";
import {getBotName} from "../../../users/components/utils/getBotName";
import {hasPermission} from "../../../../app/permissions";

export const BotsControlActionsBar = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [isCannotReload, setIsCannotReload] = useState(false);
    const [numSelected, setNumSelected] = useState(0);
    const [filter, setfilter] = useState(state.filter);

    const [selectedNames, setSelectedNames] = useState(
        state.selectedBotIds
            .map(id => {
                const bot = appState.data.bots_statuses.find(bs => bs.key === id);
                return bot ? getBotName(bot) : "";
            })
            .filter(key => key)
    );

    useEffect(() => {
        return autorun(() => {
            setfilter(state.filter);
        });
    }, []);

    useEffect(() => {
        return autorun(() => {
            setNumSelected(state.selectedBotIds.length);
            setSelectedNames(
                state.selectedBotIds
                    .map(id => {
                        const bot = appState.data.bots_statuses.find(bs => bs.key === id);
                        return bot ? getBotName(bot) : "";
                    })
                    .filter(key => key)
            );
        });
    }, [state.selectedBotIds.length]);

    useEffect(() => {
        return autorun(() => {
            setIsCannotReload(!state.selectedBotIds.length);
        });
    }, [state.selectedBotIds.length]);

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        state.filter = event.target.value;
    };

    const options = [
        {
            fixedOnBar: true,
            title: "Filter",
            component: () => (
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        paddingRight: "8px",
                        paddingLeft: "8px"
                    }}>
                    <TextField
                        autoFocus={Boolean(filter)}
                        style={{margin: "0px"}}
                        value={filter}
                        onChange={onFilterChange}
                        margin="normal"
                    />
                </div>
            )
        },
        {divider: true},
        {
            fixedOnBar: true,
            title: "Selected",
            component: () => (
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                    <Button
                        color="primary"
                        onClick={action(() => {
                            while (state.selectedBotIds.length) {
                                state.selectedBotIds.pop();
                            }
                        })}
                        disabled={!numSelected}>
                        {"Clear"}
                    </Button>
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginRight: "8px"
                        }}>
                        <Typography variant="caption" color="textPrimary" key={`before_numSelected_badge`}>
                            {numSelected} selected
                        </Typography>
                    </div>
                </div>
            )
        },
        {divider: true},
        {
            title: "Reload",
            disabled: isCannotReload,
            action: () => {
                state.isShowingAreYouSureReload = true;
            }
        }
    ] as ActionsBarOption[];

    const cocoDeployable = selectedNames.filter(n => hasPermission("Coco Deploy", appState.auth.permissions, n));
    if (cocoDeployable.length) {
        options.push({
            title: "Reload COCO",
            disabled: isCannotReload,
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.reloadCoco(cocoDeployable);
            }
        });
    }

    const canManageBots = appState.auth.permissions.has("/toolbox/botadmin");
    if (canManageBots) {
        options.push({
            title: "Unload",
            disabled: isCannotReload,
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.undeploy();
            }
        });
        options.push({
            title: "Move",
            disabled: isCannotReload,
            action: () => {
                state.isShowingGroupChoiceDialog = true;
            }
        });
        options.push({divider: true});
        options.push({
            title: "Delete",
            disabled: isCannotReload,
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.unload();
            }
        });
        options.push({
            title: "Create",
            disabled: true,
            action: () => {
                // console.log("CREATE CLICKED");
            }
        });
    }
    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: state.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];
    return <ActionsBar {...{before, options}} />;
};
