import React from "react";
import {Tooltip} from "@material-ui/core";
import {BotStatusData, AppState} from "../../../app/types";
import {observer} from "mobx-react";
import {getBotName} from "../../users/components/utils/getBotName";
import {SelectionCheckbox} from "../../../app/components/SelectionCheckbox";
import {Auth} from "../../../auth/types";

const checkCanDeploy = (customerId: string, auth: Auth) => {
    return auth.permissions.has("/bot/deploy/*") || auth.permissions.has("/bot/deploy/" + customerId);
};

export const BotsCheckbox = observer((p: {appState: AppState; selectedBotIds: string[]; bot: BotStatusData}) => {
    const {appState, selectedBotIds, bot} = p;
    const isSelected = selectedBotIds.includes(bot.key);
    const isCanDeploy = checkCanDeploy(getBotName(bot), appState.auth);

    return (
        <Tooltip title={isCanDeploy ? (isSelected ? "Unselect" : "Select") : "Insufficient Permissions"}>
            <div>
                <SelectionCheckbox
                    {...{
                        isSelected,
                        isDisabled: !isCanDeploy,
                        onClick: (e: any) => {
                            e.stopPropagation();
                            isSelected
                                ? selectedBotIds.splice(selectedBotIds.indexOf(bot.key), 1)
                                : selectedBotIds.push(bot.key);
                        }
                    }}
                />
            </div>
        </Tooltip>
    );
});
