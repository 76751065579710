import React, {useEffect, useState} from "react";
import {AppState} from "../../../../app/types";
import {ActionsBar} from "../../../../app/components/ActionsBar";
import {autorun, action} from "mobx";
import {getBotsState} from "../../logic/getBotsState";
import {Breadcrumbs} from "../../../../app/components/Breadcrumbs";
import {AddGatewaysMenu} from "./AddGatewaysMenu";
import {SelectGatewaysMenu} from "./SelectGatewaysMenu";

export const GatewaysActionsBar = (p: {
    appState: AppState;
    deleteGateway: () => void;
    botGateways: {[key: string]: any};
    selectedGateway: string;
}) => {
    const {appState, deleteGateway} = p;
    const state = getBotsState(appState);

    const [botGatewaysData, setbotGatewaysData] = useState(
        state.gateways[state.expandedBotId] || {selectedGatewayId: "", deletedGateways: {}, gatewayChanges: {}}
    );
    useEffect(() => {
        return autorun(() => {
            setbotGatewaysData(
                state.gateways[state.expandedBotId] || {selectedGatewayId: "", deletedGateways: {}, gatewayChanges: {}}
            );
        });
    }, [state.gateways[state.expandedBotId], state.expandedBotId]);

    const [selectedGateway, setselectedGateway] = useState(p.selectedGateway);
    useEffect(() => {
        setselectedGateway(p.selectedGateway);
    }, [p.selectedGateway]);

    const [botGateways, setbotGateways] = useState(p.botGateways);
    useEffect(() => {
        setbotGateways(p.botGateways);
    }, [p.botGateways]);

    const [isCanSave, setIsCanSave] = useState(false);
    useEffect(() => {
        return autorun(() => {
            const l =
                Object.keys(botGatewaysData.gatewayChanges).length +
                Object.keys(botGatewaysData.deletedGateways).length;
            setIsCanSave(!!l);
        });
    }, [botGatewaysData.gatewayChanges, botGatewaysData.deletedGateways]);

    const goToRoot = action(() => {
        appState.actions.botsControl && appState.actions.botsControl.goBackUpPath();
    });

    const options = [
        {
            title: "Save",
            action: action(() => {
                appState.actions.botsControl && appState.actions.botsControl.save();
                botGatewaysData.selectedGatewayId = "";
                goToRoot();
            }),
            disabled: !isCanSave
        },
        {
            title: "Discard changes",
            action: action(() => {
                botGatewaysData.gatewayChanges = {};
                botGatewaysData.deletedGateways = {};
                botGatewaysData.selectedGatewayId = "";
                goToRoot();
            }),
            disabled: !isCanSave
        },
        {
            title: "",
            component: () => (
                <div
                    style={{
                        height: "100%",
                        borderRight: "1px solid lightgray",
                        paddingRight: "8px"
                    }}
                />
            )
        },
        {
            title: "Remove From Bot",
            action: deleteGateway,
            disabled: !Boolean(selectedGateway)
        },
        {
            title: "",
            component: () => <SelectGatewaysMenu {...{appState, botGateways, selectedGateway}} />
        },
        {
            title: "Add a gateway",
            component: (props: any) => <AddGatewaysMenu {...{appState, botGateways, ...props}} />
        }
    ];
    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: state.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];

    return <ActionsBar {...{before, options}} />;
};
