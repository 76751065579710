import React, {useState, useEffect} from "react";
import {Button, Menu, ListItem, ListItemText, Theme, makeStyles, Typography} from "@material-ui/core";
import {DashboardState} from "../../types";
import {autorun} from "mobx";

const useStyles = makeStyles((theme: Theme) => {
    return {
        paper: {overflow: "visible"}
    };
});

const useButtonStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            // marginRight: `${theme.spacing()}px`,
            // marginLeft: `${theme.spacing()}px`,
            // maxWidth: "30px",
            // maxHeight: "30px",
            // minWidth: "30px",
            // minHeight: "30px"
        }
    };
});

export const TimeGranularitySelect = (p: {dashState: DashboardState; style?: {}}) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const data = p.dashState.data;
    const [granularity, setgranularity] = useState(data.timeInterval);
    useEffect(() => {
        return autorun(() => setgranularity(data.timeInterval));
    }, []);
    const setGranularity = (granularity: string) => () => {
        data.timeInterval = granularity;
    };
    return (
        <div style={{marginRight: "8px", display: "flex", flexDirection: "row", ...p.style}}>
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Typography variant="subtitle2"> interval: </Typography>
            </div> */}
            <Button
                classes={buttonClasses}
                // size="small"
                variant="text"
                color={granularity === "hourly" ? "primary" : "default"}
                onClick={setGranularity("hourly")}>
                {"hourly"}
            </Button>
            <Button
                classes={buttonClasses}
                // size="small"
                variant="text"
                color={granularity === "daily" ? "primary" : "default"}
                onClick={setGranularity("daily")}>
                {"daily"}
            </Button>
        </div>
    );
};
