import React, {useState, useEffect} from "react";
import {AppState} from "../../../app/types";
import {Table, TableHead, TableRow, TableCell, Divider} from "@material-ui/core";
import {BotsControlTableBody} from "./BotsControlTableBody";
import {BotsControlActionsBar} from "./actionsBar/BotsControlActionsBar";
import {ToolInnerContent} from "../../../app/components/ToolInnerContent";
import {autorun} from "mobx";

export const BotsTable = (p: {appState: AppState}) => {
    const {appState} = p;
    const [numBots, setNumBots] = useState(appState.data.bots_statuses.length);
    useEffect(() => {
        return autorun(() => setNumBots(appState.data.bots_statuses.length));
    }, [appState.data.bots_statuses.length]);

    return (
        <div style={{height: "100%", overflow: "hidden", display: "flex", flexDirection: "column"}}>
            <BotsControlActionsBar {...{appState}} />
            <Divider />
            {!numBots ? null : (
                <ToolInnerContent style={{height:"calc(100% - 50px)", overflowY:"auto"}}>
                    <div style={{overflowX:"auto", overflowY:"hidden"}}>
                    <Table>
                        <BotsControlTableBody {...{appState}} />
                    </Table>
                    </div>
                </ToolInnerContent>
            )}
        </div>
    );
};
