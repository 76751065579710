import {observable} from "mobx";
export const getAsteriskPermissions = (allPermissions: {[key: string]: string[][]}) =>
    observable(
        Object.keys(allPermissions).reduce(
            (acc1, cur1) => {
                acc1[cur1] = allPermissions[cur1]
                    .filter(p => p.some(values => values === "*"))
                    .reduce((acc, cur) => {
                        acc.push(cur[2]);
                        return acc;
                    }, []);
                return acc1;
            },
            {} as {
                [key: string]: string[];
            }
        )
    );
