import React, {useState, useEffect} from "react";
import {KvpDisplay} from "../../../../kvp/KvpDisplay";
import {ToolInnerContent} from "../../../../app/components/ToolInnerContent";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {makeMakeKvPFromDefaultable} from "../../../../kvp/makeMakeKvPFromDefaultable";
import {DefaultableObjectState} from "../../../../kvp/types";

export const GatewayProperties = (p: {
    appState: AppState;
    gatewayId: string;
    gateway: {[key: string]: DefaultableObjectState};
}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const [gateway, setgateway] = useState(p.gateway);
    useEffect(() => {
        setgateway(p.gateway);
    }, [p.gateway]);
    const [gatewayId, setgatewayId] = useState(p.gatewayId);
    useEffect(() => {
        setgatewayId(p.gatewayId);
    }, [p.gatewayId]);

    const makeEntry = makeMakeKvPFromDefaultable(
        gateway,
        () => (state.gateways[state.expandedBotId].gatewayChanges[gatewayId] = {...gateway})
    );

    return (
        <div style={{height: "100%", overflow: "hidden", display: "flex", flexDirection: "column"}}>
            <ToolInnerContent style={{height: "100%", overflow: "auto"}}>
                {gatewayId && Object.keys(gateway).length ? (
                    <KvpDisplay
                        {...{
                            data: Object.keys(gateway).map(key => makeEntry(key, gateway[key].type))
                        }}
                    />
                ) : (
                    <span>NO GATEWAY SELECTED</span>
                )}
            </ToolInnerContent>
        </div>
    );
};
