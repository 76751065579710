import React, {useEffect, useState} from "react";
import {AppState} from "../../../../app/types";
import {ActionsBar, ActionsBarOption} from "../../../../app/components/ActionsBar";
import {autorun, action} from "mobx";
import {Theme} from "@material-ui/core";
import {getBotsState} from "../../logic/getBotsState";
import {makeStyles} from "@material-ui/styles";
import {hasPermission} from "../../../../app/permissions";
import {Breadcrumbs} from "../../../../app/components/Breadcrumbs";
import {getBotName} from "../../../users/components/utils/getBotName";

const useStyles = makeStyles((theme: Theme) => {
    return {
        clickableText: {
            cursor: "pointer"
        },
        drilldownText: {
            paddingLeft: `${theme.spacing(1)}px`,
            paddingRight: `${theme.spacing(1)}px`
        },
        breadcrumbsContainer: {
            display: "flex"
        }
    };
});

export const BotDetailsActionsBar = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const classes = useStyles();
    const [isCanSave, setIsCanSave] = useState(false);
    const goToRoot = action(() => {
        appState.actions.botsControl && appState.actions.botsControl.goBackUpPath();
    });
    useEffect(() => {
        return autorun(() => {
            const l =
                Object.keys(state.propertiesChanges).length +
                (state.gateways[state.expandedBotId]
                    ? Object.keys(state.gateways[state.expandedBotId].gatewayChanges).length
                    : 0) +
                (state.gateways[state.expandedBotId]
                    ? Object.keys(state.gateways[state.expandedBotId].deletedGateways).length
                    : 0);
            setIsCanSave(!l);
        });
    }, [state.propertiesChanges]);

    const selectedBot = appState.data.bots_statuses.find(b => b.key === state.expandedBotId);
    if (!selectedBot) return null;

    const options: ActionsBarOption[] = [
        {
            title: "Save",
            disabled: isCanSave,
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.save();
                goToRoot();
            }
        },
        {
            title: "Discard Changes",
            disabled: isCanSave,
            action: () => {
                delete state.propertiesChanges[state.expandedBotId];
                goToRoot();
            }
        }
    ];

    hasPermission("gateways", appState.auth.permissions) &&
        options.unshift({
            title: "gateways",
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.addToPath("gateways");
            }
        });

    hasPermission("dashboard", appState.auth.permissions, getBotName(selectedBot)) &&
        options.unshift({
            title: "dashboard",
            action: () => {
                appState.actions.botsControl && appState.actions.botsControl.addToPath("dashboard");
            }
        });

    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: state.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];

    return <ActionsBar {...{before, options}} />;
};
