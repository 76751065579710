import {AppState, LogType, BotsControlState, BotStatusData} from "../../../app/types";
import {getBotName} from "../../users/components/utils/getBotName";
import {getBotsState} from "./getBotsState";
import {DefaultableObjectState} from "../../../kvp/types";

const tags = ["bots"];
const updateContentDBUrl = "/updateContentDb";
const updateConfigDataUrl = "/updateConfig";
const saveGatwaysUrl = "/updateGws";

const getPropertiesUpdates = (state: BotsControlState) => {
    return Object.keys(state.propertiesChanges).map(key => {
        const changes = state.propertiesChanges[key];
        const contentDb = changes.contentDb;

        let isUpdatedConfig = changes.configJSON.isUpdated;
        // const configData = Object.keys(changes).reduce((acc, cur) => {
        //     // @ts-ignore
        //     (changes[cur].isUpdated || !changes[cur].isDefault) && (acc[cur] = changes[cur].value);
        //     // @ts-ignore
        //     !isUpdatedConfig && (isUpdatedConfig = changes[cur].isUpdated);
        //     return acc;
        // }, {} as {[key: string]: string | number});
        // delete configData.contentDb;
        const configData = changes.configJSON.value;
        return {
            key,
            contentDb,
            configData,
            isUpdatedConfig
        };
    });
};

const processGwToOutbound = (
    configDataRaw: {
        [key: string]: DefaultableObjectState;
    },
    gwKey: string
) => {
    const callbackURL = configDataRaw.callbackURL;
    const externalID = configDataRaw.externalID;
    delete configDataRaw.callbackURL;
    delete configDataRaw.externalID;
    const configData = Object.keys(configDataRaw).reduce((acc, cur) => {
        (configDataRaw[cur].isUpdated || !configDataRaw[cur].isDefault) && (acc[cur] = configDataRaw[cur].value);
        return acc;
    }, {} as {[key: string]: any});
    const result = {gatewayID: gwKey, configData} as GatewayOutbound;
    callbackURL &&
        (callbackURL.isUpdated || !callbackURL.isDefault) &&
        (result.callbackURL = String(callbackURL.value));
    externalID && (externalID.isUpdated || !externalID.isDefault) && (result.externalID = String(externalID.value));
    return result;
};
const getGwUpdates = (state: BotsControlState, bots: BotStatusData[]) => {
    return Object.keys(state.gateways).map(key => {
        const gwState = state.gateways[key];
        const changedGws = Object.keys(gwState.gatewayChanges).map(gwKey => {
            return processGwToOutbound(gwState.gatewayChanges[gwKey], gwKey);
        }) as GatewayOutbound[];
        const deletedGwKeys = Object.keys(gwState.deletedGateways);
        const bot = bots.find(bot => bot.key === key);
        if (bot) {
            const allGws = [...bot.gateways].filter(gw => !deletedGwKeys.includes(gw.gatewayID));
            allGws.forEach(gw => {
                const existingGwIndex = changedGws.findIndex(egw => egw.gatewayID === gw.gatewayID);
                existingGwIndex === -1 && changedGws.push(gw as GatewayOutbound);
            });
            changedGws.forEach(gw => {
                const existingGwIndex = allGws.findIndex(egw => egw.gatewayID === gw.gatewayID);
                existingGwIndex === -1 ? allGws.push(gw) : (allGws[existingGwIndex] = gw);
            });
        }
        gwState.gatewayChanges = {};
        gwState.deletedGateways = {};
        gwState.selectedGatewayId = "";
        return {key, gw: changedGws};
    });
};

type GatewayOutbound = {gatewayID: string; configData: any; callbackURL?: string; externalID?: string};

const makeGWBody = (gateways: {key: string; gw: GatewayOutbound[]}) =>
    JSON.stringify(
        gateways.gw.map(gw => ({
            key: gateways.key,
            gatewayID: gw.gatewayID,
            callbackURL: gw.callbackURL,
            externalID: gw.externalID,
            config: JSON.stringify(gw.configData)
        }))
    );

export const makeSaveBotProperties = (appState: AppState) => async () => {
    const state = getBotsState(appState);
    const bots = appState.data.bots_statuses;

    const updates = getPropertiesUpdates(state);

    const addLog = appState.actions.log.addLog;

    const saveGateways = async () => {
        const gateways = getGwUpdates(state, bots);
        try {
            const expandedBotId = state.expandedBotId;
            const bot = appState.data.bots_statuses.find(bot => bot.key === expandedBotId);
            while (expandedBotId && bot && gateways.length) {
                const gateway = gateways.pop();
                if (!gateway) return;
                const reply = await appState.auth.fetchWithAuth(saveGatwaysUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: makeGWBody(gateway)
                });
                if (!reply.ok) {
                    addLog({entry: `${gateway.key} gateways save error!`, tags, type: LogType.error});
                    return;
                } else {
                    addLog({entry: `gateways saved`, tags});
                    gateway.gw.forEach(gws => {
                        const gwb = bot.gateways.find(g => g.gatewayID === gws.gatewayID);
                        if (gwb) {
                            gwb.configData = {...gws.configData};
                            !gws.callbackURL && delete gwb.callbackURL;
                            !gws.externalID && delete gwb.externalID;
                            gws.callbackURL && (gwb.callbackURL = gws.callbackURL);
                            gws.externalID && (gwb.externalID = gws.externalID);
                        }
                    });
                    return;
                }
            }
        } catch (e) {
            addLog({entry: `Gateways save error!`, tags, type: LogType.error});
            return;
        }
    };

    const saveConfigs = async () => {
        while (updates.length) {
            const update = updates.pop();
            if (!update) {
                return;
            }
            delete state.propertiesChanges[update.key];
            const bot = bots.find(bot => bot.key === update.key);
            if (!bot) {
                addLog({entry: `${update.key} bot not found, discarding changes`, tags, type: LogType.error});
                continue;
            }
            try {
                const oldContentDb = bot.contentDB;
                if (oldContentDb !== update.contentDb) {
                    const reply = await appState.auth.fetchWithAuth(updateContentDBUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            key: `${update.key}_contentdb`,
                            prevContentDB: oldContentDb,
                            contentDB: update.contentDb
                        })
                    });
                    const json = await reply.json();
                    if (json.error) {
                        addLog({entry: `${getBotName(bot)} content db save error!`, tags, type: LogType.error});
                    } else {
                        bot.contentDB = update.contentDb;
                    }
                }
                if (update.isUpdatedConfig) {
                    const reply = await appState.auth.fetchWithAuth(updateConfigDataUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({key: `${update.key}_config`, config: JSON.stringify(update.configData)})
                    });
                    const json = await reply.json();
                    if (json.error) {
                        addLog({entry: `${getBotName(bot)} config save error!`, tags, type: LogType.error});
                    } else {
                        // @ts-ignore
                        bot.configData = update.configData;
                    }
                }
                addLog({entry: `${getBotName(bot)} saved, ${updates.length} to go`, tags});
            } catch (e) {
                addLog({entry: `${getBotName(bot)} save error!`, tags, type: LogType.error});
            }
        }
    };

    addLog({entry: "saving", tags, type: LogType.start});
    await saveGateways();
    await saveConfigs();
    addLog({entry: "save complete", tags, type: LogType.end});
    return;
};
