import {AppState, LogType} from "../../../app/types";

export const getDBs = async (appState: AppState, tags: string[] = []) => {
    const addLog = appState.actions.log.addLog;
    addLog({entry: "loading dbs", type: LogType.start, tags});
    const dbsReply = await appState.auth.fetchWithAuth("/getDbs");
    const t = await dbsReply.text();
    const xml = new DOMParser().parseFromString(t, "text/xml");
    const dbs = Array.from(xml.getElementsByTagName("personality")).map(p => {
        const description = p.getAttribute("description") || "No description";
        const name = p.getAttribute("name") || "No name";
        const parent = p.getAttribute("parent") || "No parent";
        return {description, name, parent};
    });
    appState.data.databases = dbs;
    addLog({entry: "dbs load complete", type: LogType.end, tags});
};
