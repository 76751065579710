import React from "react";
import {ListItem, Tooltip, ListItemIcon, Typography} from "@material-ui/core";
import {AppState} from "../types";
import {getDrawerState} from "../getDrawerState";
import {observer} from "mobx-react";

export const DrawerItem = observer(
    (p: {
        appState: AppState;
        uid: string;
        title: string;
        tooltip: string;
        itemIcon: JSX.Element;
        actionIcon?: JSX.Element;
        onClick?: () => void;
    }) => {
        const {appState, uid, title, tooltip, itemIcon, actionIcon, onClick} = p;
        const drawer = getDrawerState(appState);

        return (
            <ListItem
                button
                key={uid}
                style={{overflow: "hidden", height: "32px"}}
                onClick={() => {
                    onClick ? onClick() : (drawer.selectedItemId = uid);
                }}
                selected={drawer.selectedItemId === uid}>
                <Tooltip title={tooltip}>
                    <ListItemIcon style={{display: "flex", justifyContent: "space-between"}}>
                        <div
                            style={{
                                marginLeft: actionIcon && drawer.isCollapsed ? "8px" : "",
                                marginRight: drawer.isCollapsed ? "" : "8px",
                                display: "flex"
                            }}>
                            {itemIcon}
                        </div>
                    </ListItemIcon>
                </Tooltip>
                <div hidden={drawer.isCollapsed} style={{flex: 1}}>
                    <Typography variant="caption" hidden={drawer.isCollapsed}>
                        {title}
                    </Typography>
                </div>
                {actionIcon}
            </ListItem>
        );
    }
);
