import React from "react";
import {Checkbox} from "@material-ui/core";

export const SelectionCheckbox = (p: {isSelected: boolean; isDisabled?: boolean; onClick: (e: any) => void}) => {
    const {isSelected, isDisabled, onClick} = p;

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <Checkbox
                checked={isSelected}
                disabled={!!isDisabled}
                onClick={onClick}
                tabIndex={-1}
                style={{height: "24px", width: "24px"}}
            />
        </div>
    );
};
