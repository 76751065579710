import React from "react";
import { AppState } from "./types";
import { withStyles, Drawer, List, Theme, Divider, useTheme, useMediaQuery, Menu } from "@material-ui/core";
import { hasPermission } from "./permissions";
import { observer } from "mobx-react";
import { LogDrawerItem } from "./log/LogDrawerItem";
import { getDrawerState } from "./getDrawerState";
import { CollapseToolsButton } from "./components/CollapseToolsButton";

const drawerWidthLarge = 128;
const drawerWidthSmall = 56;

const styles = (theme: Theme) => ({
    drawerLarge: {
        width: drawerWidthLarge,
        flexShrink: 0
    },
    drawerPaperLarge: {
        width: drawerWidthLarge,
        overflow: "visible"
    },
    drawerCollapsed: {
        width: drawerWidthSmall,
        flexShrink: 0
    },
    drawerPaperCollapsed: {
        width: drawerWidthSmall,
        overflow: "visible"
    },
    toolbar: theme.mixins.toolbar
});

function ToolsDrawerStyleless(props: { classes: any } & { appState: AppState, mobileMenuOpen: boolean, setMobileMenuOpen: any }) {
    const { classes, appState, mobileMenuOpen, setMobileMenuOpen } = props;
    const drawer = getDrawerState(appState);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Drawer
            className={drawer.isCollapsed ? classes.drawerCollapsed : classes.drawerLarge}
            classes={{
                paper: drawer.isCollapsed ? classes.drawerPaperCollapsed : classes.drawerPaperLarge
            }}
            variant={desktop ? "permanent" : "temporary"}
            open
            onClick={() => {setMobileMenuOpen(false);}}
            style = {{ visibility: desktop || mobileMenuOpen ? "visible" : "hidden" }}
        >
            <div className={classes.toolbar} />
            <div
                style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                <List
                    style={{
                        overflow: "auto"
                    }}>
                    {appState.data.drawerItems
                        .filter(item => hasPermission(item.uid, appState.auth.permissions))
                        .map((item, i) => (
                            <item.drawerItem 
                            key={`drawerItem ${item.uid}`} 
                            {...{ appState }}
                            />
                        ))}
                </List>
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                    <LogDrawerItem {...{ appState }} />
                    <Divider />
                    <CollapseToolsButton {...{ appState }} />
                </div> */}
            </div>
        </Drawer>
    );
}

export const ToolsDrawer = withStyles(styles)(observer(ToolsDrawerStyleless));
