import React, {useState, useEffect} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    DialogTitle,
    DialogContentText,
    TextField
} from "@material-ui/core";
import {autorun} from "mobx";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const emailValidation = RegExp(
    /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);

export const AddUserDialog = props => {
    const handleClose = () => {
        props.state.isShowingAddUser = false;
    };
    const handleConfirm = e => {
        props.addUser(value);
        handleClose();
    };
    const [isOpen, setIsOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [value, setValue] = useState("");
    useEffect(() => {
        return autorun(() => setIsOpen(props.state.isShowingAddUser));
    }, [props.state.isShowingAddUser]);

    return (
        <Dialog open={!!isOpen} onClose={handleClose} TransitionComponent={Transition}>
            <DialogTitle>Add user</DialogTitle>
            <DialogContent>
                <DialogContentText>Please insert the email of user to add</DialogContentText>
                <TextField
                    onChange={e => {
                        const v = e.currentTarget.value;
                        const valid = emailValidation.test(v);
                        setValue(v);
                        setIsValid(valid);
                    }}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={!isValid} onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
