import React, {Component} from "react";
import {Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import {ServerRow} from "./ServerRow";
export class ServerTable extends Component {
    render() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell key="cell1">Instance</TableCell>
                        <TableCell key="cell2">Env</TableCell>
                        <TableCell key="cell3">Servers</TableCell>
                        <TableCell key="cell4">Start</TableCell>
                    </TableRow>
                </TableHead>
                {this.props.rows.length ? (
                    <TableBody>
                        <ServerRow key="row" rows={this.props.rows} />
                    </TableBody>
                ) : null}
            </Table>
        );
    }
}
