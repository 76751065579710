import React from "react";
import Select from "react-select";
import {withStyles, Theme, Typography, TextField, MenuItem, Paper} from "@material-ui/core";
import {emphasize} from "@material-ui/core/styles/colorManipulator";
import {observer} from "mobx-react";

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        height: 60
    },
    input: {
        display: "flex",
        padding: 0
    },
    valueContainer: {
        display: "flex",
        flexWrap: "wrap" as "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden"
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08
        )
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    placeholder: {
        position: "absolute" as "absolute",
        left: 2
    },
    paper: {
        position: "absolute" as "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        left: 0,
        right: 0
    }
});

function NoOptionsMessage(props: any) {
    return (
        <Typography
            variant="body2"
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

// @ts-ignore
function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

function Control(props: any) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps
                }
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props: any) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontSize: "13.3px",
                height: "24px",
                minHeight: "0px",
                padding: "0px",
                margin: "0px"
            }}
            {...props.innerProps}>
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props: any) {
    return (
        <Typography
            variant="body2"
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function SingleValue(props: any) {
    return (
        <Typography variant="body2" className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props: any) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: any) {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps} elevation={0}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

export const EntryAdder = withStyles(styles, {withTheme: true})(
    observer(
        (p: {
            classes: any;
            theme: Theme;
            options: {value: string; label: string}[];
            value?: string;
            isReady: boolean;
            onChange: (value: string) => void;
            placeholderText: string;
            placeholderNotReadyText: string;
        }) => {
            const {classes, theme, options, placeholderText, placeholderNotReadyText, onChange, isReady} = p;
            const selectStyles = {
                input: (base: any) => ({
                    ...base,
                    fontSize: "1rem",
                    color: theme.palette.text.primary
                })
            };

            const select = (
                <Select
                    isDisabled={!isReady}
                    classes={classes}
                    styles={selectStyles}
                    isMulti={false}
                    options={options}
                    components={components}
                    value={null}
                    // @ts-ignore
                    onChange={value => value && onChange(value.label)}
                    placeholder={isReady ? placeholderText : placeholderNotReadyText}
                />
            );
            return select;
        }
    )
);
