// import {DashboardData} from "../../app/types";

export type SizeBreakpoint = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
export type ChartLabelPosition = "bottom" | "labeled" | "left" | "none" | "right" | "top";
export type ChartLayout = {
    id: string; // chart ID
    xs: SizeBreakpoint; // default breakpoint
    lg?: SizeBreakpoint; // default breakpoint
    xl?: SizeBreakpoint; // default breakpoint
    sm?: SizeBreakpoint; // small size breakpoint
    md?: SizeBreakpoint; // medium size breakpoint
    row: number; // row to place chart
    isMini?: boolean; // whether should go into mini charts bar
    chartType: ChartType; // type of chart
    axes: {x: EntryType; y: [{label: string; value: EntryType}]}; // types of data to show on axes
    axesLabels: {x: string; y: string}; // axis labels
    legend: {position: ChartLabelPosition}; //defines label position
    title: string; // chart title
    aggregator?: Aggregator;
    formatters?: any[]; // formatters as defined in https://developers.google.com/chart/interactive/docs/reference#formatters
    dataProperty: string;
    valueSelector: string;
    filterIndex: number;
    filters: {title: string; filter: string}[];
    perGWDataProperty?: string;
};
export type QueryLayout = {
    query: string;
    chartIds: Array<string>;
};

export type QueryState = {
    query: string;
    chartIds: Array<string>;
    isLoading: boolean;
};

export type Layout = {
    charts: Array<ChartLayout>;
    queries: Array<QueryLayout>;
};

export type DashboardState = {
    data: {
        timeInterval: string;
        timeRange: number[];
        timeRangeOptionIndex: number;
        timezone: string; // timezone name
        defaultTimezone: string;
        gateway: string;
        region: string;
    };
};

export type ParsedPhrase = {
    from: {fromdate: string; fromtime: string; error: string};
    to: {todate: string; totime: string; error: string};
    every: {value: Number; enum: string; error: string};
    until: {value: string; error: string};
    match: {category: string; id: string; error: string};
};
export type TasksType = {
    taskID: string;
    whenPhrase: string;
    taskAction: string;
    taskOrder: number;
    actionResponse: string;
    parsedWhenPhrase: string;
}[];

export type DialogsTypeTable = {
    dialogID: string;
    dialogLine: number;
    response: string;
    parentLine: number;
};

export type DialogsType = {
    dialogID: string;
    dialogLine: number;
    response: string;
    isExpanded: boolean;
    children: DialogsType[];
};

export type QnaType = {
    ruleid: string;
    question: string;
    response: string;
    questionType: string;
}[];

export type FaqType = {
    index: string;
    qid: string;
    question: string;
    answer: string;
    category: number;
    categoryName: string;
}[];

export type OtherBotsType = {
    bot: string;
    count: number;
}[];

export type DataValueType = string | number | Date;

export type DataUnit = {x: DataValueType; y: DataValueType[]};

export type DataTypeEntry = {id: string; value: EntryType; isEnabled?: boolean; label: string};

export type DataValues = {first: number; returning: number; all: number};
export type gwResult = {gateway: string; result: DataValues};
export type timeframe = {start: Date; end: Date};
export type DataRow = {
    timeframe: timeframe;
    unique_users: Array<gwResult>;
    sessions: Array<gwResult>;
    turns: Array<gwResult>;
    duration: Array<gwResult>;
    msgs: Array<gwResult>;
    avg_turns: Array<gwResult>;
    avg_duration: Array<gwResult>;
    avg_msgs: Array<gwResult>;
    unique_users_total: DataValues;
    sessions_total: DataValues;
    turns_total: DataValues;
    duration_total: DataValues;
    msgs_total: DataValues;
    avg_turns_total: DataValues;
    avg_duration_total: DataValues;
    avg_msgs_total: DataValues;
    goal: string;
    result: string;
    count: string;
    [key: string]: any;
};

export type linkRow = {
    url: string;
    clicks: Array<gwResult>;
}
export type Data = {
    totals: DataRow;
    startTimestamp: Date;
    endTimestamp: Date;
    result: Array<DataRow>;
    links?: Array<linkRow>;
    points: number;
    days: number;
};

export type miniRegularGraphs = {
    mini: {layout: ChartLayout; graph: GraphState}[];
    regular: Array<{layout: ChartLayout; graph: GraphState}[]>;
};

export type GraphState = {
    isReloading: boolean;
    data: DataUnit[];
    dataTypes: {x: DataTypeEntry; y: DataTypeEntry[]};
    axesLabels: {x: string; y: string};
    legend: {position: ChartLabelPosition};
    chartType: ChartType;
    id: string;
    title?: string;
    aggregator: Aggregator;
    formatters: any[];
    dataProperty: string[];
    valueSelector: string[];
    perGWDataProperty?: string[];
    filterIndex: number;
    filters: {title: string; filter: string}[];
    gateway?: string;
};

export enum ChartType {
    "bar" = "bar",
    "line" = "line",
    "area" = "area",
    "pie" = "pie",
    "table" = "table",
    "counter" = "counter",
    "simpletable" = "simpletable"
}

export enum EntryType {
    "sessionDuration" = "sessionDuration",
    "sessionTurns" = "sessionTurns",
    "sessions" = "sessions",
    "users" = "users",
    "platform" = "platform",
    "time" = "time"
}

export enum Aggregator {
    "count" = "count",
    "average" = "average",
    "count total" = "count total",
    "average total" = "average total"
}

export enum GatewayType {
    "TWIT" = "TWIT",
    "KIK" = "KIK",
    "SMS" = "SMS",
    "SKTXT" = "SKTXT",
    "FBSR" = "FBSR",
    "PHONE" = "PHONE",
    "WEB" = "WEB",
    "APP" = "APP",
    "GHOME" = "GHOME",
    "ECHO" = "ECHO"
}

export type DataEntry = {
    sessionDuration: number;
    sessionTurns: number;
    users: number;
    platform: string;
    time: Date;
};

export const entryTypes = Object.values(EntryType) as string[];
export const chartTypes = Object.values(ChartType) as string[];
export const gatewayTypes = Object.values(GatewayType) as string[];
export const aggregators = Object.values(Aggregator) as string[];
