import {observable} from "mobx";
export const getAllPermissions = (permissions: {[key: string]: string[]}, type: string) =>
    observable(
        Object.keys(permissions).reduce(
            (acc, key) => {
                const userAllPermissions = permissions[key]
                    .filter((entry: string) => entry.startsWith(`/${type}/`))
                    .map((entry: string) => entry.split("/"));
                acc[key] = userAllPermissions;
                return acc;
            },
            {} as {
                [key: string]: string[][];
            }
        )
    );
