import {observable} from "mobx";

export const getNonAsteriskPermissions = (allPermissions: {[key: string]: string[][]}) =>
    observable(
        Object.keys(allPermissions).reduce(
            (acc1, cur1) => {
                acc1[cur1] = allPermissions[cur1].reduce((acc, cur) => {
                    if (cur.length <= 3) return acc;
                    const key = cur[3];
                    const value = cur[2];
                    if (!value || !key || key === "*") return acc;
                    !acc.has(key) && acc.set(key, []);
                    (acc.get(key) as string[]).push(value);
                    return acc;
                }, new Map<string, string[]>());
                return acc1;
            },
            {} as {
                [key: string]: Map<string, string[]>;
            }
        )
    );
