import React, {useState, useEffect} from "react";
import {AppState, BotStatusData, GatewayData} from "../../../../app/types";
import {GatewaysActionsBar} from "./GatewaysActionsBar";
import {getBotsState} from "../../logic/getBotsState";
import {autorun} from "mobx";
import {GatewayProperties} from "./GatewayProperties";
import {getGatewayProperties} from "./getGatewayProperties";
import {DefaultableObjectState} from "../../../../kvp/types";
import {Divider} from "@material-ui/core";

const reduceBotGWs = (bot: BotStatusData): GatewayData[] =>
    bot.gateways.reduce(
        (acc, cur) => {
            const result = {...cur};
            cur.hasOwnProperty("callbackURL") && (result.callbackURL = cur.callbackURL);
            cur.hasOwnProperty("externalID") && (result.externalID = cur.externalID);
            acc.push(result);
            return acc;
        },
        [] as GatewayData[]
    );

const botGwsToProperties = (bot: BotStatusData) => {
    const processedGws = reduceBotGWs(bot);
    return processedGws.reduce(
        (acc, cur) => {
            acc[cur.gatewayID] = getGatewayProperties(cur);
            return acc;
        },
        {} as {
            [gwID: string]: {
                [valueName: string]: DefaultableObjectState;
            };
        }
    );
};

export const Gateways = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);

    const [botGatewaysData, setbotGatewaysData] = useState(
        state.gateways[state.expandedBotId] || {selectedGatewayId: "", deletedGateways: {}, gatewayChanges: {}}
    );
    useEffect(() => {
        return autorun(() => {
            // console.log(`updating botGatewaysData`);
            setbotGatewaysData(
                state.gateways[state.expandedBotId] || {selectedGatewayId: "", deletedGateways: {}, gatewayChanges: {}}
            );
        });
    }, [state.expandedBotId, state.gateways]);

    const [selectedGateway, setselectedGateway] = useState(botGatewaysData.selectedGatewayId);
    useEffect(() => {
        return autorun(() => {
            botGatewaysData && setselectedGateway(botGatewaysData.selectedGatewayId);
        });
    }, [state.expandedBotId, state.gateways, botGatewaysData.selectedGatewayId]);

    const [deletedGWs, setdeletedGWs] = useState(botGatewaysData.deletedGateways);
    useEffect(() => {
        return autorun(() => {
            setdeletedGWs(botGatewaysData.deletedGateways);
        });
    }, [botGatewaysData.deletedGateways]);

    const [bots, setbots] = useState(p.appState.data.bots_statuses);
    useEffect(() => {
        const l = p.appState.data.bots_statuses.length;
        setbots(p.appState.data.bots_statuses);
        const b = bots.find(b => b.key === state.expandedBotId);
    }, [p.appState.data.bots_statuses, bots, state.expandedBotId]);

    const [bot, setbot] = useState(bots.find(b => b.key === state.expandedBotId));
    useEffect(() => {
        return autorun(() => {
            const b = bots.find(b => b.key === state.expandedBotId);
            b && setbot(b);
        });
    }, [state.expandedBotId, bots]);

    const validGws = bot ? {...botGwsToProperties(bot), ...botGatewaysData.gatewayChanges} : {};
    Object.keys(deletedGWs).forEach(gw => delete validGws[gw]);
    const [botGateways, setbotGateways] = useState(validGws);
    useEffect(() => {
        return autorun(() => {
            // console.log(`updating Gateways`);
            const validGws = bot ? {...botGwsToProperties(bot), ...botGatewaysData.gatewayChanges} : {};
            Object.keys(botGatewaysData.deletedGateways).forEach(gw => delete validGws[gw]);
            setbotGateways(validGws);
        });
    }, [bot, deletedGWs, botGatewaysData.deletedGateways, botGatewaysData.gatewayChanges]);

    if (!bot) {
        return null;
    }

    const deleteGateway = () => {
        botGatewaysData.deletedGateways[selectedGateway] = validGws[selectedGateway];
        botGatewaysData.selectedGatewayId = "";
        // console.log(`deleting gateway`);
        setdeletedGWs({...botGatewaysData.deletedGateways});
    };

    const configData = botGateways[selectedGateway];

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", flex: 1, overflow: "hidden"}}>
            <GatewaysActionsBar {...{...p, deleteGateway, botGateways, selectedGateway}} /> <Divider />
            <GatewayProperties {...{...p, gatewayId: selectedGateway, gateway: configData}} />
        </div>
    );
};
