import {observable, action} from "mobx";
import {AppState} from "../../../app/types";

const version = 1;

const handleOldVersion = action((appState: AppState) => {
    if (!appState.state.users) return;
    const entry = `State version ${appState.state.users.version} is != ${version}, using default state`;
    appState.actions.log &&
        appState.actions.log.addLog({
            tags: ["users"],
            entry
        });
    appState.state.users = undefined;
});

export const getUsersState = (appState: AppState) => {
    appState.state.users &&
        (isNaN(appState.state.users.version) || appState.state.users.version !== version) &&
        handleOldVersion(appState);

    const state =
        appState.state.users ||
        observable({
            updates: [],
            selectedUserIds: [],
            selectedTab: 0,
            isReady: false,
            isError: false,
            isReloading: false,
            isShowingAddUser: false,
            version
        });

    !appState.state.users && (appState.state.users = state);
    return state;
};
