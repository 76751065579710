import React from "react";
import {AppState} from "../../app/types";
import Home from "@material-ui/icons/Home";
import {DrawerItem} from "../../app/components/DrawerItem";

const uid = "home";

export const HomeDrawerItem = (props: {appState: AppState}) => (
    <DrawerItem {...{appState: props.appState, uid, title: uid, tooltip: uid, itemIcon: <Home color="primary" />}} />
);
