import React from "react";
import {Paper, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {getAllPermissions} from "../utils/getAllPermissions";
import {getAsteriskPermissions} from "../utils/getAsteriskPermissions";
import {getNonAsteriskPermissions} from "../utils/getNonAsteriskPermissions";
import {getCheckboxControl} from "../utils/getCheckboxControl";
import {BotsPermissionsDynamic} from "./BotsPermissionsDynamic";
import {EntryAdder} from "../../../../app/components/EntryAdder";
import {AppState} from "../../../../app/types";
import {getBotName} from "../utils/getBotName";

export const BotsPermissions = observer(
    (p: {permissions: {[key: string]: string[]}; appState: AppState} & {classes?: any}) => {
        const tempBots = observable([] as string[]);
        const {permissions, appState} = p;
        const updateUsers = appState.actions.users && appState.actions.users.updateUsers;
        if (!updateUsers) {
            console.error(`appState.actions.users.updateUsers not found`);
            return null;
        }
        const allPermissions = getAllPermissions(permissions, "bot");
        const asteriskPermissions = getAsteriskPermissions(allPermissions);
        const botsPermissions = getNonAsteriskPermissions(allPermissions);

        const togglePermission = (type: string) => (value: string) => (isOn: boolean) => {
            const entry = `/bot/${value}/${type}`;
            Object.keys(permissions).forEach(key => {
                const userPermissions = permissions[key];
                isOn
                    ? !userPermissions.includes(entry) && userPermissions.push(entry)
                    : userPermissions.includes(entry) && userPermissions.splice(userPermissions.indexOf(entry), 1);
            });
            updateUsers(permissions);
        };

        const toggleAsterisk = togglePermission("*");

        return (
            <Paper elevation={0}>
                <Table padding="none">
                    <TableHead>
                        <TableRow>
                            <TableCell key="cell1">Bot name</TableCell>
                            <TableCell key="cell2">View</TableCell>
                            <TableCell key="cell3">Deploy</TableCell>
                            <TableCell key="cell4">Update</TableCell>
                            <TableCell key="cell5">Dashboard</TableCell>
                            <TableCell key="cell6">Log view</TableCell>
                            <TableCell key="cell7">Log Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={`row *`}>
                            <TableCell>*</TableCell>
                            <TableCell>{getCheckboxControl(asteriskPermissions, "view", toggleAsterisk)}</TableCell>
                            <TableCell>{getCheckboxControl(asteriskPermissions, "deploy", toggleAsterisk)}</TableCell>
                            <TableCell>{getCheckboxControl(asteriskPermissions, "update", toggleAsterisk)}</TableCell>
                            <TableCell>
                                {getCheckboxControl(asteriskPermissions, "dashboard", toggleAsterisk)}
                            </TableCell>
                            <TableCell>{getCheckboxControl(asteriskPermissions, "logview", toggleAsterisk)}</TableCell>
                            <TableCell>
                                {getCheckboxControl(asteriskPermissions, "logcomment", toggleAsterisk)}
                            </TableCell>
                        </TableRow>
                        <BotsPermissionsDynamic
                            {...{botsPermissions, asteriskPermissions, tempBots, togglePermission}}
                        />
                        <EntryAddedWrapper {...{appState, botsPermissions, tempBots}} />
                    </TableBody>
                </Table>
            </Paper>
        );
    }
);

const EntryAddedWrapper = observer(
    (p: {
        appState: AppState;
        botsPermissions: {
            [key: string]: Map<string, string[]>;
        };
        tempBots: string[];
    }) => {
        const {appState, botsPermissions, tempBots} = p;
        return (
            <TableRow key={`row with button`}>
                <TableCell colSpan={7}>
                    <EntryAdder
                        {...{
                            isReady: !!appState.data.bots.length,
                            onChange: (value: string) => tempBots.push(value),
                            options: appState.data.bots
                                .map(bot => getBotName(bot))
                                .filter(
                                    name =>
                                        !tempBots.includes(name) &&
                                        !Object.keys(botsPermissions).some(userId => botsPermissions[userId].has(name))
                                )
                                .map((name, i) => {
                                    return {
                                        value: i.toString(),
                                        label: name
                                    };
                                }),
                            placeholderText: "Choose a bot to add",
                            placeholderNotReadyText: "Please wait for bots to load"
                        }}
                    />
                </TableCell>
            </TableRow>
        );
    }
);
