import React from "react";
import {AppState, DashboardData} from "../../../../../../app/types";
import {ActionsBar, ActionsBarOption} from "../../../../../../app/components/ActionsBar";
import {Breadcrumbs} from "../../../../../../app/components/Breadcrumbs";
import {getBotsState} from "../../../../../botsControl/logic/getBotsState";
import {DashboardTimerange} from "./DashboardTimerange";
import {TimeGranularitySelect} from "../range/TimeGranularitySelect";
import {TimezoneMenu} from "../range/TimezoneMenu";
import {ExportButton} from "./ExportButton";
import {ReloadButton} from "./ReloadButton";
import {DashboardState} from "../../types";
import {PlatformsMenu} from "../range/PlatformsMenu";
import {RegionsMenu} from "../range/RegionsMenu";

export const DashboardActionsBar = (p: {appState: AppState; dashState: DashboardState; config: DashboardData}) => {
    // console.log(`dashboard actionbar`);
    const {appState, dashState, config} = p;
    const botsState = getBotsState(appState);
    const bot = appState.data.bots_statuses.find(bot => bot.key === botsState.expandedBotId);

    const options: ActionsBarOption[] = [
        {component: () => <ReloadButton {...{appState, dashState, config}} />},
        {divider: true},
        {component: () => <TimeGranularitySelect {...{dashState}} />},
        {divider: true},
        {component: () => <DashboardTimerange {...{dashState}} />},
        {divider: true}
    ];
    if (bot && bot.configData && bot.configData.dashPlatforms) {
        options.push({component: () => <PlatformsMenu {...{appState, dashState}} />}, {divider: true});
    }
    if (bot && bot.configData && bot.configData.dashRegions) {
        options.push({component: () => <RegionsMenu {...{appState, dashState}} />}, {divider: true});
    }
    options.push(
        {component: () => <ExportButton {...{appState, dashState}} />},
        {divider: true},
        {component: () => <TimezoneMenu {...{appState, dashState}} />}
    );

    const actions = appState.actions.botsControl;
    const before = actions
        ? [
              <Breadcrumbs
                  key={"breadcrumbs"}
                  {...{
                      path: botsState.path,
                      goBackUpPath: actions.goBackUpPath,
                      addToPath: actions.addToPath
                  }}
              />
          ]
        : [];

    return <ActionsBar {...{before, options}} />;
};
