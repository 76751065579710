import React, {useEffect} from "react";
import {AppState} from "../../app/types";
import {Servers} from "./components/Servers";

export function ServersContent(p: {appState: AppState}) {
    const {appState} = p;
    useEffect(() => {
        appState.actions.servers && appState.actions.servers.getData();
    }, [appState.actions.servers]);
    return <Servers {...{appState}} />;
}
