import React, {Fragment} from "react";
import {TableRow, TableCell} from "@material-ui/core";
import {observer} from "mobx-react";
import {action} from "mobx";
import {getCheckboxWithAsteriskControl} from "../utils/getCheckboxWithAsteriskControl";

export const DbsPermissionsDynamic = observer(
    (
        p: {
            dbsPermissions: {[key: string]: Map<string, string[]>};
            asteriskPermissions: {
                [key: string]: string[];
            };
            tempDbs: string[];
            togglePermission: (type: string) => (value: string) => (isOn: boolean) => void;
        } & {
            classes?: any;
        }
    ) => {
        const {dbsPermissions, asteriskPermissions, tempDbs, togglePermission} = p;
        tempDbs.forEach(bot =>
            Object.keys(dbsPermissions).forEach(
                key => !dbsPermissions[key].has(bot) && action(() => dbsPermissions[key].set(bot, []))()
            )
        );
        const allDbsInUse = Array.from(
            new Set(Object.keys(dbsPermissions)
                .map(key => Array.from(dbsPermissions[key].keys()))
                .flat(1) as string[])
        );
        return (
            <Fragment>
                {allDbsInUse.map(key => {
                    const thisDbPermissions = Object.keys(dbsPermissions).map(
                        userKey => dbsPermissions[userKey].get(key) || []
                    );
                    return (
                        <TableRow key={`row ${key}`}>
                            <TableCell>{key}</TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "view",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "update",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "deploy",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "create",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "delete",
                                    togglePermission(key)
                                )}
                            </TableCell>
                            <TableCell>
                                {getCheckboxWithAsteriskControl(
                                    thisDbPermissions,
                                    asteriskPermissions,
                                    "manage",
                                    togglePermission(key)
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Fragment>
        );
    }
);
