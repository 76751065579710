import React, {useState, useEffect} from "react";
import {IconButton} from "@material-ui/core";
import {AppState} from "../../app/types";
import People from "@material-ui/icons/People";
import {Options} from "../../app/options/Options";
import {Reload} from "../../icons/Reload";
import {orange} from "@material-ui/core/colors";
import {autorun, action, observable} from "mobx";
import {makeGetUsers} from "./logic/makeGetUsers";
import {makeUpdateUsers} from "./logic/makeUpdateUsers";
import {makeSaveUsers} from "./logic/makeSaveUsers";
import {makeAddUser} from "./logic/makeAddUser";
import {getUsersState} from "./logic/getUsersState";
import {getDrawerState} from "../../app/getDrawerState";
import {DrawerItem} from "../../app/components/DrawerItem";

const uid = "users";

export const UsersDrawerItem = (props: {appState: AppState}) => {
    const {appState} = props;
    const drawer = getDrawerState(appState);
    const state = getUsersState(appState);
    appState.state.users && (appState.state.users = state);
    const [isError, setIsError] = useState(false);
    const [isReloading, setIsReloading] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const actionIconParams = observable({
        isRotating: isReloading,
        nextReload: NaN,
        isError
    });

    useEffect(() => {
        return autorun(() => {
            const isStatusSelected = drawer.selectedItemId === uid;
            setIsFocus(isStatusSelected);
        });
    }, [drawer.selectedItemId]);

    useEffect(() => {
        return autorun(() => {
            setIsDirty(!!state.updates.length);
        });
    }, [state.updates.length]);

    const getUsers = makeGetUsers(appState);

    const loadUsersData = action(async () => {
        action(() => (actionIconParams.isRotating = true))();
        setIsReloading(true);
        setIsError(false);
        const isSuccess = await getUsers();
        setIsReloading(false);
        setIsError(!isSuccess);
        action(() => {
            actionIconParams.isRotating = false;
            actionIconParams.isError = !isSuccess;
        })();
    });
    const updateUsers = makeUpdateUsers(appState);
    const saveUsers = makeSaveUsers(appState);
    const addUser = makeAddUser(appState);

    appState.actions.users = {addUser, saveUsers, updateUsers, loadUsersData};

    const tooltip = isDirty ? `${uid}: unsaved changes` : uid;

    const itemIcon = isDirty ? <People htmlColor={orange[500]} /> : <People color="primary" />;

    const actionIcon =
        isFocus || isReloading ? (
            <IconButton
                disabled={isReloading}
                style={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                    marginRight: "8px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                <Options
                    data={[
                        {
                            onClick: () => appState.actions.users && appState.actions.users.loadUsersData(),
                            icon: Reload({state: actionIconParams}),
                            label: "Reload"
                        }
                    ]}
                />
            </IconButton>
        ) : (
            undefined
        );

    return <DrawerItem {...{appState, uid, title: uid, tooltip, itemIcon, actionIcon}} />;
};
