import React, { useEffect, useState } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { makeTheme } from "../theme/makeTheme";
import { CircularProgress, CssBaseline, Typography } from "@material-ui/core";
import {
    AppState,
    LogType,
    LogEntryData,
    DatabaseData,
    BotsData,
    BotStatusData,
    EngineStatusData,
    ServerEngineData,
    ServerInstanceData,
    UserData,
    DrawerItemData,
    AppStates,
    UserState,
    UserType
} from "./types";
import { Appbar } from "./Appbar";
import { Content } from "./Content";
import { ToolsDrawer } from "./ToolsDrawer";
import { observable, autorun } from "mobx";
import { HomeComponent } from "../content/home/HomeComponent";
import { UsersComponent } from "../content/users/UsersComponent";
import { hasPermission } from "./permissions";
import { ServersComponent } from "../content/servers/ServersComponent";
import { loadState } from "./statePersistence/loadState";
import { saveState } from "./statePersistence/saveState";
import { BotsControlComponent } from "../content/botsControl/BotsControlComponent";
import { applyState } from "./statePersistence/applyState";
import { Auth } from "../auth/types";
import { AuthErrorDialog } from "./components/AuthErrorDialog";
import { auth } from "../auth/auth";
import { GraphState, QueryState } from "../content/botsControl/components/dashboard/types";
import layoutJson from "./../content//botsControl/components/dashboard/test/config.json";
import { Layout } from "./../content//botsControl/components/dashboard/types";
import { getDrawerState } from "./getDrawerState";
import { getBotsState } from "../content/botsControl/logic/getBotsState";
import { getUser } from "../content/home/logic/getUser";
import { setReactionScheduler } from "mobx/lib/internal";
import { ToolContent } from "./components/ToolContent";

// update welcome, first time and about us
// validate all inputs
// finish notifications
// remove tasks


/*
TODO
    * switch coco permissions to /bot/cocodeploy/<bot name>
    Dashboard
        * The app bar on both dashboard and gateways is missing a bottom border.
        * The button styles on the app bar in gateways and dashboard is not consistent with the rest of the system.
            * there was 1 button in gateway that had different main color. Changed.
            * dashboard buttons have a different function from other buttons (they're radio buttons). So they look different.
        * Remove the labels: "period:" and "interval:"
        * Each chart should have a border, like the table borders.
        * Change default button size to "small"
        * chart spacing and sizing is wrong. 
            * The spacing between charts and between charts and the container should be fixed
            * Charts need to resize to accommodate the space according to the window size
            * You can see how the existing dashboard is sizing the charts
        * The "counter" charts at the top should be aligned to the left or right, not across the window
        * For the dashboard, the time zone should be taken from the bot properties. If it's not defined there, it should be "UTC".
        * The "custom" time range should be by default from "today" until "today"
        * When changing the time range from "day" to "week" or "all", the interval should change from "hourly" to "daily".
        * add dashboard stuff to log

        * hunt down the errors that happen when fetching data - is NOT due to missing timezone

        * change "day" and "hour" to "daily" and "hourly"
        * why no proper width on charts?
        * how to prevent charts from being too high (when have table and such)?
        * use my own title, remove title from charts
        
        * use airBNB date pick if
            * add today / clear buttons
            * can disable future and enable the past
            * pop on top of button 

        * use path to determine tool and breadcrumbs
        
        * switch to new API 

        * switch all names to lowercase

        * missing timezone (see https://toolbox.imperson.com/bots/vmas1-prod/dashboard)

        ~ fix Bots lowercase - when received from saved state
        
        * fix custom time requests - add zero padding to day and month

        * fix padding of line charts
        
        * make counters responsive

        cancel previous query if have a new query

    ? Why add/remove gw is broken ?
        * switch to deleting by sending a list without the deleted GW

    Create bot (always available)

    Tables
        do not wrap text in cells, allow scrolling hor
    update bots list when finished updating state

    auth0 silent login
        https://auth0.com/docs/api-auth/tutorials/silent-authentication#successful-authentication-response
        wrap auth0 in an iframe
        set up communication with parent
            https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
            
*/

const isLocal = () =>
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "";

const theme = makeTheme();

const makeDefaultState = (): AppState => {
    const appState = {
        auth: observable({
            token: "",
            userName: "",
            userId: "",
            userEmail: "",
            logout: () => { },
            fetchWithAuth: (input: any, init?: any) => Promise.resolve(new Response()),
            permissions: new Set<string>()
        }),
        data: observable({
            drawerItems: [] as DrawerItemData[],
            users: [] as UserData[],
            server_instances: [] as ServerInstanceData[],
            server_engines: [] as ServerEngineData[],
            engine_statuses: {} as { [key: string]: EngineStatusData },
            bots_statuses: [] as BotStatusData[],
            log: [] as LogEntryData[],
            bots: [] as BotsData[],
            databases: [] as DatabaseData[]
            // dashboardState: {
            //     layout: layoutJson as Layout,
            //     graphs: [] as GraphState[],
            //     queries: [] as QueryState[]
            // }
        }),
        state: observable({} as AppStates),
        user: observable({ userType: 0 } as UserState),
        actions: {},
        version: "0.1.18"
    };
    const actions = {
        log: {
            addLog: (p: { entry: string; type?: LogType; tags?: string[]; style?: any }) => {
                const { entry, style, tags, type } = p;
                appState.data.log.push({
                    entry,
                    style,
                    tags: tags ? tags : [],
                    type: type ? type : LogType.info,
                    timestamp: new Date()
                });
            }
        }
    };
    return { ...appState, actions };
};

const appState = makeDefaultState();

const loginWithAuth = (appState: AppState, errorDialogState: { isOpen: boolean }, setUser: any) => {
    auth(
        async (auth: Auth) => {
            // @ts-ignore
            Object.keys(auth).forEach(k => (appState.auth[k] = auth[k]));
            const newState = await loadState(appState);
            newState && applyState(appState, newState);
            appState.data.drawerItems = [
                HomeComponent,
                UsersComponent,
                ServersComponent,
                BotsControlComponent
            ].filter(item => hasPermission(item.uid, auth.permissions));
            const path = window.location.pathname.split("/").filter(p => p);
            breadcrumbsFromPath(appState, path);
            appState.user.setUser = setUser;
            if (auth.permissions && auth.permissions.size !== 0) {
                if (auth.permissions.has("/selfregistered")) {
                    // self registered
                    loggedInUserState(appState, setUser);
                } else {
                    // authorized user
                    appState.user.userType = UserType.authorized;
                    appState.user.setUser(UserType.authorized);
                }
            }
            else {
                // just loggedin without permissions
                loggedInUserState(appState, setUser);
            }
            // !isLocal() &&
            autorun(() => {
                saveState(appState);
            });
        },
        () => {
            // console.log("handling auth failure");
            errorDialogState.isOpen = true;
        }
    );
};

const breadcrumbsFromPath = (appState: AppState, path: string[]) => {
    const drawer = getDrawerState(appState);

    if (!path.length) {
        return;
    }
    const item = path.shift();
    if (!item || !hasPermission(item, appState.auth.permissions)) return;
    drawer.selectedItemId = item || "";
};

const loggedInUserState = (appState: AppState, setUser: any) => {
    getUser(appState, "login", {}).then(res => {
        if (res.status === 0) {
            appState.user.userType = UserType.loggedin;
            setUser(UserType.loggedin);
            // console.log("logged in");
        } else if (res.status === 1 || res.status === 2) {
            appState.user.userType = res.status === 1 ? UserType.registered : res.status === 2 ? UserType.premium : UserType.initial
            appState.user.bot = res.bot;
            appState.user.key = res.key;
            appState.user.website = res.website;
            appState.user.botName = res.botName;
            appState.user.businessName = res.businessName;
            setUser(appState.user.userType);
        }
    });
}

export default function App() {
    const errorDialogState = observable({ isOpen: false });
    const path = window.location.pathname.split("/");
    const [user, setUser] = useState(UserType.initial);
    const [mobileMenuOpen,setMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (user === UserType.initial && (!appState.auth || !appState.auth.token)) {
            loginWithAuth(appState, errorDialogState, setUser)
        }
    }, [user]);

    if (path.length && path[path.length - 1] === "401.html") {
        return <div>401</div>;
    }
    // console.log("rendering app",path);

    return (
        <MuiThemeProvider theme={theme}>
            <div style={{ display: "flex" }}>
                <CssBaseline />
                <Appbar {...{ appState, mobileMenuOpen,setMobileMenuOpen }} />
                <ToolsDrawer {...{ appState, mobileMenuOpen, setMobileMenuOpen }} />
                {user == UserType.initial
                    ? <ToolContent>
                        <div style={{ textAlign: "center" as "center" }}>
                            <CircularProgress />
                            <Typography variant="h6">Please Wait...</Typography>
                        </div>
                    </ToolContent>
                    : <Content {...{ appState }} />}
            </div>
            <AuthErrorDialog {...{ state: errorDialogState }} />
        </MuiThemeProvider>
    );
}
