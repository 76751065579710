import * as React from "react";
import {Menu, MenuItem, Typography, IconButton, Theme} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {OptionProp} from "./types";
import {makeStyles, useTheme} from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme & {sizes: {small: number}}) => {
    const root = {
        width: "100%",
        height: "26px",
        minHeight: "26px",
        margin: "2px",
        display: "flex",
        alignItems: "center"
    };
    const container = {
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "center",
        alignItems: "center"
    };
    const checkbox = {
        ...root,
        flexBasis: "50%",
        maxWidth: "26px"
    };
    const menuItem = {
        display: "flex",
        paddingTop: "2px",
        paddingBottom: "2px",
        justifyContent: "space-between"
    };
    const topPanelButton = {
        display: "flex",
        flexDirection: "column" as "column",
        maxHeight: `${theme.sizes.small * 2}px`,
        height: `${theme.sizes.small * 2}px`,
        width: `${theme.sizes.small * 2}px`
    };
    const rightmostButton = {};
    const singleMenuItem = {
        display: "flex",
        alignSelf: "center"
    };
    return {
        topPanelButton,
        rightmostButton,
        root,
        checkbox,
        menuItem,
        singleMenuItem,
        container
    };
});

const stopTap = (e: React.SyntheticEvent<HTMLElement>) => e.stopPropagation();

const menuItems = (className: string, items: OptionProp[]) => {
    return items.map((item, i) => (
        <MenuItem key={`option_${i}_${item.label}`} onClick={item.onClick} className={className}>
            {item.icon}
            <Typography>{item.label}</Typography>
        </MenuItem>
    ));
};

export function Options(p: {data: OptionProp[]}) {
    const {data} = p;
    const theme = useTheme();
    const classes = useStyles(theme);
    const refContainer = React.useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    if (!data.length) return null;
    const Icon = data[0].icon;
    return (
        <div className={classes.container} onDoubleClick={stopTap}>
            <div className={classes.rightmostButton}>
                {data.length > 1 ? (
                    <div ref={refContainer}>
                        <IconButton onClick={() => setIsOpen(!isOpen)} className={classes.topPanelButton}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={refContainer.current}
                            open={isOpen}
                            onClose={() => setIsOpen(false)}>
                            {menuItems(classes.menuItem, data)}
                        </Menu>
                    </div>
                ) : (
                    <div
                        className={classes.singleMenuItem}
                        onClickCapture={() => {
                            data[0].onClick();
                            setIsOpen(false);
                        }}>
                        <Icon />
                    </div>
                )}
            </div>
        </div>
    );
}
