import React from "react";
import { AppState, UserType } from "./types";
import { AppBar, Toolbar, Typography, withStyles, Theme, IconButton, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { AuthButton } from "./AuthButton";
import { observer } from "mobx-react";
import MenuIcon from '@material-ui/icons/Menu';

const styles = (theme: Theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    }
});

function AppbarStyleless(props: { classes: any } & { appState: AppState, mobileMenuOpen: boolean, setMobileMenuOpen: any }) {
    const { classes, appState, mobileMenuOpen,setMobileMenuOpen } = props;
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
            <Toolbar style={{ justifyContent: "space-between", paddingLeft: "0px" }}>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                        <Hidden smUp>
                        <IconButton onClick={() => {console.log("click", mobileMenuOpen); setMobileMenuOpen(!mobileMenuOpen); } }>
                            <MenuIcon htmlColor="white" style={{width:"20px",height:"20px"}}/>
                        </IconButton>
                        </Hidden>
                        {desktop ? <div style={{width:"20px"}}></div> : null}
                        <Typography variant="body2" color="inherit" noWrap style={{padding:"0",fontSize:"16px",lineHeight:"16px"}}>
                            {appState.user.userType === UserType.premium ? `TOOLBOX PREMIUM` : `TOOLBOX`}
                        </Typography>
                    </div>
                </div>
                <div>
                    <AuthButton {...{ appState }} />
                </div>
            </Toolbar>
        </AppBar>
    );
}
export const Appbar = withStyles(styles)(observer(AppbarStyleless));
