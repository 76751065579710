import React, {useState, useEffect, useCallback} from "react";
import {IconButton} from "@material-ui/core";
import {AppState} from "../../app/types";
import {Options} from "../../app/options/Options";
import {Reload} from "../../icons/Reload";
import {autorun, observable, action} from "mobx";
import {getDBs} from "../users/logic/getDBs";
import {orange} from "@material-ui/core/colors";
import {makeSaveBotProperties} from "./logic/makeSaveBotProperties";
import {getBotsState} from "./logic/getBotsState";
import {getDrawerState} from "../../app/getDrawerState";
import {DrawerItem} from "../../app/components/DrawerItem";
import {Robots} from "../../icons/Robots";
import {makeDoReloads} from "./logic/makeDoReloads";
import {makeGetStatus} from "./logic/makeGetStatus";
import {makeBotsUndeploy} from "./logic/makeBotsUndeploy";
import {makeBotsMove} from "./logic/makeBotsMove";
import {makeBotsUnload} from "./logic/makeBotsUnload";
import {makeCocoReload} from "./logic/makeCocoReload";

let timeout: NodeJS.Timeout;
const reloadDelay = 60 * 5 * 1000;

const uid = "bots";

export const BotsControlDrawerItem = (props: {appState: AppState}) => {
    const {appState} = props;
    const state = getBotsState(appState);
    const drawer = getDrawerState(appState);
    const getStatus = makeGetStatus(appState);
    // const getCocoWhitelist = makeGetCocoWhitelist(appState);

    const [isError, setIsError] = useState(false);
    const [isReloading, setIsReloading] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [nextReload, setNextReload] = useState(Date.now() + reloadDelay);
    const [isDirty, setIsDirty] = useState(false);

    const actionIconParams = observable({
        isRotating: isReloading,
        nextReload,
        isError
    });

    const getData = useCallback(async () => {
        action(() => (actionIconParams.isRotating = true))();
        setIsReloading(true);
        const isSuccess = await getStatus();
        await getDBs(appState, ["bots"]);
        setIsReloading(false);
        setIsError(!isSuccess);
        action(() => {
            actionIconParams.isRotating = false;
            actionIconParams.isError = !isSuccess;
            setNextReload(Date.now() + reloadDelay);
        })();
        return isSuccess;
    }, [getStatus, appState, actionIconParams.isError, actionIconParams.isRotating]);

    useEffect(() => {
        timeout = setInterval(() => {
            if (drawer.selectedItemId === uid) {
                getData();
            }
        }, reloadDelay);
        return () => clearInterval(timeout);
    }, [drawer.selectedItemId, getData]);

    useEffect(() => {
        return autorun(() => {
            setIsFocus(drawer.selectedItemId === uid);
        });
    }, [drawer.selectedItemId]);

    useEffect(() => {
        return autorun(() => {
            const l =
                Object.keys(state.propertiesChanges).length +
                (state.gateways[state.expandedBotId]
                    ? Object.keys(state.gateways[state.expandedBotId].gatewayChanges).length
                    : 0) +
                (state.gateways[state.expandedBotId]
                    ? Object.keys(state.gateways[state.expandedBotId].deletedGateways).length
                    : 0);
            setIsDirty(!!l);
        });
    }, [state.propertiesChanges, state.expandedBotId, state.gateways]);

    const resetExpanded = () => {
        delete state.gateways[state.expandedBotId];
        delete state.propertiesChanges[state.expandedBotId];
        state.expandedBotId = "";
        state.selectedTab = 0;
    };
    const save = makeSaveBotProperties(appState);
    const reload = makeDoReloads(appState);
    const goBackUpPath = action((index: number = -1) => {
        index === -1 ? state.path.pop() : state.path.splice(index + 1);
        // state.selectedTab = state.path.length - 1;
        state.path.length <= 1 && resetExpanded();
    });
    const addToPath = action((name: string) => {
        state.path.push(name);
        // state.selectedTab = state.path.length - 1;
    });
    const unload = makeBotsUnload(appState);
    const undeploy = makeBotsUndeploy(appState);
    const moveBots = makeBotsMove(appState);
    const reloadCoco = makeCocoReload(appState);
    appState.actions.botsControl = {
        getData,
        save,
        reload,
        unload,
        undeploy,
        moveBots,
        goBackUpPath,
        addToPath,
        reloadCoco
    };

    const tooltip = isDirty ? `Bots: unsaved changes` : "bots";

    const itemIcon = isDirty ? <Robots htmlColor={orange[500]} /> : <Robots color="primary" />;

    const actionIcon =
        isFocus || isReloading ? (
            <IconButton
                disabled={isReloading}
                style={{
                    width: "16px",
                    height: "16px",
                    marginLeft: "8px",
                    marginRight: "8px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column"
                }}>
                <Options
                    data={[
                        {
                            onClick: getData,
                            icon: Reload({state: actionIconParams}),
                            label: "Reload"
                        }
                    ]}
                />
            </IconButton>
        ) : (
            undefined
        );

    return <DrawerItem {...{appState, uid, title: "bots", tooltip, itemIcon, actionIcon}} />;
};
