import {AppState, LogType} from "../../../app/types";
import {getBotsState} from "./getBotsState";

const tags = ["bots"];
const url = "https://api.imperson.com/disableKeys?keys=";

export const makeBotsUnload = (appState: AppState) => async () => {
    const state = getBotsState(appState);
    const addLog = appState.actions.log.addLog;
    addLog({entry: "unloading bots", tags, type: LogType.start});

    const reply = await appState.auth.fetchWithAuth(url + state.selectedBotIds.join(","));
    const json = await reply.json();
    if (json.error) {
        addLog({entry: `error ${json.error} unloading bots`, tags, type: LogType.error});
    } else {
        addLog({entry: "unloading bots complete", tags, type: LogType.end});
    }
};
