import React, {useState, useEffect} from "react";
import {AppState} from "../../../../app/types";
import {getBotsState} from "../../logic/getBotsState";
import {ToolInnerContent} from "../../../../app/components/ToolInnerContent";
import {autorun} from "mobx";
import {Table, TableHead, TableCell, TableBody, TableRow} from "@material-ui/core";
import {getBotName} from "../../../users/components/utils/getBotName";

export const BotsEnginesTab = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);

    const [engine_statuses, setengine_statuses] = useState(appState.data.engine_statuses);
    const [isReady, setIsReady] = useState(!!Object.keys(appState.data.engine_statuses).length);
    useEffect(() => {
        return autorun(() => {
            setIsReady(!!Object.keys(appState.data.engine_statuses).length);
            setengine_statuses(appState.data.engine_statuses);
        });
    }, [appState.data.engine_statuses]);

    const [bots_statuses, setbots_statuses] = useState(appState.data.bots_statuses);
    useEffect(() => {
        return autorun(() => {
            const l = appState.data.bots_statuses.length;
            setbots_statuses(appState.data.bots_statuses);
        });
    }, [appState.data.bots_statuses.length, appState.data.bots_statuses]);

    const bot = bots_statuses.find(bot => bot.key === state.expandedBotId);
    if (!bot) return null;
    const name = getBotName(bot);
    const rawEngine = engine_statuses[name];

    const xml = !rawEngine
        ? new Document()
        : new DOMParser().parseFromString("<engines>" + rawEngine.response + "</engines>", "text/xml");
    const engineStatusArr = Array.from(xml.getElementsByTagName("engine-status"));

    return (
        <div style={{height: "100%", overflow: "hidden", display: "flex", flexDirection: "column"}}>
            <ToolInnerContent style={{height: "100%", overflow: "auto"}}>
                {!isReady ? (
                    "PLEASE WAIT FOR ENGINES TO LOAD"
                ) : !engineStatusArr.length ? (
                    "NO ENGINES"
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell key="server" style={{width: "20%"}}>
                                    server
                                </TableCell>
                                <TableCell key="engine" style={{width: "20%"}}>
                                    engine
                                </TableCell>
                                <TableCell key="process" style={{width: "5%"}}>
                                    process
                                </TableCell>
                                <TableCell key="active" style={{width: "5%"}}>
                                    active
                                </TableCell>
                                <TableCell key="total" style={{width: "5%"}}>
                                    total
                                </TableCell>
                                <TableCell key="memory" style={{width: "5%"}}>
                                    memory
                                </TableCell>
                                <TableCell key="start" style={{width: "20%"}}>
                                    start
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {engineStatusArr.map((esa, i) => {
                                return (
                                    <TableRow key={`${i}`}>
                                        <TableCell>{esa.getAttribute("server-id")}</TableCell>
                                        <TableCell>{esa.getAttribute("engine-id")}</TableCell>
                                        <TableCell>{esa.getAttribute("process-id")}</TableCell>
                                        <TableCell>{esa.getAttribute("active-session-count")}</TableCell>
                                        <TableCell>{esa.getAttribute("session-count")}</TableCell>
                                        <TableCell>{esa.getAttribute("memory")}</TableCell>
                                        <TableCell>{esa.getAttribute("start-time")}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
            </ToolInnerContent>
        </div>
    );
};
