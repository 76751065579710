import {AppState, LogType} from "../../../app/types";
import {getDBs} from "./getDBs";

const tags = ["users"];

export const makeGetUsers = (appState: AppState) => async () => {
    const botlistUrl = "https://api.imperson.com/botlist";
    const addLog = appState.actions.log.addLog;

    try {
        addLog({entry: "loading users", type: LogType.start, tags});

        const reply = await appState.auth.fetchWithAuth("/getUsers");
        const json = (await reply.json()) as any[];
        appState.data.users = json.sort((a, b) => (a.email < b.email ? -1 : a.email > b.email ? 1 : 0));
        addLog({entry: "users load complete", type: LogType.end, tags});
    } catch (e) {
        addLog({entry: "users load failed", type: LogType.error, tags});
    }
    try {
        addLog({entry: "loading bots", type: LogType.start, tags});
        const botsReply = await appState.auth.fetchWithAuth(botlistUrl);
        appState.data.bots = await botsReply.json();
        addLog({entry: "bots load complete", type: LogType.end, tags});
    } catch (e) {
        addLog({entry: "bots load failed", type: LogType.error, tags});
    }
    try {
        await getDBs(appState, tags);
    } catch (e) {
        addLog({entry: "dbs load failed", type: LogType.error, tags});
        return false;
    }
    return true;
};
