import {createMuiTheme} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";

export const makeTheme = () =>
    createMuiTheme({
        // @ts-ignore
        sizes: {
            small: 8,
            mid: 28,
            large: 38
        },
        transition: "all 0.2s ease",
        mixins: {
            toolbar: {
                minHeight: "40px"
            }
        },
        props: {
            MuiButton: {
                size: "small"
            },
            MuiTabs: {
                    indicatorColor:"primary"
                }
        },
        overrides: {
            MuiTab: {
                labelIcon: {
                    height: "32px",
                    minHeight: "32px",
                    paddingTop: "0px",
                    "& MuiTab-wrapper > *:first-child": {marginBottom: "0px !important"}
                },
                wrapper: {
                    display: "flex",
                    flexDirection: "row"
                },
                root: {
                    minHeight: "initial"
                }
            },
            MuiTabs: {
                root: {
                    height: "32px",
                    minHeight: "32px"
                },
                flexContainer: {
                    height: "32px",
                    minHeight: "32px"
                }
            },
            MuiListItem: {
                gutters: {
                    paddingLeft: "6px",
                    paddingRight: "6px"
                },
                root: {
                    justifyContent: "space-around"
                }
            },
            MuiListItemIcon: {
                root: {
                    marginRight: "0px",
                    minWidth: "12px"
                }
            },
            MuiTable: {
                root: {
                    display: "table",
                    width: "100%",
                    borderCollapse: "collapse",
                    borderSpacing: "8px"
                    // marginLeft: "8px"
                }
            },
            MuiTableRow: {
                root: {
                    paddingLeft: "28px",
                    height: "48px",
                    minHeight: "48px",
                    "&$selected": {
                        backgroundColor: blue[100],
                        "&:hover": {
                            backgroundColor: blue[300]
                        }
                    }
                },
                head: {
                    paddingLeft: "28px",
                    height: "24px"
                }
            },
            MuiButton: {
                sizeSmall: {
                    fontSize: "0.8em"
                }
            },
            MuiTableCell: {
                root: {
                    padding: "8px",
                    minHeight: "48px",
                    height: "48px",
                    textAlign: "left",
                    whiteSpace: "nowrap"
                }
            },
            MuiSvgIcon: {
                root: {
                    height: "16px",
                    margin: "0px !important"
                }
            },
            MuiToolbar: {
                regular: {
                    minHeight: "40px",
                    "@media (min-width: 600px)": {
                        minHeight: "40px"
                    }
                }
            },
            MuiBadge: {
                badge: {
                    top: "50%",
                    right: -16,
                    borderRadius: "5px"
                }
            }
        },
        palette: {
            // type,
            primary: {
                light: blue[300],
                main: blue[500],
                dark: blue[700]
            },
            secondary: {
                light: green[300],
                main: green[500],
                dark: green[700]
            }
        },
        typography: {
            fontFamily: ["Verdana", "sans-serif"].join(",")
            // fontSize: 14,
            // subheading: {
            //     fontSize: "0.8em"
            // },
            // h6: {
            //     fontSize: 14
            // }
        }
        // shadows: Array.from(new Array(25), () => "none")
    });
