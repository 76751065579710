import React, {useEffect} from "react";
import {AppState} from "../../app/types";
import {BotsContent} from "./components/BotsContent";

export function BotsControlContent(p: {appState: AppState}) {
    const {appState} = p;
    useEffect(() => {
        appState.actions.botsControl && appState.actions.botsControl.getData();
    }, [appState.actions.botsControl]);
    return <BotsContent {...{appState}} />;
}
