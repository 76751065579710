import React, {useState, useEffect} from "react";
import {AppState} from "../../../../app/types";
import {BotsControlDetailsTabs} from "./BotsControlDetailsTabs";
import SwipeableViews from "react-swipeable-views";
import {BotsEnginesTab} from "./BotsEnginesTab";
import {BotsPropertiesTab} from "./BotsPropertiesTab";
import {getBotsState} from "../../logic/getBotsState";
import {BotDetailsActionsBar} from "./BotDetailsActionsBar";
import {autorun} from "mobx";
import {Divider} from "@material-ui/core";
import {ToolInnerContent} from "../../../../app/components/ToolInnerContent";
import {BotsBroadcastTab} from "./BotsBroadcastTab";
import {BotsLinksTab} from "./BotsLinksTab";
import {hasPermission} from "../../../../app/permissions";
import {getBotName} from "../../../users/components/utils/getBotName";

export const BotsDetails = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);

    const [expandedBotId, setExpandedBotId] = useState(state.expandedBotId);
    useEffect(() => {
        return autorun(() => setExpandedBotId(state.expandedBotId));
    }, [state.expandedBotId]);

    const [numBotsInData, setnumBotsInData] = useState(appState.data.bots_statuses.length);
    useEffect(() => {
        return autorun(() => setnumBotsInData(appState.data.bots_statuses.length));
    }, [appState.data.bots_statuses.length]);

    const [selectedTab, setSelectedTab] = useState(state.selectedTab);
    useEffect(() => {
        return autorun(() => setSelectedTab(state.selectedTab));
    }, [state.selectedTab]);

    const bots = appState.data.bots_statuses;
    const selected = state.expandedBotId;
    if (!selected) return null;
    const bot = bots.find(b => b.key === selected);
    if (!bot) return null;

    return expandedBotId && !!numBotsInData ? (
        <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "column"}}>
            <BotDetailsActionsBar {...{appState}} />
            <Divider />
            <ToolInnerContent
                style={{
                    height: "100%",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid lightgray"
                }}>
                <BotsControlDetailsTabs {...{appState}} />
                <Divider />
                <SwipeableViews
                    disabled
                    style={{height: "100%", overflow: "hidden"}}
                    slideStyle={{height: "100%", overflow: "hidden"}}
                    containerStyle={{height: "100%"}}
                    index={selectedTab}
                    axis="x"
                    springConfig={{
                        duration: "0.2s",
                        easeFunction: `cubic-bezier(0.15, 0.3, 0.25, 1)`,
                        delay: "0s"
                    }}>
                    {hasPermission("Bot Links", appState.auth.permissions, getBotName(bot)) && (
                        <BotsLinksTab {...{appState}} />
                    )}
                    {hasPermission("Bot Properties", appState.auth.permissions) && (
                        <BotsPropertiesTab {...{appState}} />
                    )}
                    {hasPermission("Bot Engines", appState.auth.permissions) && <BotsEnginesTab {...{appState}} />}
                    {hasPermission("Bot Broadcasts", appState.auth.permissions) && <BotsBroadcastTab {...{appState}} />}
                    {/* {hasPermission("gateways", appState.auth.permissions) && <BotsGatewaysTab {...{appState}} />} */}
                </SwipeableViews>
            </ToolInnerContent>
        </div>
    ) : (
        <div style={{height: "100%", width: "100%", display: "flex", flexDirection: "column"}}>
            <BotDetailsActionsBar {...{appState}} />
        </div>
    );
};
