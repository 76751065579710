import React, { useState } from "react";
import { AppState, BotPropertiesState, BotStatusData } from "../../../../app/types";
import { DefaultableObjectState } from "../../../../kvp/types";
import { EntryAdder } from "../../../../app/components/EntryAdder";
import { observer } from "mobx-react";
import { observable, action, toJS } from "mobx";
import { getBotsState } from "../../logic/getBotsState";
import { ToolInnerContent } from "../../../../app/components/ToolInnerContent";
import { KvpDisplay, KvpType } from "../../../../kvp/KvpDisplay";
import { getBotName } from "../../../users/components/utils/getBotName";
import { Auth } from "../../../../auth/types";
import { makeMakeKvPFromDefaultable } from "../../../../kvp/makeMakeKvPFromDefaultable";
import { Divider, Input, TextField } from "@material-ui/core";

const canUpdate = (auth: Auth, bot: BotStatusData) =>
    auth.permissions.has("/bot/update/*") || auth.permissions.has("/bot/update/" + getBotName(bot));

const validConfig = (tempConfig: string ,config: string) => {
    try
    {
        return JSON.parse(tempConfig);
    }
    catch {
        return JSON.parse(config);
    }
}

export const BotsPropertiesTab = observer((p: { appState: AppState }) => {
    const state = getBotsState(p.appState);
    const bots = p.appState.data.bots_statuses;
    const bot = bots.find(b => b.key === state.expandedBotId);
    if (!bot) {
        return null;
    }

    // @ts-ignore --- this is here for observer to trigger on length change
    const numDbs = p.appState.data.databases.length;

    const configData = (bot.configData || {}) as any;
    const botPropertiesState =
        state.propertiesChanges[bot.key] ||
        observable({
            contentDb: bot.contentDB,
            enginesPerInstance: {
                value: isNaN(configData.enginesPerInstance) ? 0 : configData.enginesPerInstance,
                isDefault: !configData.hasOwnProperty("enginesPerInstance"),
                isUpdated: false,
                default: 0
            },
            timezone: {
                value: configData.timezone || "America/Los_Angeles",
                isDefault: !configData.hasOwnProperty("timezone"),
                isUpdated: false,
                default: "America/Los_Angeles"
            },
            dashPassword: {
                value: configData.dashPassword || "",
                isDefault: !configData.hasOwnProperty("dashPassword"),
                isUpdated: false,
                default: ""
            },
            customDash: {
                value: configData.customDash || "",
                isDefault: !configData.hasOwnProperty("customDash"),
                isUpdated: false,
                default: ""
            },
            intentServerURL: {
                value: configData.intentServerURL || "",
                isDefault: !configData.hasOwnProperty("intentServerURL"),
                isUpdated: false,
                default: ""
            },
            dashTable: {
                value: configData.dashTable || "",
                isDefault: !configData.hasOwnProperty("dashTable"),
                isUpdated: false,
                default: ""
            },
            userSessionTimeoutMinutes: {
                value: isNaN(configData.userSessionTimeoutMinutes) ? 30 : configData.userSessionTimeoutMinutes,
                isDefault: !configData.hasOwnProperty("userSessionTimeoutMinutes"),
                isUpdated: false,
                default: 30
            },
            variant: {
                value: configData.variant || "",
                isDefault: !configData.hasOwnProperty("variant"),
                isUpdated: false,
                default: ""
            },
            firstResponseDelayMilliseconds: {
                value: isNaN(configData.firstResponseDelayMilliseconds) ? 0 : configData.firstResponseDelayMilliseconds,
                isDefault: !configData.hasOwnProperty("firstResponseDelayMilliseconds"),
                isUpdated: false,
                default: 0
            },
            longBubbleDelayMilliseconds: {
                value: isNaN(configData.longBubbleDelayMilliseconds) ? 0 : configData.longBubbleDelayMilliseconds,
                isDefault: !configData.hasOwnProperty("longBubbleDelayMilliseconds"),
                isUpdated: false,
                default: 0
            },
            shortBubbleDelayMilliseconds: {
                value: isNaN(configData.shortBubbleDelayMilliseconds) ? 0 : configData.shortBubbleDelayMilliseconds,
                isDefault: !configData.hasOwnProperty("shortBubbleDelayMilliseconds"),
                isUpdated: false,
                default: 0
            },
            mediumBubbleDelayMilliseconds: {
                value: isNaN(configData.mediumBubbleDelayMilliseconds) ? 0 : configData.mediumBubbleDelayMilliseconds,
                isDefault: !configData.hasOwnProperty("mediumBubbleDelayMilliseconds"),
                isUpdated: false,
                default: 0
            },
            translationLanguage: {
                value: configData.translationLanguage || "",
                isDefault: !configData.hasOwnProperty("translationLanguage"),
                isUpdated: false,
                default: ""
            },
            configJSON: {
                value: configData || "",
                isDefault: false,
                isUpdated: false,
                default: ""
            }
        });


    const [config,setConfig] = useState(JSON.stringify(configData, null, 2));

    const updateChanged = action(() => {
        state.propertiesChanges[bot.key] = botPropertiesState;
        console.dir(botPropertiesState);
    });
    const isCanUpdate = canUpdate(p.appState.auth, bot);
    const makeRow = makeMakeKvPFromDefaultable(
        (botPropertiesState as any) as { [key: string]: DefaultableObjectState },
        updateChanged,
        !isCanUpdate
    );

    return (
        <div style={{ height: "100%", overflow: "hidden", display: "flex", flexDirection: "column" }}>
            <ToolInnerContent style={{ height: "100%", overflow: "auto" }}>
                <KvpDisplay
                    {...{
                        data: [
                            {
                                left: { data: "contentDB", type: KvpType.text },
                                right: {
                                    data: (
                                        <div
                                            style={{
                                                flex: 0.7,
                                                paddingLeft: "8px",
                                                paddingRight: "8px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center"
                                            }}>
                                            <EntryAdder
                                                {...{
                                                    isReady: isCanUpdate && !!p.appState.data.databases.length,
                                                    onChange: (value: string) => {
                                                        botPropertiesState.contentDb = value;
                                                        updateChanged();
                                                    },
                                                    options: p.appState.data.databases
                                                        .map(db => db.name)
                                                        .map((name, i) => {
                                                            return {
                                                                value: i.toString(),
                                                                label: name
                                                            };
                                                        }),
                                                    placeholderText: botPropertiesState.contentDb,
                                                    placeholderNotReadyText: isCanUpdate
                                                        ? "Please wait for dbs to load"
                                                        : "Insufficient permissions"
                                                }}
                                            />
                                        </div>
                                    ),
                                    type: KvpType.custom
                                }
                            }
                            // makeRow("enginesPerInstance", "number"),
                            // makeRow("timezone"),
                            // makeRow("dashPassword"),
                            // makeRow("customDash"),
                            // makeRow("intentServerURL"),
                            // makeRow("dashTable"),
                            // makeRow("userSessionTimeoutMinutes", "number"),
                            // makeRow("variant"),
                            // makeRow("firstResponseDelayMilliseconds", "number"),
                            // makeRow("longBubbleDelayMilliseconds", "number"),
                            // makeRow("shortBubbleDelayMilliseconds", "number"),
                            // makeRow("mediumBubbleDelayMilliseconds", "number"),
                            // makeRow("translationLanguage")
                        ]
                    }}
                />
                <Divider />
                {/* <AceEditor
                    style={{ marginTop: "10px" }}
                    mode="json"
                    value={JSON.stringify(configData, null, 2)}
                    theme="tomorrow"
                    width="100%"
                    height="300px"
                    highlightActiveLine={false}
                    onValidate = {(whatever) => {
                        console.log(whatever);
                    }}
                    onChange={(value: string) => {
                        try {
                            botPropertiesState.configJSON = JSON.parse(value);
                            updateChanged();
                        }
                        catch {
                        }
                    }}
                /> */}
                <TextField 
                  rows={10} 
                  multiline 
                  fullWidth 
                  value={config} 
                  onChange={(event)=>{botPropertiesState.configJSON = { value: validConfig(event.target.value,config), isDefault: false, isUpdated: true, default:"" };setConfig(event.target.value);updateChanged()}}
                />
            </ToolInnerContent>
        </div>
    );
});
