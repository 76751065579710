import {AppState, LogType} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {QnaType} from "../types";

const url = "https://api.imperson.com/qna/";
// const url = "http://localhost:31755/qna/";

const tags = ["bots", "qna"];

export const makeGetQnaData = (appState: AppState) => {
    return async () => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const qnaData = [] as QnaType;

        const query = "all_qa";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            const owner = getBotName(bot);
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(url + query + "?owner=" + owner, {
                method: "GET"
            });
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                data.forEach((row: any) => {
                    qnaData.push({
                        ruleid: row.questionID,
                        question: row.question,
                        response: row.answer,
                        questionType: row.questionType
                    });
                });
                return Promise.resolve(qnaData);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
export const makePutQnaData = (appState: AppState) => {
    return async (owner: string, key: string, qid: string, question: string, answer: string, questionType: string) => {
        const state = getBotsState(appState);
        const addLog = appState.actions.log.addLog;

        const query = "qa";
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);
            if (!bot || !query) {
                return Promise.resolve({d: []});
            }
            addLog({tags, entry: `Query: ${query} start`, type: LogType.start});
            const reply = await appState.auth.fetchWithAuth(
                url + query + "?owner=" + owner + "&id=" + qid + "&key=" + key,
                {
                    body: JSON.stringify({question: question, answer: answer, questionType: questionType}),
                    method: "POST"
                }
            );
            if (reply.ok) {
                const data = await reply.json();
                addLog({tags, entry: `Query: ${query} success`, type: LogType.end});
                return Promise.resolve(data);
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: ${query} error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: ${query} ${e} exception`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
