import React from "react";
import {Typography, Divider} from "@material-ui/core";
import {AppState, UsersState, UserData} from "../../../app/types";
import {observer} from "mobx-react";
import {ToolboxPermissions} from "./ToolboxPermissions";
import {DatabasePermissions} from "./dbs/DatabasePermissions";
import {BotsPermissions} from "./bots/BotsPermissions";
import {PermissionsTabs} from "./PermissionsTabs";
import {getUsersState} from "../logic/getUsersState";
import {ToolInnerContent} from "../../../app/components/ToolInnerContent";

const getFusedPermissions = (state: UsersState, users: UserData[]) => {
    const permissions: {[key: string]: string[]} = {};
    state.selectedUserIds.forEach(user_id => {
        const entry =
            state.updates.find(user => user.user_id === user_id) || users.find(user => user.user_id === user_id);
        if (!entry) return [];
        permissions[entry.user_id] = entry.app_metadata
            ? entry.app_metadata.permissions && [...entry.app_metadata.permissions]
            : [];
    });
    return permissions;
};

export const UsersDetailsContainer = observer((p: {appState: AppState} & {classes?: any}) => {
    const {appState} = p;
    const state = getUsersState(appState);
    const isSelected = !!state.selectedUserIds.length;
    const permissions = getFusedPermissions(state, appState.data.users);

    return (
        <ToolInnerContent
            style={{
                height: "70%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                border: "1px solid lightgray"
            }}>
            <PermissionsTabs {...{state}} />
            <Divider />
            {isSelected ? (
                <ToolInnerContent style={{height: "100%", overflow: "auto"}}>
                    {state.selectedTab === 0 && <ToolboxPermissions {...{permissions, appState}} />}
                    {state.selectedTab === 1 && <DatabasePermissions {...{permissions, appState}} />}
                    {state.selectedTab === 2 && <BotsPermissions {...{permissions, appState}} />}
                </ToolInnerContent>
            ) : (
                <div style={{height: "calc(100% - 40px)", display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Typography variant="h6">No selected users</Typography>
                    </div>
                </div>
            )}
        </ToolInnerContent>
    );
});
