import React, {useState, useEffect, useReducer} from "react";
import {AppState} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {autorun} from "mobx";
import {hasPermission} from "../../../../../app/permissions";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {ToolInnerContent} from "../../../../../app/components/ToolInnerContent";
import {uuid} from "../test/uuid";
import {FaqTabs} from "./FaqTabs";
import { Divider } from "@material-ui/core";
import { FaqsActionsBar } from "./ActionsBar"

export const Faqs = (p: {appState: AppState}) => {
    const trace = uuid();
    const {appState} = p;
    const state = getBotsState(appState);
    const [bots, setbots] = useState(p.appState.data.bots_statuses);
    useEffect(() => {
        return autorun(() => {
            setbots(p.appState.data.bots_statuses);
        });
    }, [p.appState.data.bots_statuses]);

    const [bot, setbot] = useState(bots.find(b => b.key === state.expandedBotId));

    useEffect(() => {
        return autorun(() => {
            const b = p.appState.data.bots_statuses.find(b => b.key === state.expandedBotId);
            if (!b) return;
        });
    }, [bot]);

    useEffect(() => {
        return autorun(() => {
            const b = p.appState.data.bots_statuses.find(b => b.key === state.expandedBotId);
            if (!b) return;
            setbot(b);
        });
    }, [p.appState.data.bots_statuses, state.expandedBotId, bot]);

    if (!bot) return null;
    if (!hasPermission("faq", appState.auth.permissions, getBotName(bot))) return null;

    // console.log(`dashboard config before render ${config.key} ${config.graphs.length} ${trace}`);
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", flex: 1, overflow: "hidden"}}>
            <FaqsActionsBar {...{appState}} /> <Divider />
            <ToolInnerContent  style={{ height: "calc(100% - 53px)" }} 
                //     display: "flex",
                //     flexDirection: "column",
                //     border: "1px solid lightgrey"
                // }}
                >
                <FaqTabs {...{appState}} />
            </ToolInnerContent>
        </div>
    );
};
