import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Slide,
    DialogTitle,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import { AppState, ReloadActionParams } from "../../../../app/types";
import { getBotsState } from "../../logic/getBotsState";
import { autorun } from "mobx";

// function Transition(props: any) {
//     return <Slide direction="up" {...props} />;
// }

export const AreYouSureReloadDialog = (p: { appState: AppState }) => {
    const { appState } = p;
    const state = getBotsState(appState);
    const [isSoftReload, setIsSoftReload] = useState(false);
    const [isShowingAreYouSureReload, setisShowingAreYouSureReload] = useState(state.isShowingAreYouSureReload);
    useEffect(() => {
        return autorun(() => setisShowingAreYouSureReload(state.isShowingAreYouSureReload));
    }, [state.isShowingAreYouSureReload]);
    const handleClose = () => {
        state.isShowingAreYouSureReload = false;
    };
    const handleConfirm = () => {
        confirmReload();
        handleClose();
    };

    const botsToReload = state.selectedBotIds
        .map(id => {
            const bot = appState.data.bots_statuses.find(botInData => botInData.key === id);
            if (!bot) return null;
            return {
                key: bot.key,
                botName: `${bot.personalityID}-${bot.instanceID}`,
                isSoftReload
            };
        })
        .filter(entry => entry) as ReloadActionParams[];

    const toggleSoftReload = () => {
        setIsSoftReload(!isSoftReload);
        botsToReload.forEach(b => (b.isSoftReload = !isSoftReload));
    };

    const confirmReload = () => {
        appState.actions.botsControl && appState.actions.botsControl.reload(botsToReload);
    };

    return (
        <Dialog open={isShowingAreYouSureReload} keepMounted onClose={handleClose}>
            <DialogTitle>{`Do you want to reload ${botsToReload.map(bot => bot.botName).join(", ")}?`}</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isSoftReload}
                            onChange={toggleSoftReload}
                            value={isSoftReload}
                            color="primary"
                        />
                    }
                    label="Soft reload"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
