import {AppState, ReloadActionParams, LogType} from "../../../app/types";
import {getBotsState} from "./getBotsState";

const deployUrl = "https://api.imperson.com/deploy?key=";
// const deployUrl = "http://localhost:31755/deploy?key=";

const tags = ["bots"];

export const makeDoReloads = (appState: AppState) => async (params: ReloadActionParams[]) => {
    const state = getBotsState(appState);
    const auth = appState.auth;
    const addLog = appState.actions.log.addLog;
    params.length && (state.isShowingReloadIFrame = true);

    let i: number = 0;
    while (i < params.length) {
        const entry = params[i];
        const {key, isSoftReload, botName} = entry;

        addLog({tags, entry: `Reloading ${i + 1}/${params.length} - ${entry.botName}`, type: LogType.start});

        const url = deployUrl + key + `&softreload=${isSoftReload ? "true" : "false"}`;
        await auth
            .fetchWithAuth(url)
            .then(resp => {
                if (!resp.ok) {
                    throw Error("not ok");
                }
                return resp;
            })
            .then(resp => resp.body)
            .then(rs => {
                if (!rs) {
                    throw new Error("No reload body response");
                }
                const reader = rs.getReader();
                const chunks: BlobPart[] = [];
                const iframe = document.getElementById("reload-iframe") as HTMLIFrameElement;
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        async function pump(): Promise<void> {
                            const {done, value} = await reader.read();
                            if (done) {
                                controller.close();
                                return;
                            }
                            const rs = new Response(value);
                            const txt = await rs.text();
                            chunks.push(txt);
                            const firstDiv = txt.indexOf("<div>");
                            firstDiv === 0 &&
                                addLog({
                                    tags,
                                    entry: `${botName}: ${txt.substring(firstDiv + 5, txt.indexOf("</div>"))}`,
                                    style: {
                                        color: "purple"
                                    }
                                });
                            const blb = new Blob(chunks, {type: "text/html"});
                            const url = URL.createObjectURL(blb);
                            iframe && (iframe.src = url);
                            controller.enqueue(value);
                            return pump();
                        }
                    }
                });
            })
            .then(rs => new Response(rs))
            .then(response => response.text())
            .then(txt => {
                addLog({tags, entry: `${botName}: reload complete`, type: LogType.end});
                return txt;
            })
            .catch(error => {
                addLog({tags, entry: `${botName}: reload failed with error ${error}`, type: LogType.error});
                console.error(error);
            });
        i += 1;
    }
    addLog({tags, entry: `All reloads complete`, type: LogType.end});
};
