import React from "react";
import {AppState} from "./types";
import {Theme, withStyles} from "@material-ui/core";
import {observer} from "mobx-react";
import {Log} from "./log/Log";
import {getDrawerState} from "./getDrawerState";

const styles = (theme: Theme) => ({
    content: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
        maxHeight: "100vh",
        width: "80%"
    },
    toolbar: theme.mixins.toolbar
});

function ContentStyleless(props: {classes: any} & {appState: AppState}) {
    const {classes, appState} = props;
    const drawer = getDrawerState(appState);

    const selectedItemId = drawer.selectedItemId;
    const selectedItem = appState.data.drawerItems.find(item => item.uid === selectedItemId);
    const SelectedContent = selectedItem && selectedItem.content;
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div style={{overflow: "auto", flex: 1, display: "flex", flexDirection: "column" as "column"}}>
                {SelectedContent ? <SelectedContent {...{appState}} /> : null}
            </div>
            {appState.state.logState && <Log {...{appState}} />}
        </main>
    );
}

// @ts-ignore
export const Content = withStyles(styles)(observer(ContentStyleless));
