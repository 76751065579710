import {AppState, LogType} from "../../../app/types";

const tags = ["users"];

export const makeAddUser = (appState: AppState) => async (email: string) => {
    const addLog = appState.actions.log.addLog;
    addLog({entry: `adding ${email}`, tags, type: LogType.start});

    const reply = await appState.auth.fetchWithAuth(`/addUser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            connection: "Username-Password-Authentication",
            email_verified: true,
            email,
            password: "thisIsAPassword!!123something",
            app_metadata: {permissions: []}
        })
    });
    const json = await reply.json();
    if (!json.error) {
        addLog({entry: `user ${email} created`, tags, type: LogType.end});
        appState.data.users = [...appState.data.users, ...[json]].sort((a, b) =>
            a.email < b.email ? -1 : a.email > b.email ? 1 : 0
        );
    } else {
        addLog({entry: `user ${email} could not be created`, tags, type: LogType.error});
    }
};
