import {AppState, UserUpdateData} from "../../../app/types";
import {getUsersState} from "./getUsersState";

const updateUser = (user: UserUpdateData, permissions: string[]) => (user.app_metadata.permissions = permissions);
const addUpdate = (appState: AppState, userId: string, permissions: string[]) => {
    const state = getUsersState(appState);
    state.updates.push({user_id: userId, app_metadata: {permissions}});
};
export const makeUpdateUsers = (appState: AppState) => async (updates: {[key: string]: string[]}) => {
    Object.keys(updates).forEach(userId => {
        const state = getUsersState(appState);
        const found = state.updates.find(up => up.user_id === userId);
        found ? updateUser(found, updates[userId]) : addUpdate(appState, userId, updates[userId]);
    });
};
