import React from "react";
import {AppState} from "../../../../app/types";
import {ToolInnerContent} from "../../../../app/components/ToolInnerContent";
import {getBotsState} from "../../logic/getBotsState";
import {KvpDisplay, KvpType} from "../../../../kvp/KvpDisplay";

export const BotsLinksTab = (p: {appState: AppState}) => {
    const {appState} = p;
    const state = getBotsState(appState);
    const bots = appState.data.bots_statuses;
    const selected = state.expandedBotId;
    if (!selected) return null;
    const bot = bots.find(b => b.key === selected);
    if (!bot) return null;

    return (
        <ToolInnerContent>
            <KvpDisplay
                {...{
                    data: [
                        {left: {data: "Key", type: KvpType.text}, right: {data: bot.key, type: KvpType.text}},
                        {left: {data: "Secret", type: KvpType.text}, right: {data: bot.secret, type: KvpType.text}},
                        {
                            left: {data: "Web test", type: KvpType.text},
                            right: {data: bot.webTestURL, type: KvpType.link}
                        },
                        {
                            left: {data: "Edit content", type: KvpType.text},
                            right: {data: bot.editContentURL, type: KvpType.link}
                        },
                        {
                            left: {data: "Edit parent content", type: KvpType.text},
                            right: {data: bot.editParentContentURL, type: KvpType.link}
                        },
                        {
                            left: {data: "View sessions", type: KvpType.text},
                            right: {data: bot.viewSessionsURL, type: KvpType.link}
                        },
                        {
                            left: {data: "Run script", type: KvpType.text},
                            right: {data: bot.scriptURL, type: KvpType.link}
                        },
                        {
                            left: {data: "View dashboard", type: KvpType.text},
                            right: {data: bot.dashboardURL, type: KvpType.link}
                        }
                    ]
                }}
            />
        </ToolInnerContent>
    );
};
