import React from "react";
import {DefaultableObjectState} from "./types";
import {Input, InputAdornment, IconButton, Tooltip} from "@material-ui/core";
import {KvpType} from "./KvpDisplay";
import {ResetToDefault} from "../icons/ResetToDefault";
import {KvPBoolean} from "./KvPBoolean";

const inputStyle = {width: "100%", fontSize: "1rem"};

export const makeMakeKvPFromDefaultable = (
    data: {
        [key: string]: DefaultableObjectState;
    },
    updateChanged: () => void,
    disabled: boolean = false
) => (key: string, type: string | undefined = "text") => {
    const v = data[key] as DefaultableObjectState;
    const extraStyle = v.isDefault ? {fontStyle: "italic", color: "darkgray"} : {};
    return {
        left: {data: key, type: KvpType.text},
        right: {
            data: (
                <div
                    style={{
                        width: "100%",
                        fontSize: "1rem",
                        flex: 0.7,
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        minHeight: "32px"
                    }}>
                    {v.type === "boolean" ? (
                        <KvPBoolean {...{data: v, updateChanged, disabled}} />
                    ) : (
                        <Input
                            disabled={disabled}
                            type={type}
                            value={v.value}
                            style={{...inputStyle, ...extraStyle}}
                            onChange={e => {
                                v.value = type === "number" ? parseInt(e.currentTarget.value) : e.currentTarget.value;
                                v.isDefault = false;
                                v.isUpdated = true;
                                updateChanged();
                            }}
                            endAdornment={
                                !v.isDefault && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Revert to default">
                                            <IconButton
                                                size="small"
                                                style={{height: "30px"}}
                                                onClick={() => {
                                                    v.value = v.default;
                                                    v.isDefault = true;
                                                    v.isUpdated = true;
                                                    updateChanged();
                                                }}>
                                                <ResetToDefault />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }
                        />
                    )}
                </div>
            ),
            type: KvpType.custom
        }
    };
};
