import { AppState, UserType } from "../../../app/types";

const url = "https://api.imperson.com/toolboxuser";
// const url = "http://localhost:31755/toolboxuser";

export const getUser = (appState: AppState, action: string, payload: any) => {
    return appState.auth.fetchWithAuth(url,
        {
            method: "POST",
            body: JSON.stringify({ "action": action, ...payload })
        })
        .then(response => { return response.json() }
        )
        .then(res => {
            if (res.error) {
                return Promise.reject({ message: res.error.message });
            } else {
                return Promise.resolve(res)
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
};