import React, {Component, Fragment} from "react";
import {TableRow, TableCell} from "@material-ui/core";
import {ServerSecondRow} from "./ServerSecondRow";
export class ServerRow extends Component {
    constructor(props) {
        super(props);
        this.state = {selected: ""};
    }
    render() {
        return this.props.rows.map((row, i) => {
            return (
                <Fragment key={i}>
                    <TableRow
                        selected={this.state.selected === row.instance}
                        hover
                        id={row.instance}
                        onClick={e => this.handleClick(e, row.instance)}>
                        <TableCell>{row.instance}</TableCell>
                        <TableCell>{row.group}</TableCell>
                        <TableCell>
                            {row.activeServers}/{row.maxServers}
                        </TableCell>
                        <TableCell>{new Date(row.startTime).toLocaleString()}</TableCell>
                    </TableRow>
                    {this.state.selected === row.instance ? (
                        <ServerSecondRow deployments={row.deployments} visible={true} />
                    ) : null}
                </Fragment>
            );
        });
    }
    handleClick = (event, id) => {
        if (this.state.selected === id) {
            this.setState({selected: null});
        } else {
            this.setState({selected: id});
        }
    };
}
