import {AppState, LogType} from "../../../app/types";
import {getUsersState} from "./getUsersState";

const tags = ["users"];

export const makeSaveUsers = (appState: AppState) => async () => {
    const state = getUsersState(appState);
    const updates = state.updates;
    const addLog = appState.actions.log.addLog;
    addLog({entry: "saving", tags, type: LogType.start});
    while (updates.length) {
        const update = updates.pop();
        if (!update) {
            addLog({entry: "save complete", tags, type: LogType.end});
            return;
        }
        const user = appState.data.users.find(user => user.user_id === update.user_id);
        if (!user) return;
        if (!user.app_metadata) user.app_metadata = {permissions: []};
        user.app_metadata.permissions = [...update.app_metadata.permissions];
        const userName = user.email;
        const reply = await appState.auth.fetchWithAuth(`/updateUser?userId=${update.user_id}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({app_metadata: update.app_metadata})
        });
        const json = await reply.json();
        if (!json.error) {
            user.app_metadata = json.app_metadata;
            addLog({entry: `${userName} saved, ${updates.length} to go`, tags});
        } else {
            addLog({entry: `${userName} save error!`, tags, type: LogType.error});
        }
    }
    addLog({entry: `save complete`, tags, type: LogType.end});
};
