import React, { Fragment, useState } from "react";
import { AppState } from "./types";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { observer } from "mobx-react";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

function AuthButtonVanilla(p: { appState: AppState }) {
    const { appState } = p;
    const { auth } = appState;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuClicked,setMenuClicked] = useState<null | string>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuClicked(event.currentTarget.getAttribute("aria-controls"));
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuClicked(null);
        setAnchorEl(null);
    };

    return auth && auth.token
        ? <Fragment>
            <Tooltip title="Contact Us">
                <IconButton aria-controls="simple-menu1" aria-haspopup="true" onClick={handleClick}>
                    <HelpOutlineOutlinedIcon htmlColor="white" style={{ width: "20px", height: "20px" }} />
                </IconButton>
            </Tooltip>
            <Menu
                id="simple-menu1"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) && menuClicked === "simple-menu1"}
                onClose={handleClose}
            >
                <div style={{padding:"10px"}}><span style={{color:"gray"}}>Support: </span><span style={{color:"black"}}>support@imperson.com</span></div>
                <hr style={{ color: "#fafafa" }} />
                <div style={{padding:"10px"}}><span style={{color:"gray"}}>Sales: </span><span style={{color:"black"}}>sales@imperson.com</span></div>
            </Menu>
            <Tooltip title={auth.userName + " - " + auth.userEmail}>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <AccountCircleOutlinedIcon htmlColor="white" style={{ width: "20px", height: "20px" }} />
                </IconButton>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) && menuClicked === "simple-menu"}
                onClose={handleClose}
            >
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
                    <div style={{ fontSize: "16px", padding: "5px" }}>{auth.userName}</div>
                    <div style={{ color: "gray" }}>{auth.userEmail}</div>
                </div>
                <hr style={{ color: "#fafafa" }} />
                <MenuItem onClick={auth.logout}>Logout</MenuItem>
            </Menu></Fragment>
        : null;
}

export const AuthButton = observer(AuthButtonVanilla);
