import {AppState, AppStates} from "../types";
import {getDrawerState} from "../getDrawerState";

export const applyState = (currentState: AppState, loadedStates: AppStates) => {
    const drawer = loadedStates.drawer || getDrawerState(currentState);
    if (drawer.isClearingState) {
        currentState.state.drawer = drawer;
        return;
    }
    // if (loadedStates.botsControl) loadedStates.botsControl.path = ["bots"];
    currentState.state = loadedStates;
};
