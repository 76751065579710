import {observer} from "mobx-react";
import {getCheckboxControl} from "../utils/getCheckboxControl";
import {AppState} from "../../../../app/types";

export const BotsListPermissions = observer(
    (p: {
        permissions: {
            [key: string]: string[];
        };
        allPermissions: {
            [key: string]: string[][];
        };
        appState: AppState;
    }) => {
        const {permissions, allPermissions, appState} = p;
        const updateUsers = appState.actions.users && appState.actions.users.updateUsers;
        if (!updateUsers) {
            console.error(`appState.actions.users.updateUsers not found`);
            return null;
        }
        const toggleList = (value: string) => (isOn: boolean) => {
            const entry = `/bot/${value}`;
            Object.keys(permissions).forEach(key => {
                const userPermissions = permissions[key];
                isOn
                    ? !userPermissions.includes(entry) && userPermissions.push(entry)
                    : userPermissions.includes(entry) && userPermissions.splice(userPermissions.indexOf(entry), 1);
            });
            updateUsers(permissions);
        };

        const listPermissions = Object.keys(allPermissions).reduce(
            (acc, cur) => {
                const p = allPermissions[cur];
                acc[cur] = p.some(item => item[2] === "list") ? ["list"] : [];
                return acc;
            },
            {} as {[key: string]: string[]}
        );
        return getCheckboxControl(listPermissions, "list", toggleList);
    }
);
