import React, {useState, useEffect} from "react";
import {Dialog, DialogActions, Button, DialogTitle, DialogContentText, DialogContent} from "@material-ui/core";
import {action, autorun} from "mobx";

export const AuthErrorDialog = (p: {state: {isOpen: boolean}}) => {
    const [isOpen, setisOpen] = useState(p.state.isOpen);
    useEffect(() => {
        return autorun(() => setisOpen(p.state.isOpen));
    }, [p.state.isOpen]);

    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth={true}>
            <DialogTitle>Authentication expired</DialogTitle>
            <DialogContent>
                <DialogContentText>Your authentication session has expired, please reload page</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    onClick={action(() => {
                        window.location.reload();
                    })}>
                    Reload Page
                </Button>
            </DialogActions>
        </Dialog>
    );
};
