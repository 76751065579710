import React from "react";
import {Checkbox} from "@material-ui/core";

export const getCheckboxControl = (
    items: {
        [key: string]: string[];
    },
    value: string,
    onChange: (value: string) => (isOn: boolean) => void
) => {
    const first = value.charAt(0).toUpperCase();
    const displayString = first.concat(value.substr(1));
    const isOn = Object.keys(items).some(key => items[key].includes(value));
    const isUniform = isOn
        ? Object.keys(items).some(key => !items[key].includes(value))
        : Object.keys(items).some(key => items[key].includes(value));
    return (
        <Checkbox
            checked={isOn}
            color={isUniform ? "primary" : "secondary"}
            onClick={() => onChange(value)(!isOn)}
            value={displayString}
            title={displayString}
            tabIndex={-1}
            style={{height: "16px"}}
        />
    );
};
