export function randInt(min: number, max: number) {
    min = Math.ceil(min);
    return Math.floor(Math.random() * (Math.floor(max) - min + 1)) + min;
}

export function showDate(utcdate: string, nowdate: Date): string {
    const localtimestamp = new Date(utcdate+"Z").getTime();
    const localdate = new Date(utcdate+"Z");
    const now = nowdate.getTime();
    const diffMS = now-localtimestamp;
    const diffMinutes = diffMS / (1000*60)
    if (diffMinutes < 1) {
        return "Now"
    } else if (diffMinutes < 2 ) {
        return "A minute ago"
    } else if (diffMinutes < 60) {
        return `${Math.round(diffMinutes)} minutes ago`
    } else if (localdate.getDate() === nowdate.getDate()) {
        return localdate.toLocaleTimeString();
    }
    return localdate.toLocaleString();
}
