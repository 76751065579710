import {AppState, LogType} from "../../../app/types";

const tags = ["COCO"];
const reloadUrl = "/coco/reload/";

export const makeCocoReload = (appState: AppState) => async (botNames: string[]) => {
    const addLog = appState.actions.log.addLog;
    addLog({entry: `reloading ${botNames.join(", ")}`, tags, type: LogType.start});

    await Promise.all(
        botNames.map(botName => {
            return appState.auth
                .fetchWithAuth(reloadUrl + botName, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(r => {
                    if (r.ok) {
                        addLog({entry: `${botName} reload complete`, tags, type: LogType.info});
                    } else {
                        addLog({entry: `${botName} reload failed`, tags, type: LogType.error});
                    }
                })
                .catch(e => {
                    addLog({entry: `${botName} reload failed`, tags, type: LogType.error});
                });
        })
    );

    addLog({entry: "reloads complete", tags, type: LogType.end});
    return;
};
