import React, { useState, useEffect } from "react";
import { AppState, BotsControlState } from "../../../app/types";
import { BotsTable } from "./BotsTable";
import { BotsDetails } from "./details/BotsDetails";
import { getBotsState } from "../logic/getBotsState";
import { Gateways } from "./gateways/Gateways";
import { Dashboard } from "./dashboard/components/Dashboard";
import { autorun, action } from "mobx";
import { getBotName } from "../../users/components/utils/getBotName";
import { hasPermission } from "../../../app/permissions";
import { Conversations } from "./conversations/components/ConversationsDetails";
import { Faqs } from "./faq/components/Faqs";

const clearPathToDepthOf = action((state: BotsControlState, depth: number) => {
    while (state.path.length > depth) {
        state.path.pop();
    }
});

export const BotsControlTableContainer = (p: { appState: AppState }) => {
    const { appState } = p;
    const state = getBotsState(appState);
    const [path, setPath] = useState([] as string[]);
    useEffect(
        () =>
            autorun(() => {
                // console.log("bot control path",state.path);
                const bots = appState.data.bots_statuses;
                // no bots or no path
                if (!bots || !bots.length || state.path.length < 2) {
                    // console.log("no bots");
                    setPath(state.path.length ? ["bots"] : []);
                    return;
                }
                const botName = state.path[1];
                const b = bots.find(bot => getBotName(bot) === botName);
                // bot not found
                if (!b) {
                    // console.log("bot not found");
                    clearPathToDepthOf(state, 1);
                    return;
                }
                // setting expanded bot id
                state.expandedBotId = b.key;

                // specific bot subpage
                if (state.path.length > 2) {
                    const botArea = state.path[2];
                    if (!hasPermission(botArea, appState.auth.permissions, botName)) {
                        clearPathToDepthOf(state, 2);
                    }
                }
                // console.log("setting path", state.path);
                setPath([...state.path]);
            }), []
    );
    
    // console.log("bot control render path", path, "state path", state.path);
    
    return (
        path[path.length - 1] === "gateways"
            ? <Gateways {...{ appState }} />
            : path[path.length - 1] === "conversations"
                ? <Conversations {...{ appState }} />
                : path[path.length - 1] === "dashboard"
                    ? <Dashboard {...{ appState }} />
                    : path[path.length - 1] === "faq"
                        ? <Faqs {...{ appState }} />
                        : path.length === 2
                            ? <BotsDetails {...{ appState }} />
                            : <BotsTable {...{ appState }} />

    );
};
