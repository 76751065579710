import React, { Fragment, useEffect, useRef } from "react";
import { conversation, logParams, message } from '../types';

export const Log = ({ conv, readonly, setStage }: logParams) => {
    const logRef = useRef(null);

    useEffect(() => {
        (logRef as any).current.scrollTop = 9999999999;
    }, [conv.messages]);

    return <div className="log" ref={logRef}>
        {conv.messages
            ? conv.messages.map((message, index) => {
                const sideClass = (message.incoming ? "line userline" : "line botline");
                if (message.contenttype === "text") {
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <div className={sideClass} dangerouslySetInnerHTML={{ __html: message.msgtext ? message.msgtext : "" }} />
                        {index !== conv.messages.length - 1 ? null : Suggest(message, readonly, setStage)}
                    </Fragment>
                } else if (message.contenttype === "image") {
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <div className="line botline media-bubble">
                            <img src={message.msgimage} style={{ borderRadius: "18px", width: "100%" }} />
                        </div>
                    </Fragment>
                } else if (message.contenttype === "template") {
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <div>
                            {Template(message, readonly, setStage)}
                        </div>
                    </Fragment>
                } else if (message.contenttype === "sound") {
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <div className="line botline media-bubble">
                            <audio controls src={message.msgaudio} style={{ borderRadius: "18px", width: "100%" }} />
                        </div>
                    </Fragment>
                } else if (message.contenttype === "video") {
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <Video {...message} />
                    </Fragment>
                } else if (message.contenttype === "no-response" || message.contenttype === "end") {
                    return null;
                } else if (message.contenttype === "typing") {
                    if (index == conv.messages.length - 1) {
                        return <div key={message.muid} className={sideClass}><img src="dots.gif"></img></div>
                    } else { return null; }
                } else if (message.contenttype === "transfer") {
                    var parser = new DOMParser();
                    var actions = parser.parseFromString(message.msgactions ? message.msgactions : "<actions />", "text/xml");
                    var transferResult = actions.evaluate("//actions/transfer", actions, null, 0, null);
                    var transferDOM = transferResult.iterateNext();
                    var tobot = false;
                    var toqueue = false;
                    if (transferDOM) tobot = actions.evaluate("@tobot", transferDOM, null, XPathResult.STRING_TYPE, null).stringValue === "1";
                    if (transferDOM) toqueue = actions.evaluate("@toqueue", transferDOM, null, XPathResult.STRING_TYPE, null).stringValue === "1";
                    return <Fragment key={message.muid}>
                        {Timestamp(message, index, conv.messages)}
                        <div className="divider">
                            <div className="dividerline" />
                            <span className="dividertext">{"Transfer to " + (toqueue ? "queue" : (tobot ? "bot" : "agent"))}</span>
                            <div className="dividerline" />
                        </div>
                    </Fragment>
                }
                else {
                    return <div key={message.muid} className={sideClass}>[{message.contenttype}]</div>
                }

            })
            : null
        }
    </div>
}

function showDate(thisdate: string, prevdate: string): string {
    const localzsuffix = thisdate.endsWith("Z") ? "" : "Z"
    const prevzsuffix = prevdate.endsWith("Z")?"":"Z"
    const localdate = new Date(thisdate + localzsuffix);
    const localtimestamp = localdate.getTime();
    const prev = new Date(prevdate + prevzsuffix).getTime();
    const diffMS = localtimestamp - prev;
    const diffMinutes = diffMS / (1000 * 60)
    const diffHours = diffMS / (1000 * 60 * 24)
    if (thisdate === prevdate || diffHours > 24) {
        return localdate.toLocaleDateString() + " " + localdate.toLocaleTimeString()
    } else {
        return ""
    }
}

function Timestamp(message: message, index: number, messages: message[]) {
    const prevMessages = messages.filter((msg, i) => i < index && msg.contenttype !== "typing" && msg.contenttype !== "no-response");
    const prevMessageCreated = index > 0 && prevMessages.length ? prevMessages[prevMessages.length - 1].created : message.created;
    const dateText = showDate(message.created, index === 0 ? message.created : prevMessageCreated);
    return (dateText
        ? <div key={message.muid} className="divider">
            <div className="dividerpad" />
            <span className="dividertext">{dateText}</span>
            <div className="dividerpad" />
        </div>
        : null
    )
}

function Video(message: message) {
    var parser = new DOMParser();
    var actions = parser.parseFromString(message.msgactions ? message.msgactions : "", "text/xml");
    var videoType = actions.getElementsByTagName("video")[0].getAttribute("type");
    var videoID = actions.getElementsByTagName("video")[0].getAttribute("id");
    if (videoType == null || (videoType != null && videoType == "impersonmedia")) {
        var reply = message.msgvideo;
        return (
            <div key={message.muid} className="line botline media-bubble">
                <video controls src={reply} style={{ borderRadius: "18px", width: "100%" }} />
            </div>
        );
    } else if (videoType != null && videoType == "youtube") {
        return (
            <div key={message.muid} className="line botline media-bubble">
                <iframe
                    src={"https://www.youtube.com/embed/" + videoID + "?autoplay=1"}
                    frameBorder={0}></iframe>
            </div>
        );
    }
    return null;
}

function Suggest(message: message, readonly: boolean, setStage: any) {
    if (message.msgactions) {
        var parser = new DOMParser();
        var actions = parser.parseFromString(message.msgactions, "text/xml");
        var suggestResult = actions.evaluate("//actions/suggest", actions, null, 0, null);
        var suggestOptionsDOM = suggestResult.iterateNext();
        if (suggestOptionsDOM) {
            var suggestOptionsText = suggestOptionsDOM.textContent;
            var suggestOptions = suggestOptionsText ? suggestOptionsText.split(",") : [];
            return (
                <div className="line suggestline">
                    {suggestOptions.map(btn => {
                        var txt = btn.trim().replace(/'/g, "&apos;");
                        return (
                            <button className="suggestbutton" type="button" style={{ marginLeft: "5px" }}
                                onClick={() => { if (!readonly) { setStage({ stage: "post", value: txt, value2: txt }) }; return false; }}>
                                {txt}
                            </button>
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    } else { return null; }
};

const getAction = (buttonType: string, buttonUrl: string, buttonPayload: string, buttonTitle: string, readonly: boolean, setStage: any): any => {
    return (readonly
        ? () => { return false; }
        : buttonType === "web_url"
            ? () => { window.open(buttonUrl, '_blank'); return false; }
            : buttonType === "postback"
                ? () => { setStage({ stage: "post", value: buttonPayload, value2: buttonTitle }); return false; }
                : () => { return false; })
}

const getButtons = (buttonsResult: XPathResult, doc: Document, readonly: boolean, setStage: any) => {
    let buttons = [];
    let button = null;
    while ((button = buttonsResult.iterateNext())) {
        var buttonType = doc.evaluate("@type", button, null, XPathResult.STRING_TYPE, null).stringValue;
        var buttonTitle = doc.evaluate("@title", button, null, XPathResult.STRING_TYPE, null).stringValue;
        var buttonUrl = doc.evaluate("@url", button, null, XPathResult.STRING_TYPE, null).stringValue;
        var buttonPayload = doc.evaluate("@payload", button, null, XPathResult.STRING_TYPE, null).stringValue;
        buttons.push(
            <tr>
                <td>
                    <button type="button" className="templatebutton" style={{ width: "100%" }}
                        onClick={getAction(buttonType, buttonUrl, buttonPayload, buttonTitle, readonly, setStage)}
                    >
                        {buttonTitle}
                    </button>
                </td>
            </tr>
        );
    }
    return <table style={{ width: "100%", borderSpacing: "0px", borderCollapse: "collapse" }}>{buttons}</table>;
};

function getElements(elementsResult: XPathResult, doc: Document, readonly: boolean, setStage: any) {
    var numberOfElements = 0;
    var imageRow = [];
    var titleRow = [];
    var buttonsRow = [];
    var element = elementsResult.iterateNext();
    while (element) {
        numberOfElements++;
        var elementTitle = doc.evaluate("@title", element, null, XPathResult.STRING_TYPE, null).stringValue;
        var elementSubTitle = doc.evaluate("@subtitle", element, null, XPathResult.STRING_TYPE, null).stringValue;
        var elementURL = doc.evaluate("@image_url", element, null, XPathResult.STRING_TYPE, null).stringValue;
        var elementButtonsHTML = getButtons(doc.evaluate("button", element, null, XPathResult.ANY_TYPE, null), doc, readonly, setStage);
        imageRow.push(
            <td>
                <img src={elementURL} className="templateimage" />
            </td>
        );
        titleRow.push(
            <td>
                <div className="templatetitle">{elementTitle}</div>
                <div className="templatesubtitle">{elementSubTitle}</div>
            </td>
        );
        buttonsRow.push(<td style={{ backgroundColor: "white", verticalAlign: "bottom" }}>{elementButtonsHTML}</td>);
        element = elementsResult.iterateNext();
    }
    var widthText = "calc(80% * " + numberOfElements.toString() + ")";
    // console.log(widthText);
    return (
        <table
            style={{
                tableLayout: "fixed",
                backgroundColor: "transparent",
                width: widthText
            }}>
            <tr>{imageRow}</tr>
            <tr>{titleRow}</tr>
            <tr>{buttonsRow}</tr>
        </table>
    );

}

function Template(message: message, readonly: boolean, setStage: any) {
    if (message.msgactions) {
        var parser = new DOMParser();
        var actions = parser.parseFromString(message.msgactions, "text/xml");
        var templateType = actions.evaluate("//actions/template/@type", actions, null, XPathResult.STRING_TYPE, null).stringValue;
        switch (templateType) {
            case "button":
                var templateText = actions.evaluate("//actions/template/@text", actions, null, XPathResult.STRING_TYPE, null).stringValue;
                var templateButtons = actions.evaluate("//actions/template/button", actions, null, XPathResult.ANY_TYPE, null);
                return (
                    <Fragment>
                        <div className="line botline">{templateText}</div>
                        <div className="line templateline">
                            {getButtons(templateButtons, actions, readonly, setStage)}
                        </div>
                    </Fragment>
                );
                break;
            case "carousel":
                return (
                    <div className="line templateline">
                        {getElements(actions.evaluate("//actions/template/element", actions, null, XPathResult.ANY_TYPE, null), actions, readonly, setStage)}
                    </div>
                );
                break;
            default:
                break;
        }
    } else {
        return null;
    }
}

export const mergeConvs = (conversations: conversation[], newconv: conversation): conversation[] => {
    var ret = [...conversations];
    var ind = ret.findIndex(sconv => sconv.cuid === newconv.cuid);
    if (ind === -1) {
        ret.push(newconv);
    } else {
        ret[ind] = mergeConv(ret[ind], newconv);
    }
    return ret;
}

export const mergeConv = (conv: conversation, newconv: conversation): conversation => {
    var ret = { ...newconv, messages: mergeMessages(conv.messages, newconv.messages) };
    return ret;
}

export const mergeMessages = (messages: message[], newmessages: message[]): message[] => {
    var ret = [...messages];
    if (newmessages) {
        newmessages.forEach(msg => {
            if (ret.findIndex(smsg => smsg.muid === msg.muid) === -1) ret.push(msg);
        })
        ret.sort((a, b) => a.created < b.created ? -1 : (a.created > b.created ? 1 : 0));
    }
    return ret;
}

export default Log
