import {AppState, LogType} from "../../../../../app/types";
import {getBotsState} from "../../../../botsControl/logic/getBotsState";
import {getBotName} from "../../../../users/components/utils/getBotName";
import {getPeriod} from "./getData";
import {base64DecToArr} from "./helpers.js";
import moment from "moment";

const url = "https://api.imperson.com/exportExcel/";

// const url = "http://localhost:31755/exportExcel";

const tags = ["bots", "DASHBOARD"];

export const makeGetExport = (appState: AppState) => {
    return async (appState: AppState) => {
        const botsState = getBotsState(appState);
        const addLog = appState.actions.log.addLog;
        const dash = botsState.dashboards[botsState.expandedBotId];
        if (!dash) return;
        try {
            const bot = appState.data.bots_statuses.find(bot => bot.key === botsState.expandedBotId);
            if (!bot) {
                return Promise.resolve({d: []});
            }
            addLog({tags, entry: `Query: export start`, type: LogType.start});

            const period = getPeriod(dash);

            const body = {
                dates: Boolean(period)
                    ? {start: "", end: ""}
                    : {
                        start: moment(dash.data.timeRange[0]).format("YYYY-MM-DD"),
                        end: moment(dash.data.timeRange[1]).format("YYYY-MM-DD")
                      },
                period,
                interval: dash.data.timeInterval,
                personality: getBotName(bot),
                relative: Boolean(period),
                timezone: dash.data.timezone
            };
            const reply = await appState.auth.fetchWithAuth(url, {
                method: "POST",
                body: JSON.stringify(body)
            });

            if (reply.ok) {
                const data = await reply.json();
                const result = data;
                const output = base64DecToArr(result);
                var blob = new Blob([output], {type: "application/vnd.ms-excel"});
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "sessions.xlsx";
                link.click();
                // Promise.resolve(processReplyData(graph, data));
                addLog({tags, entry: `Query: export success`, type: LogType.end});
                return result;
            } else {
                const result = Promise.resolve({error: reply.statusText});
                addLog({tags, entry: `Query: export error`, type: LogType.error});
                return result;
            }
        } catch (e) {
            addLog({tags, entry: `Query: export error`, type: LogType.error});
            return Promise.resolve({error: e});
        }
    };
};
