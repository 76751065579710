import React, {useState, useEffect} from "react";
import {observable, action, autorun} from "mobx";
import {AppState, DashboardData} from "../../../../../../app/types";
import {IconButton} from "@material-ui/core";
import {Options} from "../../../../../../app/options/Options";
import {Reload} from "../../../../../../icons/Reload";
import {DashboardState} from "../../types";
import {getBotsState} from "../../../../../botsControl/logic/getBotsState";
import {makeGetQueryData, makeGetSessionData} from "../../logic/getQueryData";

// const reloadDelay = 60 * 5 * 1000;

export const ReloadButton = (p: {
    appState: AppState;
    dashState: DashboardState;
    config: DashboardData;
    // setSessionsData: any;
}) => {
    const [isReloading] = useState(false);
    const [isError, setIsError] = useState(false);
    //    const [nextReload, setNextReload] = useState(Date.now() + reloadDelay);
    const {appState} = p;
    const state = getBotsState(appState);
    const bot = appState.data.bots_statuses.find(bot => bot.key === state.expandedBotId);

    const actionIconParams = observable({
        isRotating: isReloading,
        nextReload: 0,
        isError
    });

    const reload = action(async () => {
        // console.log(`reload action ${p.config.key}`);
        // action(() => (actionIconParams.isRotating = true))();
        // setIsReloading(true);
        const getSessionsData = makeGetSessionData(appState, p.config);
        getSessionsData();
        const getQueryData = makeGetQueryData(appState, p.config);
        p.config.queries.forEach(query => {
            query.chartIds.forEach(id => {
                const graph = p.config.graphs.find(x => x.id === id);
                if (graph) {
                    if (bot && bot.configData && bot.configData.dashPlatforms && p.dashState.data.gateway) {
                        graph.gateway = p.dashState.data.gateway !== "ALL" ? p.dashState.data.gateway : undefined;
                    }
                    graph.isReloading = true;
                    graph.data = [];
                }
            });
            getQueryData(query);
        });
        setIsError(false);
        // setIsReloading(false);
        // action(() => {
        //     setNextReload(Date.now() + reloadDelay);
        //     actionIconParams.isRotating = false;
        // })();
    });

    useEffect(() => {
        return autorun(() => {
            // console.log(`reload useeffect ${p.config.key}`);
            const numGraphs = p.config.graphs.length;
            const currPathEnd = state.path[state.path.length - 1];
            const isOnDashboard = currPathEnd === "dashboard";
            if (!isOnDashboard || !numGraphs) return;
            const tindex = p.dashState.data.timeRangeOptionIndex;
            const trange = p.dashState.data.timeRange;
            const tInterval = p.dashState.data.timeInterval;
            const tz = p.dashState.data.timezone;
            const gateway = p.dashState.data.gateway;
            const region = p.dashState.data.region;
            reload();
        });
    }, []);

    return (
        <IconButton
            disabled={isReloading}
            style={{
                width: "16px",
                height: "16px",
                marginLeft: "8px",
                marginRight: "8px",
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column"
            }}>
            <Options
                data={[
                    {
                        onClick: reload,
                        icon: Reload({state: actionIconParams}),
                        label: "Reload"
                    }
                ]}
            />
        </IconButton>
    );
};
// ReloadButton.whyDidYouRender = {logOnDifferentValues: true, customName: "reload"};
