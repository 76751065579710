import React, {useState, useEffect} from "react";
import {extractNumberFromX} from "../../test/extractNumberFromX";
import {Button} from "@material-ui/core";
import {DashboardState} from "../../types";
import {action} from "mobx";
import {AirBnBDateRangePicker} from "./AirBnBDateRangePicker";

const areSame = (newRange: Date[], oldRange: number[]) => {
    if (newRange.length !== oldRange.length) return false;
    const numNewRange = newRange.map(d => d.getTime());
    const [longer, shorter] = newRange.length >= oldRange.length ? [numNewRange, oldRange] : [oldRange, numNewRange];
    for (let i = 0; i < longer.length; i++) {
        if (longer[i] !== shorter[i]) return false;
    }
    return true;
};

const changes = {range: [] as Date[], optionIndex: 0 as number};

export const TimeButtonsSelect = (p: {
    dashState: DashboardState;
    range: number[];
    minmax: number[];
    optionIndex: number;
    updateRange: (range: number[], optionIndex: number) => void;
}) => {
    const [optionIndex, setoptionIndex] = useState(p.optionIndex);
    useEffect(() => {
        setoptionIndex(p.optionIndex);
    });

    const onChange = (dates: Date[], optionIndex: number) => {
        if (!dates) {
            setoptionIndex(optionIndex);
            return;
        }
        setoptionIndex(optionIndex);
        changes.range = dates;
        changes.optionIndex = optionIndex;
    };

    const handleClose = () => {
        if (changes.optionIndex !== p.optionIndex || !areSame(changes.range, p.range)) {
            p.updateRange(changes.range.map(d => extractNumberFromX(d)), changes.optionIndex);
        }
        changes.range = [...p.range.map(r => new Date(r))];
        changes.optionIndex = p.optionIndex;
    };

    const setDaysBack = (daysBack: number, optionIndex: number) =>
        action(() => {
            var d = new Date();
            d.setDate(d.getDate() - daysBack);
            onChange([d, new Date()], optionIndex);
            if (daysBack <= 1) {
                p.dashState.data.timeInterval = "hourly";
            } else {
                p.dashState.data.timeInterval = "daily";
            }
            handleClose();
        });

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <Button variant="text" color={optionIndex === 0 ? "primary" : "default"} onClick={setDaysBack(1, 0)}>
                day
            </Button>
            <Button variant="text" color={optionIndex === 1 ? "primary" : "default"} onClick={setDaysBack(7, 1)}>
                week
            </Button>
            <Button
                variant="text"
                color={optionIndex === 2 ? "primary" : "default"}
                onClick={() => {
                    onChange(p.minmax.map(mm => new Date(mm)), 2);
                    handleClose();
                }}>
                all
            </Button>
            <AirBnBDateRangePicker
                {...{
                    dashState: p.dashState,
                    onChange: (dates: Date[]) => {
                        onChange(dates, 3);
                        handleClose();
                    },
                    isSelected: optionIndex === 3
                }}
            />
        </div>
    );
};
