import React, {useState, useEffect, Fragment} from "react";
import {GraphState} from "../../types";
import {autorun} from "mobx";
import {Typography} from "@material-ui/core";
import {Reloading} from "../Reloading";

// const format = (formatter: any, data: any) => {
//     if (formatter.function) {
//         const f = new Function(...formatter.function);
//         return f(data);
//     }
//     return data;
// };

export const CounterChart = (p: {graph: GraphState}) => {
    const [filteredData, setfilteredData] = useState(p.graph.data);
    const [isReloading, setisReloading] = useState(p.graph.isReloading);

    useEffect(() => {
        return autorun(() => {
            setfilteredData(p.graph.data);
        });
    }, []);
    useEffect(() => {
        return autorun(() => {
            setisReloading(p.graph.isReloading);
        });
    }, []);

    // const enabledYs = p.graph.dataTypes.y.filter(y => y.isEnabled);
    // const formatters = p.graph.formatters || [];
    // let displayData = filteredData.length ? ((filteredData[0].y as any) as number) : 0;
    // formatters.forEach(f => (displayData = format(f, displayData)));

    return (
        <div style={{height: "100%", width: "100%"}}>
            {p.graph.dataTypes.y.map((dt, i) => {
                return (
                    <Fragment key={i}>
                        <div style={{padding: "7px 10px 4px"}}>
                            <Typography variant="subtitle2">{dt.label}</Typography>
                        </div>
                        {!isReloading ? (
                            <div style={{textAlign: "right", padding: "7px 10px 4px"}}>
                                <span style={{color: "#5bc0de", fontWeight: "bold"}}>
                                    {filteredData.length && filteredData[i].y[0]
                                        ? filteredData[i].y[0].toLocaleString(undefined, {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 2
                                          })
                                        : ""}
                                </span>
                            </div>
                        ) : (
                            <Reloading />
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};
