import React, { Fragment } from "react";
import { AppBar, Toolbar, Tooltip, Button, Paper, Theme, Hidden, IconButton, Menu, MenuItem, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';

export type ActionsBarOption = {
    divider?: boolean;
    title?: string;
    action?: () => void;
    disabled?: boolean;
    tooltip?: string;
    component?: (props: any) => JSX.Element | JSX.Element;
    props?: any;
    fixedOnBar?: boolean;
};

const useStyles = makeStyles((theme: Theme) => {
    return {
        before: {
            paddingLeft: `${theme.spacing(2)}px`,
            paddingRight: `${theme.spacing(2)}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
        },
        actionSection: {
            display: "flex",
            paddingLeft: `${theme.spacing(2)}px`,
            paddingRight: `${theme.spacing(2)}px`
        },
        container: {
            minHeight: `${theme.spacing(5)}px`,
            backgroundColor: "white",
            zIndex: 2
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between"
        }
    };
});

const Divider = () => {
    return (
        <div
            style={{
                height: "100%",
                borderRight: "1px solid lightgray",
                marginRight: "4px",
                marginLeft: "4px"
            }}
        />
    );
};

const getComponent = (option: ActionsBarOption) => {
    if (option.divider) return <Divider />;
    if (option.component) return <option.component {...option.props} />;
    return (
        <Button color="primary" onClick={option.action || (() => { })} disabled={option.disabled}>
            {option.title}
        </Button>
    );
};

export const ActionsBar = (p: { before?: JSX.Element[]; options: ActionsBarOption[] }) => {
    const { options, before } = p;
    const classes = useStyles();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log("options",options);
    return (
        <AppBar position="sticky" className={classes.container} elevation={0}>
            <Toolbar className={classes.toolbar} disableGutters>
                <Paper className={classes.before} elevation={0}>
                    {before}
                </Paper>
                {desktop || options.filter((opt)=> opt.fixedOnBar).length ?
                    < Paper className={classes.actionSection} elevation={0}>
                        {options.filter((opt)=> desktop || opt.fixedOnBar).map((option, i) => {
                            const tooltip = option.tooltip || option.title;
                            return tooltip ? (
                                <Tooltip key={`action_${i}`} title={tooltip}>
                                    <div>{getComponent(option)}</div>
                                </Tooltip>
                            ) : (
                                <div key={`action_${i}`}>{getComponent(option)}</div>
                            );
                        })}
                    </Paper>
                    : null}
                {!desktop && options.filter((opt)=> !opt.fixedOnBar || opt.fixedOnBar == undefined).length
                    ? <Fragment>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {options.filter((opt)=> !opt.fixedOnBar || opt.fixedOnBar == undefined).map((option, i) => {
                                const tooltip = option.tooltip || option.title;
                                const divider = option.divider;
                                return tooltip
                                    ? <Tooltip key={`action_${i}`} title={tooltip}>
                                        <MenuItem onClick={handleClose}>{getComponent(option)}</MenuItem>
                                    </Tooltip>
                                    : divider
                                        ? null
                                        : <MenuItem onClick={handleClose} key={`action_${i}`}>{getComponent(option)}</MenuItem>
                                    ;
                            })}
                        </Menu>
                    </Fragment>
                    : null}
            </Toolbar>
        </AppBar >
    );
};
