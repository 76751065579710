import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: 'GTM-52DF9QJ'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render( < App / > , document.getElementById("root"));