import React, {useState, useEffect} from "react";
import {GraphState, DataUnit} from "../../types";
import Chart from "react-google-charts";
import {autorun} from "mobx";
import {Typography, Divider} from "@material-ui/core";

export const PieChart = (p: {graph: GraphState; filteredData: DataUnit[]}) => {
    const processedData = p.filteredData.map(du => [du.x, du.y]);
    return processedData.length > 1 ? (
        <Chart
            style={{display: "flex", justifyContent: "center"}}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={processedData}
            options={{
                // title: p.graph.title || `${xAxisLabel} by ${yAxisLabel}`,
                legend: p.graph.legend,
                chartArea: {left: 40, right: 10, top: 10},
                hAxis: {
                    title: p.graph.axesLabels.x
                },
                vAxis: {
                    title: p.graph.axesLabels.y
                }
            }}
            formatters={[
                {
                    type: "DateFormat",
                    column: 0,
                    options: {
                        formatType: "short"
                    }
                }
            ]}
            legendToggle
        />
    ) : (
        <div style={{height: "100%", width: "100%", display: "grid", placeItems: "center"}}>
            <Typography variant="subtitle2">{"NO DATA"}</Typography>
        </div>
    );
};
