import React from "react";
import {Checkbox} from "@material-ui/core";

export const getCheckboxWithAsteriskControl = (
    specifics: string[][],
    asterisks: {
        [key: string]: string[];
    },
    value: string,
    onChange: (value: string) => (isOn: boolean) => void
) => {
    const first = value.charAt(0).toUpperCase();
    const displayString = first.concat(value.substr(1));
    const isOnSpecific = specifics.some(values => values.includes(value));
    const isOnAsterisk = Object.keys(asterisks).some(userKey => asterisks[userKey].includes(value));
    const isUniform = isOnSpecific
        ? specifics.some(values => !values.includes(value))
        : specifics.some(values => values.includes(value));
    return (
        <Checkbox
            checked={isOnSpecific || isOnAsterisk}
            disabled={isOnAsterisk}
            color={isUniform ? "primary" : "secondary"}
            onClick={() => onChange(value)(!isOnSpecific)}
            value={displayString}
            title={displayString}
            tabIndex={-1}
            style={{height: "16px"}}
        />
    );
};
